import uk from "antd/locale/uk_UA";
import en from "antd/locale/en_US";

export const AppConfig = {
    /**
     * Url of Pages
     */
    authPageUrl: '/auth/login',
    forgotPasswordPageUrl: '/auth/forgot-password',
    /**
     * Backend url
     */
    backendUrl: import.meta.env.VITE_BACKEND_URL,
    mercureUrl: import.meta.env.VITE_MERCURE_URL,
    collaboraOfficeBrowserUrl: import.meta.env.VITE_COLLABORA_OFFICE_BROWSER_URL,
    /** 
     * Rest request url
    */
    restLoginUrl: '/core_api/auth/login',
    restUserMe: '/core_api/company/user/me',
    /**
     * Locale
     */
    defaultLocale: import.meta.env.VITE_DEFAULT_LOCALE ?? 'uk',
    currentLocale: import.meta.env.VITE_DEFAULT_LOCALE ?? 'uk',
    locales: {
        uk: {
            configProviderAntdLocale: uk 
        },
        en: {
            configProviderAntdLocale: en
        }
    },
    /** 
     * Datetime
    */
    dateDefaultFormat: 'YYYY-MM-DD',
    datetimeDefaultFormat: 'YYYY-MM-DD HH:mm',
    timezone: 'Europe/Kiev'
};