import { useFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { DefaultFieldTemplate } from "@/Core/UI/Form/Templates/Field/DefaultFieldTemplate";
import { DefaultViewFieldTemplate } from "@/Core/UI/Form/Templates/Field/DefaultViewFieldTemplate";
import { DefaultVerticalObjectFieldTemplate } from "@/Core/UI/Form/Templates/Field/DefaultVerticalObjectFieldTemplate";
import { StringInput } from "@/Core/UI/Form/Inputs/StringInput";
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { FormMultipleField } from './../FormMultipleField';

import './FormField.css';
import { observer } from 'mobx-react-lite';
import { FormFieldContextProvider, useFormFieldContext } from "@/Core/UI/Form/Providers/FormFieldContextProvider";
import { useDependency } from "@/Core/UI/Dependency/useDependency";
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { GetResourceCodeByDetailUri } from '@/Core/Tasks/Resources/GetResourceCodeByDetailUri';
import { useDefaultLayoutContext } from '@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider';

const useTemplate = ({ template = null, templateList = {}, children, props = {} }) => {

    if( typeof children === 'function' )
        return children(props);

    if( children )
        return children;

    if( typeof template === 'string' && templateList[template] !== undefined )
        return templateList[template];

    if( template )
        return template;

    return null;
}

const fieldObjectTemplate = {
    vertical: DefaultVerticalObjectFieldTemplate,
};

export const FormField = ({ 
    name,
    template,
    label,
    type,
    input,
    output,
    view,
    access,
    depFn,
    depStore,
    depField,
    initialValue,
    defaultValue,
    isMultiple,
    isRequired,
    validators,
    inputProps,
    isEditable,
    children,
    ignoreParentField = false,
    ...props
}) => {

    const formContext = useFormContext();
    const parentFormFieldContext = useFormFieldContext();
    const defaultLayoutContext = useDefaultLayoutContext();
    const formConfig = ConfigStore.get('FormConfig');

    props ??= {};

    if( formContext === undefined || name === undefined )
        return;
    
    let path = [];

    if( parentFormFieldContext && !ignoreParentField) {
        path = parentFormFieldContext.path;
    }

    path = [
        ...path,
        ...(Array.isArray(name) ? name : [ name ])
    ];

    
    let field = formContext.getField( path );

    if( !field ) {
        field = formContext.makeField({
            template,
            name,
            label, 
            type, 
            isMultiple,
            inputProps,
            input, 
            output,
            view,
            access, 
            depFn, 
            depStore,
            initialValue, 
            defaultValue, 
            isRequired, 
            validators,
            isEditable,
            ...props
        }, path);
        // console.warn(`Warning: Field "${name}" not found. Path: ${path.join('.')}`);
        // return null;
    }

    // TODO: Handle other incoming props
    if( label !== undefined )
        field.setLabel( label, path );

    depStore ??= {};
    depStore.field = field;
    depStore.formContext = formContext;

    depFn = useDependency({ depFn, depStore });

    if( typeof isRequired === 'boolean' )
        field.setIsRequired(isRequired);

    if( typeof isEditable === 'boolean' )
        field.setIsEditable( isEditable );

    if( typeof props === 'object' && Object.keys(props).length > 0 ) {
        field.mergeProps(props);
    }

    if( typeof inputProps === 'object' && Object.keys(inputProps).length > 0 ) {
        field.mergeInputProps(inputProps);
    }

    if( typeof view === 'function' )
        field.setView(view);  

    if( typeof depField === 'string' )
        depField = [ depField ];

    let effectField = [];
    let disposeReactionArray = [];

    if( Array.isArray(depField) ) {
        depField.map( fieldName => {
            effectField.push(fieldName);
            const disposeReaction = reaction(
                () => formContext.values[fieldName],
                () => formContext.setInitialValue( name, undefined )
            );
            disposeReactionArray.push( disposeReaction );
        });
    }
    
    useEffect(() => {
        
        if( disposeReactionArray.length > 0 )
            disposeReactionArray.map( disposeReaction => disposeReaction());

    }, effectField);

    const FormFieldObserver = observer(() => {

        if( depFn() === false )
            return null;

        // if( field.type === 'object' ) {
        //     return <FormFieldContextProvider value={field}>{
        //         useTemplate({
        //             template: field?.template ?? <>{Object.keys(field.fields).map( name => {
        //                 return <FormField name={name} />;
        //             })}</>,
        //             children,
        //             props: { field } 
        //         })
        //     }</FormFieldContextProvider>
        // }

        if( 
            field.type !== 'file'
            && (
                formContext.isEditable === false 
                || field.isEditable === false
            ) 
        ) {
            // TODO: Add object handler
            if( field.type === 'object' )
                return null;

            let viewValue = field.value || '-';

            if(
                field?.props?.isLink === true
                && field.type === 'select'
                && typeof field.props?.resource === 'string'
                && !Array.isArray( field.initialValue )
                && typeof field.initialValue === 'object'
            ) {
                
                const resourceCode = GetResourceCodeByDetailUri( field.initialValue['@id'] );

                if( resourceCode ) {
                    viewValue = <a href='#' onClick={() => {
                        defaultLayoutContext.openResource({
                            resourceCode,
                            resourceId: field.initialValue.id,
                            mode: 'view'
                        });
                    }}>{viewValue}</a>
                }
            }

            if( !Array.isArray(viewValue) && typeof viewValue === 'string' )
                viewValue = <div dangerouslySetInnerHTML={{ __html: viewValue }}></div>;
            
            if( Array.isArray(viewValue) )
                viewValue = viewValue.join(', ');

            if( field.type === 'textarea' ) {
                viewValue = <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>{viewValue}</pre>;
            }

            if( field.type === 'select' && typeof field.value === 'string' && Array.isArray(field.props?.options) ) {
                const option = field.props.options.find( option => option.value === field.value );
                if( option?.label )
                    viewValue = option.label;
            }

            return <DefaultViewFieldTemplate field={field}>
                {viewValue}
            </DefaultViewFieldTemplate>;
        }

        /** Field Template */
        let FieldTemplate = DefaultFieldTemplate;

        if( formContext.config?.defaultFieldTemplate )
            FieldTemplate = formContext.config.defaultFieldTemplate;

        if( 
            formContext.config?.fieldTemplates 
            && template !== undefined
            && typeof template === 'string'
            && formContext.config.fieldTemplates[template] !== undefined 
        )
            FieldTemplate = formContext.config.fieldTemplates[template];

        if( 
            template !== undefined
            && typeof template !== 'string'
        )
            FieldTemplate = template;
        
        /** Input */
        let InputComponent = StringInput;

        if( 
            formConfig
            && formConfig?.fieldTypes 
            && formConfig.fieldTypes[field.type] !== undefined 
        )
            InputComponent = formConfig.fieldTypes[field.type];
        
        if( input !== undefined )
            InputComponent = input;
        
        // Multiple field handler
        if( field?.isMultiple === true) {
            return <FormFieldContextProvider value={field}>
                <FieldTemplate field={field} formContext={formContext}>
                    <FormMultipleField field={field} InputComponent={InputComponent} />
                </FieldTemplate>
            </FormFieldContextProvider>;
        }

        // Object handler
        if( field.type === 'object' ) {
            return <FormFieldContextProvider value={field}>
                <DefaultVerticalObjectFieldTemplate>
                    {
                        field.isMultiple 
                            ? <FormMultipleField>{useTemplate({ props: { field } })}</FormMultipleField> 
                            : useTemplate({ props: { field } })
                    }
                </DefaultVerticalObjectFieldTemplate>
            </FormFieldContextProvider>;
        }

        if( children )
            return <FormFieldContextProvider value={field}>{children}</FormFieldContextProvider>

        return <FormFieldContextProvider value={field}>
            <FieldTemplate field={field} formContext={formContext}>
                <InputComponent field={field} />
            </FieldTemplate>
        </FormFieldContextProvider>;
    });

    return <FormFieldObserver />;
};