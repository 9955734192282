import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ApplicationItemResource = {
    title: 'Транспорт',
    fields: {
        entity: {
            label: 'Заявка',
            type: 'select',
            resource: 'Purchase.Application',
            isRequired: true,
            inputProps: {
                responseLabel: value => value.id
            }
        },
        transportNumber: {
            label: 'Номер автомобіля/Номер вагону',
            isRequired: true
        },
        loadedQuantity: {
            label: 'Завантажена кількість',
            type: 'number'
        },
        unloadedQuantity: {
            label: 'Вивантажена кількість',
            type: 'number'
        },
        targetWeight: {
            label: 'Залікова вага',
            type: 'number'
        },
        ttn: {
            label: 'ТТН'
        }
    },
    restUri: '/core_api/purchase/application/items'
};