import { UserOptionStore } from '@/Core/Stores/UserOptionStore';
import { action, computed, makeObservable, observable } from "mobx";
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import { GetResourceTask } from "@/Core/Tasks/Resources/GetResourceTask";

export class CrudResourceStore extends UIBaseStore {

    userOptions = null;
    resource = null;
    isFilter = true;
    prefilter = {};

    formContext = null;
    formComposerContext = null;
    filterFormContext = null;
    listContext = null;
    parentCrudContext = null;

    actions = {};
    modeItems = {};

    isLiveCrud = false;

    isMobileMode = true;
    isOpenMobileFilter = false;
    mobileModeWidth = 1024;

    constructor({
        isFilterOpen,
        isFilter,
        isLiveCrud,
        prefilter,
        resource,
        mode,
        modeItems,
        ...props
    }) {

        resource = GetResourceTask( resource );

        if( !resource?.key )
            throw new Error(`Resource key is not defined. Resource: ${JSON.stringify(resource)}`);

        super({ ...props });

        this.resource = resource;
        this.userOptions = new UserOptionStore({ key: resource.key });
        // this.userOptions.stopPersisting();
        
        if( isLiveCrud )
            this.isLiveCrud = true;

        if( !this.accessManager.accessFn && typeof this.resource.accessFn === 'function' ) {
            this.accessManager.setAccessFn( this.resource.accessFn );
        }

        if( !this.accessManager.accessFn && typeof this.resource.access === 'object' ) {
            this.accessManager.setAccessFn( place => this.resource.access[place] === true ? true : false );
        }

        if( typeof mode === 'string' && this.mode === undefined )
            this.setMode( mode );

        if( typeof modeItems === 'object' )
            this.setModeItems( modeItems );

        if( typeof isFilterOpen === 'boolean' && this.isFilterOpen === undefined )
            this.setIsFilterOpen( isFilterOpen );

        if( typeof isFilter === 'boolean' )
            this.isFilter = isFilter;

        if( typeof prefilter === 'object' )
            this.prefilter = prefilter;

        makeObservable( this, {
            isMobileMode: observable,
            isOpenMobileFilter: observable,
            isFilterOpen: computed,
            setIsFilterOpen: action,
            mode: computed,
            // modeItems: observable,
            setMode: action,
            setIsMobileMode: action,
            setIsOpenMobileFilter: action
            // setModeItems: action
        });
    }

    get isFilterOpen() {
        const isFilterOpen = this.userOptions.get('isFilterOpen');
        return isFilterOpen === false ? false : true;
    }

    get mode() {
        return this.userOptions.get('listMode');
    }

    setIsOpenMobileFilter( isOpenMobileFilter ) {
        this.isOpenMobileFilter = isOpenMobileFilter;
    }

    setIsMobileMode( mode ) {
        this.isMobileMode = mode === true ? true : false;
    }

    setParentCrudContext( parentCrudContext ) {
        this.parentCrudContext = parentCrudContext;
    }

    setMode( mode ) {
        if( typeof mode === 'string' )
            this.userOptions.set('listMode', mode);
    }

    setModeItems( modeItems ) {
        this.modeItems = modeItems;
    }

    setFormComposerContext( formComposerContext ) {
        this.formComposerContext = formComposerContext;
    }

    setIsFilterOpen( isFilterOpen ) {
        if( typeof isFilterOpen === 'boolean' ) {
            this.userOptions.set('isFilterOpen', isFilterOpen) 
        }
    }

    setFormContext( formContext ) {
        this.formContext = formContext;
    }

    setFilterFormContext( filterFormContext ) {
        this.filterFormContext = filterFormContext;
    }

    setListContext( listContext ) {
        this.listContext = listContext;
    }

    setActions( actions ) {
        this.actions = actions;
    }
}