import dayjs from "dayjs";

export const GetCollectionRequestParamsTask = ( object ) => {

    object = object ?? {};

    let filter = typeof object?.filter === 'object' ? object.filter : {};
    let pagination = typeof object?.pagination === 'object' ? object.pagination : {};
    let sorter = typeof object?.sorter === 'object' ? object.sorter : {};
    let params = { page: pagination.current, itemsPerPage: pagination.pageSize };

    params["order[" + sorter.key + "]"] = sorter.order;

    Object.keys(filter).map( key => {

        if(filter[key] !== undefined && (filter[key] === false || filter[key])) {

            if( 
                Array.isArray( filter[key] ) 
                && dayjs.isDayjs( filter[key][0] )
                && dayjs.isDayjs( filter[key][1] )
            ) {
                const dates = filter[key];
                params[`${key}[after]`] = dates[0].format("YYYY-MM-DD HH:mm:ss");
                params[`${key}[before]`] = dates[1].format("YYYY-MM-DD HH:mm:ss");
            }
            else if(Array.isArray(filter[key])) {
                filter[key].map( ( item, index ) => {
                    params[`${key}[${index}]`] = item;
                });
            }
            else if( typeof filter[key] === 'object' && filter[key]['@id'] !== undefined )
                params[key] = filter[key]['@id'];
            else
                params[key] = filter[key];
        }
    });

    return params;
}