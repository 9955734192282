import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { LoadNotificationListTask } from './LoadNotificationListTask';

export const RemoveNotificationTask = (id) => {

    const restClient = ServiceStore.get('restClient');

    restClient
        .delete(`/core_api/communications/notifications/${id}`, {
            json: {}
        })
        .getResponse()
        .then(() => {
            LoadNotificationListTask();
        });
}