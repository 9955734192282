import { AuthLayout as CoreAuthLayout } from "@/Core/Layouts/AuthLayout";
import { Spin } from "antd";
import styles from "./AuthLayout.module.css";

import './Form.css';

import { AntdConfig } from "@/Ship/Configs/AntdConfig";
import { AppConfig } from "@/Ship/Configs/AppConfig";
import { FormConfig } from '@/Ship/Configs/FormConfig';
import { Services } from "@/Ship/Services";

export const AuthLayout = () => {

    return <>
        <CoreAuthLayout 
            contentStyles={{
                // background: `url("/assets/back.webp") no-repeat center center`,
                backgroundColor: '#00246A',
                backgroundSize: 'cover'
            }} 
            modalStyles={{
                boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)'
            }}
            configs={{ AntdConfig, AppConfig, FormConfig }}
            services={Services}
        />
    </>;
}