import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { NotificationStore } from '../Stores/NotificationStore';
import { toJS } from 'mobx';

export const ReadAllNotificationTask = () => {

    const restClient = ServiceStore.get('restClient');

    restClient
        .patch('/core_api/communications/notifications/readed/all', {
            json: {}
        })
        .getResponse()
        .then(() => {

            let notifications = toJS(NotificationStore.notifications);
            notifications = notifications.map( item => {
                    item.isReaded = true;
                return item;
            });
            
            NotificationStore.setNotifications(notifications);
        });
}