export class MapBaseStore {
    
    store = new Map();

    sets( object ) {
        if( typeof object === 'object' )
            Object.keys(object).map( key => this.store.set( key, object[key] ));
    }

    set( key, value ) {
        this.store.set( key, value );
    }

    get( key, defaultValue ) {

        const storeValue = this.store.get( key );

        if( storeValue === undefined )
            return defaultValue;

        return storeValue;
    }

    getAll() {
        let object = {};
        this.store.forEach( (value, key) => object[key] = value );
        return object;
    }

    clear() {
        this.store = new Map();
    }
}