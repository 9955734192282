export const AddDefaultRowKeyFeature = {
    eventHandlers: {
        onBuildProps: {
            AddDefaultRowKeyProps: ({ props }) => {
                
                if( !props?.rowKey )
                    props.rowKey = item => `rowk-${item.id}`

                return props;
            }
        }
    }
};