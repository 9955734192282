import './Error.css';
import { Button, Card } from 'antd';

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const Error403 = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return <div className='error-block'>
        <Card title={t("Access denied", { ns: 'Core' })} extra={<Button type='primary' onClick={() => navigate('/')}>{t("To Home page", { ns: 'Core' })}</Button>}>
            <img className='error-img' src="/assets/errors/error403.svg" />
        </Card>
    </div>
}