import { action, makeObservable } from 'mobx';
import { FieldStore } from './FieldStore';

export class FieldMultipleStore extends FieldStore {

    index = 0;

    constructor(props) {
        super(props);

        makeObservable( this, {
            add: action,
            remove: action
        });

        this.eventManager.run('onInit', { context: this });
    }

    add = () => {
        let value = this.formContext.getValue(this.path) ?? [];
        value.push('');
        this.formContext.setValue( this.path, value );
    }

    remove = ( index ) => {
        let value = this.formContext.getValue(this.path) ?? [];
        value.splice(index, 1);
        this.formContext.setValue( this.path, value );
    }
}