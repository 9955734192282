const replaceParamsFn = ( uri, params ) => {

    if( typeof uri !== 'string' )
        return null;

    if( Object.keys(params).length === 0 )
        return uri;
    
    Object.keys(params).map( key => {
        uri = uri.replace(`{${key}}`, params[key])
    });

    return uri;
};

export const GetRestUriTask = ( restUri, type, replaceParams = {} ) => {

    type = type || 'getCollection';

    let uri = null;

    if( typeof restUri === 'string' )
        uri = restUri;

    if( typeof restUri === 'object' && typeof restUri[type] === 'string' )
        uri = restUri[type];

    if( !uri )
        return null;

    if( ['put', 'patch', 'get', 'delete'].includes(type) && uri.substr(-5) !== '/{id}')
        uri = `${uri}/{id}`;

    return replaceParamsFn(uri, replaceParams);
    
}