import { createContext, useContext } from "react";
import { FormStore } from "./../Stores/FormStore";

export const createFormContext = props => {

    props ??= {};
    props.template ??= null;
    props.templateView ??= null;
    props.templateMultiple ??= null;
    props.templateObject ??= null;
    props.onSubmit ??= null;
    props.fields ??= {};
    props.initialValues ??= {};
    props.eventHandlers ??= {};

    if( typeof props?.eventHandlers?.onSubmit !== 'function' && typeof props?.onSubmit === 'function' ) {
        props.eventHandlers.onSubmit = { baseOnSubmit: props.onSubmit };
        delete props.onSubmit;
    }

    if( typeof props?.eventHandlers?.onChange !== 'function' && typeof props?.onChange === 'function' ) {
        props.eventHandlers.onChange = { baseOnChange: props.onChange };
        delete props.onChange;
    }

    return new FormStore( props );
};

export const FormContext = createContext();

export const useFormContext = () => {
    const context = useContext(FormContext);
    return context;
}

export const FormContextProvider = ({ value, children }) => <FormContext.Provider value={value}>
    {children}
</FormContext.Provider>