import { CrudResource } from "@/Core/UI/CrudResource/CrudResource";
// import { AddButtonPrintablesCrud } from './AddButtonPrintablesCrud';
import { Button } from '@/Core/UI/Button';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowSuccessNotificationTask } from '@/Ship/Tasks/ShowSuccessNotificationTask';
import { Drawer } from "antd";
import { observer } from "mobx-react-lite";
import { makeAutoObservable, toJS } from "mobx";
import { Form, FormField } from "@/Core/UI/Form";
import { useShowErrorNotificationTask } from "@/Ship/Tasks/ShowErrorNotificationTask";
import { useTranslation } from 'react-i18next';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { PurchaseDocumentFormTemplate } from '../Templates/PurchaseDocumentFormTemplate';
import { AddButtonPurchaseDocumentCrud } from './AddButtonPurchaseDocumentCrud';
// import { Buttons4DocumentDrawerExtra } from './Buttons4DocumentDrawerExtra';

export const PurchaseDocumentCrud = ({ ...crudProps }) => {

    crudProps ??= {};
    const restClient = ServiceStore.get('restClient');
    const successTask = useShowSuccessNotificationTask();
    const errorTask = useShowErrorNotificationTask();
    const { t } = useTranslation();
    const crudContext = useCrudResourceContext();

    return <CrudResource 
        resource='Purchase.PurchaseDocument'
        isFilter={false}
        headerPage={{
            eventHandlers: {
                onButtons: {
                    AddButton: ({ buttons, headerPageContext, crudContext }) => {
                        buttons.AddButton = {
                            children: <AddButtonPurchaseDocumentCrud />
                        }
                    }
                },
            }
        }}
        list={{
            restUriReplaceParams: {
                entity_id: crudContext.formContext.values.id
            }
        }}
        form={{
            initialValues: {
                entity: crudContext.formContext.values
            },
            template: PurchaseDocumentFormTemplate
        }}
        table={{
            eventHandlers: {
                onBuildDataSourceItem: {
                    transformFileField: ({ item }) => {
                        console.log({ item });
                        
                        if( item?.wordFile?.fileUrl && item?.wordFile?.originalName )
                            item.wordFile = <a href={item.wordFile.fileUrl}>{item.wordFile.originalName}</a>;

                        if( item?.pdfFile?.fileUrl && item?.pdfFile?.originalName )
                            item.pdfFile = <a href={item.pdfFile.fileUrl}>{item.pdfFile.originalName}</a>;

                        return item;
                    }
                }
            },
            dataSourceNoTransform: ['pdfFile', 'wordFile'],
            showColumns: [
                'title', 'number', 'date', 'type',
                'wordFile', 'pdfFile'
            ]
        }}
        drawer={{
            width: 600,
            // eventHandlers: {
            //     onButtons: {
            //         addSendMailButtons: ({ buttons, crudContext }) => { 
            //             buttons.sendmail = { sort: 500, children: <Buttons4DocumentDrawerExtra crudContext={crudContext} key='dropdown-menu' /> };
            //         }
            //     }
            // }
        }}
        accessFn={ mode => {
            const item = toJS(crudContext.formContext.values);            
            return crudContext.resource.accessFn( mode, item );
        }}
        {...crudProps}
    />;
}