
const shipResources = import.meta.glob('/src/Ship/Resources/*Resource.{js,jsx,ts,tsx}', { eager: true });
const containerResources = import.meta.glob('/src/Containers/*Section/*Container/Resources/*Resource.{js,jsx,ts,tsx}', { eager: true });

const combinedResources = {};

for (const [key, module] of Object.entries(shipResources)) {
    const name = key.split('/').pop().replace(/\.(js|jsx|ts|tsx)$/, '');
    if( name.indexOf('Resource') === -1 )
        continue;
    combinedResources[name.replace('Resource', '')] = module[name];
}

for (const [key, module] of Object.entries(containerResources)) {

    const pathParts = key.split('/');

    if( pathParts[6] === undefined || pathParts[6].indexOf('Resource') === -1 )
        continue;

    const sectionName = pathParts[3].replace('Section', '');  
    const containerName = pathParts[4].replace('Container', '');
    const resourceName = pathParts[6].replace(/Resource\.(js|jsx|ts|tsx)$/, '');

    let namespace = [];

    if( import.meta.env.VITE_RESOURCES_REMOVE_SECTION_FROM_PATH !== 'true' )
        namespace.push(sectionName);
    
    if( import.meta.env.VITE_RESOURCES_ADD_CONTAINER_TO_PATH === 'true' )
        namespace.push(containerName);

    namespace.push(resourceName);
    namespace = namespace.join('.');

    if (!combinedResources[namespace]) {
        combinedResources[namespace] = {};
    }
    
    const resourceFullName = resourceName + 'Resource';
    combinedResources[namespace] = module[resourceFullName];

    if( import.meta.env.VITE_RESOURCES_DUPLICATE_PATH_RESOURCE_ONLY === 'true' )
        combinedResources[resourceName] = module[resourceFullName];
}

export const Resources = combinedResources;