import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const CarrierResource = {
    title: 'Перевізники',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        edrpou: {
            label: 'Код ЄДРПОУ',
            isRequired: true
        },
        ipn: {
            label: 'ІПН',
            isRequired: true
        },
        phones: {
            label: 'Телефони',
            isMultiple: true
        },
        otherContact: {
            label: 'Інші контактні дані',
            type: 'textarea'
        },
        comment: {
            label: 'Коментар',
            type: 'textarea'
        }
    },
    restUri: '/core_api/purchase/carriers',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER', 'ROLE_BUYER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};