export const DeepCloneTask = (obj, clones = new WeakMap()) => {

    if (obj === null || typeof obj !== 'object') {
        return obj;
    }

    if (clones.has(obj)) {
        return clones.get(obj);
    }

    if (Array.isArray(obj)) {
        const newArray = [];
        clones.set(obj, newArray);

        for (let i = 0; i < obj.length; i++) {
            newArray[i] = DeepCloneTask(obj[i], clones);
        }

        return newArray;
    }

    if (obj instanceof Function) {
        return obj;
    }

    const newObj = {};
    clones.set(obj, newObj);

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            newObj[key] = DeepCloneTask(obj[key], clones);
        }
    }

    return newObj;
}