import ky from 'ky';
import { UserStore } from "@/Core/Stores/UserStore";
import { HTTPResponseStore } from '@/Core/Stores/HTTPResponseStore';
import { AppConfig } from '@/Ship/Configs/AppConfig';

export class RestClientService {

    backendUrl = '';
    runningRequest = new Map();
    cacheResponse = new Map();

    constructor( props ) {
        if( props?.backendUrl )
            this.backendUrl = props.backendUrl;
    }

    get(url, options, settings) {
        return this.custom(ky.get, url, options, settings);
    }

    getNoStore(url, options, settings) {
        return this.customNoStore(ky.get, url, options, settings);
    }

    post(url, options, settings) {
        return this.custom(ky.post, url, options, settings);
    }

    put(url, options, settings) {
        return this.custom(ky.put, url, options, settings);
    }

    patch(url, options, settings) {
        
        options ??= {};
        options.headers ??= {};
        
        if( options.headers['Content-Type'] === undefined )
            options.headers['Content-Type'] = 'application/merge-patch+json';

        return this.custom(ky.patch, url, options, settings);
    }

    delete(url, options, settings) {
        settings ??= {};
        settings.isNotHeaderAddContentType = true;
        settings.isOriginalResponse = true;
        return this.custom(ky.delete, url, options, settings);
    }

    custom( method, url = null, options = {}, settings = {}) {
        
        if( typeof method !== 'function' || !url )
            return null;

        settings.isDisableResponseCache ??= false;

        try {
            
            const backendUrl = settings?.backendUrl ?? this?.backendUrl ?? '';
            url = `${backendUrl}${url}`;
    
            const buildedOptions = this.fillOptions( options, { backendUrl, ...settings } );
            
            if( method == ky.get && settings.isDisableResponseCache === false && !buildedOptions?.signal ) {
                
                if( this.cacheResponse.has(this.getRequestKey({ url, options, settings })) ) {
                    const cachedData = this.cacheResponse.get(this.getRequestKey({ url, options, settings }));
                    return cachedData;
                }                    

                if( this.runningRequest.has( this.getRequestKey({ url, options, settings }) ) ) {
                    const abortController = this.runningRequest.get( this.getRequestKey({ url, options, settings }) );
                    abortController.abort();
                }

                const abortController = new AbortController();
                buildedOptions.signal = abortController.signal;
                buildedOptions.timeout = 20000;
                this.runningRequest.set( this.getRequestKey({ url, options, settings }), abortController );
            }
            
            const response = method( url, buildedOptions );
            
            const HTTPResponseInstance = new HTTPResponseStore({
                response,
                requestClient: this,
                method,
                requestData: { url, options, settings },
                isOriginalResponse: settings.isOriginalResponse
            });
            
            return HTTPResponseInstance;
        } catch( e ) {
            // console.warn({ e })
        }
        
    }

    customNoStore( method, url = null, options = {}, settings = {}) {
        
        if( typeof method !== 'function' || !url )
            return null;

        settings.isDisableResponseCache ??= false;

        try {
            
            const backendUrl = settings?.backendUrl ?? this?.backendUrl ?? '';
            url = `${backendUrl}${url}`;
    
            const buildedOptions = this.fillOptions( options, { backendUrl, ...settings } );
            const response = method( url, buildedOptions );
            return response;
            
        } catch( e ) {
            // console.warn({ e })
        }
        
    }

    fillOptions(options, settings) {

        options ??= {};
        settings ??= {};
    
        options.hooks ??= {};
    
        if( !options?.hooks?.afterResponse || !Array.isArray(options.hooks.afterResponse) )
            options.hooks.afterResponse = [];
    
        if( !options?.hooks?.beforeRequest || !Array.isArray(options.hooks.beforeRequest) )
            options.hooks.beforeRequest = [];
    
        options.headers ??= {};
        
        if( options.headers['Content-Type'] === undefined && !settings?.isNotHeaderAddContentType )
            options.headers['Content-Type'] = 'application/ld+json';
        
        options.headers['X-LOCALE'] = AppConfig.currentLocale;

        if( settings?.isPublic )
            return options;
    
        // TODO: !!! test duplicate pushing
        options.hooks.afterResponse.push(
            async (request, options, response) => {
    
                if (response.status === 401) {
                    try {
                        const backendUrl = settings?.backendUrl ?? '';
    
                        let responseRefresh= await ky.post(
                            `${backendUrl}/core_api/auth/refresh_token`, 
                            {
                                json: {
                                    "refresh_token": UserStore.refreshToken
                                }
                            }
                        );
                        responseRefresh = await responseRefresh.json();
    
                        UserStore.setToken( responseRefresh.token );
                        UserStore.setMercureToken( responseRefresh.mercure_token );
                        UserStore.setRefreshToken( responseRefresh.refresh_token );
                        UserStore.setRefreshTokenExpiration( responseRefresh.refresh_token_expiration );
    
                        // Retry with the token
                        request.headers.set('Authorization', `Bearer ${responseRefresh.token}`);
    
                        return ky(request);
                    } catch(e) {
                        UserStore.clear();
                        location.href = '/auth/login';
                        return;
                    }
                }
    
                return request;
            }
        );
        
        const setHeadersBearerToken = request => {
            request.headers.set('Authorization', `Bearer ${UserStore.token}`);
        }
    
        options.hooks.beforeRequest.push(setHeadersBearerToken);
    
        return options;
    }

    getRequestKey({ url = null, options = {}, settings }) {
        return JSON.stringify({
            url,
            searchParams: options.searchParams ?? {},
            headers: options.headers ?? {}
        });
    }
}