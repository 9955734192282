import { observer } from 'mobx-react-lite';
import { HeaderPage } from '@/Core/UI/HeaderPage';
import { List } from '../Components/List';
import { Filter } from "../Components/Filter";

import { useCrudResourceContext } from '../CrudResourceContextProvider';
import { Card, Divider, Drawer } from 'antd';

import './CrudResourceTemplate.css';

export const CrudResourceTemplate = ({ headerPage, list }) => {
    
    const crudContext = useCrudResourceContext();

    const FilterObserver = observer(({ children }) => {

        if( crudContext.isFilter && !crudContext.isMobileMode && crudContext.mode === 'table' ) {
                return <div style={{ display: crudContext.isFilterOpen ? 'block' : 'none' }}>{children}</div>;
        }

        if( crudContext.isFilter && crudContext.isMobileMode && crudContext.mode === 'table' ) {
            return <Drawer 
                open={crudContext.isOpenMobileFilter} 
                onClose={() => crudContext.setIsFilterOpen(false)} 
                destroyOnClose={true}
                styles={{ 
                    header: { display: 'none' },
                    body: { padding: 0 }
                }}
                width={300}
            >
                {children}
            </Drawer>
        }
        
        return null;
    });

    const DividerObserver = observer(() => {
        if( crudContext.isFilterOpen && crudContext.isFilter && crudContext.mode === 'table' && !crudContext.isMobileMode ) {
            return <Divider type="vertical" style={{ margin: 0 /*, minHeight: 'calc(100vh - 110px)'*/ }} />;
        }
        return null;
    });

    const ContentObserver = observer(({children}) => {
        return <Card 
            className='wl-card' 
            bordered={false} 
            style={{ 
                width: crudContext.isFilterOpen && crudContext.isFilter && crudContext.mode === 'table' && !crudContext.isMobileMode ? 'calc(100% - 300px)' : '100%', 
                // minHeight: 'calc(100vh - 110px)' 
            }}
        >
            {children}
        </Card>;
    });

    return <div style={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column'
    }}>
        {headerPage !== false && <HeaderPage {...headerPage} storages={{ resource: crudContext?.resource }} />}
        <div className='wl-content-wrapper'>
            <FilterObserver>
                <Filter />
            </FilterObserver>
            <DividerObserver />
            <ContentObserver>
                <List list={list} />
            </ContentObserver>
        </div>
    </div>;
};