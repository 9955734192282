import { CrudResourceContextProvider, createCrudResourceContext, useCrudResourceContext } from "./CrudResourceContextProvider";
import { CrudResourceTemplate } from "./Templates/CrudResourceTemplate";
import { createFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { Error403 } from "../Error/Error403";
import { DeepCloneTask } from "@/Core/Tasks/Utils/DeepCloneTask";
import { Drawer as DrawerCore } from "@/Core/UI/Drawer";
import { Form } from './Components/Form';
import { createDrawerContext } from '@/Core/UI/Drawer/DrawerContextProvider';

import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { ServiceStore } from '@/Core/Stores/ServiceStore'; 

import { MergeConfigAndPropsTask } from '@/Core/Tasks/Utils/MergeConfigAndPropsTask';
import { App, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";

import { MdOutlineEdit } from "react-icons/md";
import { IoSaveOutline } from "react-icons/io5";
import { GrView } from "react-icons/gr";
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const CrudResource = ({
    crudContext,
    headerPage, 
    filter,
    form,
    list,
    isDrawerOnly,
    isFilterOpen,
    isFilter,
    prefilter,
    resource,
    mode,
    modeItems,
    isLiveCrud,
    ...crudProps
}) => {
    
    headerPage = headerPage ?? {};
    filter = filter ?? {};
    form = form ?? {};
    list = list ?? {};
    modeItems ??= {};
    mode ??= null;
    isDrawerOnly ??= false;
    
    /*
    const location = useLocation();

    const regex = /\/(\d+)\/(\w+)$/;
    const match = location.pathname.match(regex);

    let openResourceId = null;
    let openResourceMode = null;

    if( match ) {
        openResourceId = parseInt(match[1], 10);
        openResourceMode = match[2];
    }

    const navigate = useNavigate();
*/
    const { notification } = App.useApp();
    const { t } = useTranslation();
    const crudResourceConfig = ConfigStore.get('CrudResourceConfig', {});
    const resourceClient = ServiceStore.get('resourceClient');
    
    const parentCrudContext = useCrudResourceContext();

    crudContext = crudContext ?? createCrudResourceContext({
        isFilterOpen,
        isFilter,
        prefilter,
        resource,
        mode,
        modeItems,
        isLiveCrud
    });

    if( parentCrudContext )
        crudContext.setParentCrudContext( parentCrudContext );

    const props = MergeConfigAndPropsTask({
        crudResourceConfig, 
        crudProps
    });

    if( headerPage !== false && typeof props?.headerPage === 'object' ) {
        headerPage = MergeConfigAndPropsTask({ 
            headerPageProps: props.headerPage, 
            headerPage 
        });
        delete props.headerPage;
    }
    
    if( typeof props === 'object' && !isDrawerOnly )
        crudContext.mergeToInstance({ ...props });

    if( typeof crudContext.resource !== 'object' )
        throw new Error('Error: CrudResource component, empty resource parameter!');

    if( !form?.fields && crudContext.resource?.fields )
        form.fields = crudContext.resource.fields;

    // TODO: !!!
    if( !form.fields?.id )
        form.fields = { id: { label: t("Id", { ns: 'Core' })}, ...form.fields };

    // TODO: !!!
    if( typeof filter.fields !== 'object' )
        filter.fields = DeepCloneTask(form.fields);

    if( typeof filter.fields === 'object' ) {
        Object.keys(filter.fields).map( key => {
            
            let field = filter.fields[key];

            delete field?.isRequired;
            delete field?.isMultiple;
            delete field?.inputProps?.disabled;
            delete field?.inputProps?.mode;

            if( field.type === 'date' )
                field.type = 'daterange';

            if( field.type === 'datetime' )
                field.type = 'datetimerange';

            if( field.type === 'switch' || field.type === 'checkbox' ) {
                field.type = 'select';
                field.inputProps ??= {};
                field.options = [
                    { label: t("Yes", { ns: 'Core' }), value: true },
                    { label: t("No", { ns: 'Core' }), value: false }
                ];
                field.inputProps.allowClear = true;
            }

            filter.fields[key] = field;
        });
    }

    if( mode )
        crudContext.setMode( mode );

    if( Object.keys(modeItems).length > 0 )
        crudContext.setModeItems( modeItems );

    crudContext.setFormContext(
        createFormContext( form )
    );

    crudContext.setFilterFormContext(
        createFormContext( filter )
    );
    crudContext.setActions({
        create: async (initialValues) => {

            initialValues ??= {};

            crudContext.formContext.reset();
            if( typeof form.initialValues === 'object' ) {
                initialValues = { ...form.initialValues, ...initialValues };
            }

            if( Object.keys(initialValues).length > 0)
                crudContext.formContext.setInitialValues(initialValues);
            
            crudContext.formContext.setIsLoading( false );
            crudContext.formContext.setIsEditable(true);
            crudContext.drawerContext.setIsOpen(true);
        },
        view: (item) => {

            crudContext.formContext.reset();
            crudContext.formContext.setIsLoading( true );
            crudContext.formContext.setIsEditable(false);
            crudContext.drawerContext.setIsOpen(true);

            resourceClient
                .get(crudContext.resource, {}, {
                    restUriReplaceParams: {
                        id: item.id
                    }
                })
                .getResponse()
                .then( initialValues => {

                    crudContext.eventManager.run('onResponseRequest', {
                        initialValues, crudContext, list, headerPage
                    });
                    crudContext.eventManager.run('onResponseViewRequest', {
                        initialValues, crudContext, list, headerPage
                    });

                    crudContext.formContext.mergeInitialValues(initialValues);
                    crudContext.formContext.setStorage('responseData', {...initialValues});
                    crudContext.formContext.setIsLoading( false );
                })
                .catch(() => {

                    notification.error({
                        duration: 3,
                        message: 'Не знайдено.',
                        description: 'Обраної сутності не існує.',
                        placement: 'bottomRight'
                    });

                    setTimeout(() => {
                        // navigate(location.pathname.replace(`/${openResourceId}/${openResourceMode}`, ''))
                    }, 1500);

                });
        },
        edit: (item) => {
            crudContext.formContext.reset();
            crudContext.formContext.setIsLoading( true );
            crudContext.formContext.setIsEditable(true);
            crudContext.drawerContext.setIsOpen(true);
            resourceClient
                .get(crudContext.resource, {}, {
                    restUriReplaceParams: {
                        id: item.id
                    }
                })
                .getResponse()
                .then( initialValues => {
                    
                    crudContext.eventManager.run('onResponseRequest', {
                        initialValues, crudContext, list, headerPage
                    });
                    crudContext.eventManager.run('onResponseEditRequest', {
                        initialValues, crudContext, list, headerPage
                    });

                    crudContext.formContext.mergeInitialValues(initialValues);
                    crudContext.formContext.setStorage('responseData', {...initialValues});
                    crudContext.formContext.setIsLoading( false );
                })
                .catch(() => {
                    notification.error({
                        duration: 3,
                        message: 'Не знайдено.',
                        description: 'Обраної сутності не існує.',
                        placement: 'bottomRight'
                    });

                    setTimeout(() => {
                        // navigate(location.pathname.replace(`/${openResourceId}/${openResourceMode}`, ''))
                    }, 1500);

                });
        },
        delete: (id) => {

            resourceClient
                .delete(crudContext.resource, {}, {
                    restUriReplaceParams: { id }
                })
                .getResponse()
                .then( () => {
                    crudContext.listContext.actions.reload();
                    crudContext.eventManager.run('onAfterDeleteRequest', { id, crudContext });
                })
                .catch( async error => {
                    
                    let response = error.response;
                    response = await response.json();

                    notification.error({
                        duration: 3,
                        message: t("Error", { ns: 'Core' }),
                        description: response['hydra:description'],
                        placement: 'bottomRight'
                    });
                });

            
        },
        reload: () => {
            const id = crudContext.formContext.values?.id ?? 0;

            if( !id )
                return;

            if( crudContext.formContext.isEditable )
                crudContext.actions.edit( { id } );
            else 
                crudContext.actions.view( { id } );
        }
    });

    crudContext.eventManager.run('afterInit', {
        crudContext, list, headerPage
    });

    const TemplateError403 = crudContext.templateError403 ?? Error403;

    if( crudContext.accessManager.isCan('read') === false )
        return <TemplateError403 />;

    const Template = crudContext.template ?? CrudResourceTemplate;

    let drawerProps = crudContext.resource?.drawer ?? {};

    if( typeof drawerProps === 'function' )
        drawerProps = drawerProps({ crudContext });

    drawerProps = drawerProps ?? {};

    if( typeof crudContext.props.drawer === 'object' )
        drawerProps = { ...drawerProps, ...crudContext.props.drawer };

    if( typeof props?.drawer === 'object' )
        drawerProps = { ...drawerProps, ...props.drawer };

    if( !drawerProps?.title && crudContext?.resource?.title ) {
        drawerProps.title = crudContext.resource.title;
    }

    if( !drawerProps?.width )
        drawerProps.width = 500;

    drawerProps.eventHandlers ??= {};
    drawerProps.eventHandlers.onButtons ??= {};
    drawerProps.eventHandlers.onClose ??= {};

    if( !drawerProps.isDisabledEditButton )
        drawerProps.eventHandlers.onButtons.edit  = ({ buttons, crudContext }) => {
            buttons.edit = {
                sort: 1000,
                icon: <MdOutlineEdit />,
                depFn: ({ crudContext }) => !crudContext.formContext.isEditable && crudContext.formContext.values?.id > 0
                                            && crudContext.accessManager.isCan('update', crudContext.formContext.values),
                priority: 'button',
                size: 'middle',
                onClick: () => {
                    crudContext.actions.edit( crudContext.formContext.values );
                },
                label: t("Edit", { ns: 'Core' })
            }
        }
    
    if( !drawerProps.isDisabledViewButton )
        drawerProps.eventHandlers.onButtons.view = ({ buttons, crudContext }) => {
            buttons.view = {
                sort: 1000,
                icon: <GrView />,
                depFn: ({ crudContext }) => crudContext.formContext.isEditable && crudContext.formContext.values?.id,
                size: 'middle',
                label: t("View", { ns: 'Core' }),
                onClick: () => {
                    crudContext.actions.view( crudContext.formContext.values );
                }
            }
        }

    if( !drawerProps.isDisabledSaveButton )
        drawerProps.eventHandlers.onButtons.save = ({ buttons, crudContext }) => {
            buttons.save = {
                sort: 900,
                icon: <IoSaveOutline style={{ fontSize: 19 }} />,
                depFn: ({ crudContext }) => {
                    return crudContext.formContext.isEditable && (
                        (
                            crudContext.formContext.values?.id > 0
                            && crudContext.accessManager.isCan('update', crudContext.formContext.values)
                        )
                        ||
                        (
                            crudContext.accessManager.isCan('create')
                            && !crudContext.formContext.values?.id
                        )
                    )
                },
                get loading(){
                    return crudContext.formContext.isLoading;
                },
                type: 'primary',
                size: 'middle',
                onClick: () => {
                    if( crudContext?.formComposerContext )
                        crudContext.formComposerContext.submit();
                    else
                        crudContext.formContext.submit();
                },
                label: t("Save", { ns: 'Core' })
            }
        }
    /*
    if( !parentCrudContext )
        drawerProps.eventHandlers.onClose.changeUrl = () => {
            if( openResourceId && openResourceMode )
                navigate(location.pathname.replace(`/${openResourceId}/${openResourceMode}`, ''))
        }
    */
    crudContext.drawerContext = createDrawerContext({
        ...drawerProps
    });

    useEffect(() => {

        const handleResize = () => {
            
            if( !crudContext.isMobileMode && window.innerWidth <= crudContext.mobileModeWidth  )
                crudContext.setIsMobileMode( true );

            if( crudContext.isMobileMode && window.innerWidth > crudContext.mobileModeWidth  )
                crudContext.setIsMobileMode( false );
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    /*
    if( typeof openResourceId === 'number' && !parentCrudContext ) {
        switch (openResourceMode) {
            case 'create':
                crudContext.actions.create();
                break;
            case 'view':
                crudContext.actions.view({ id: openResourceId });
                break;
            case 'edit':
                crudContext.actions.edit({ id: openResourceId });
                break;
        }
    }
    */
    return <>
        <CrudResourceContextProvider value={crudContext}>
            {!isDrawerOnly && <Template list={list} headerPage={headerPage} />}
            <DrawerCore 
                className='wl-drawer'
                drawerContext={crudContext.drawerContext}
            >
                {() => <Form />}
            </DrawerCore>
        </CrudResourceContextProvider>
    </>    
}