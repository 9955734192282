import { Button, Drawer } from 'antd';
import { observer } from "mobx-react-lite";
import { MdOutlineDeleteSweep } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import { Form, FormField, FormBlock } from "@/Core/UI/Form";
import { createFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';

import i18n from '@/Ship/i18n';

import { AddMultipleSaleItemStore } from '../Stores/AddMultipleSaleItemStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';

import './useAddSaleItemButtonHook.css';

export const useAddSaleItemButtonHook = () => {

    const restClient = ServiceStore.get('restClient');
    const crudContext = useCrudResourceContext();
    const showErrorNotificationTask = useShowErrorNotificationTask();

    const AddForm = () => {
        const formContext = createFormContext({
            fields: {
                purchase: {
                    label: 'Закупівля',
                    type: 'select',
                    isRequired: true,
                    resource: 'Purchase.Purchase',
                    responseLabel: value => value.contractNumber,
                    filter: {
                        product: crudContext.formContext.values.product['@id']
                    }
                },
                quantity: {
                    label: 'Кількість',
                    type: 'number'
                }
            },
            initialValues: {
                entity: crudContext.formContext.values['@id']
            },
            onSubmit: async ({ values }) => {
                
                values.entity = crudContext.formContext.values['@id'];
                values.purchase = values.purchase['@id'];

                const response = await restClient.post('/core_api/sale/items', {
                    json: values
                }).getResponse();

                return response;
            }
        });
        AddMultipleSaleItemStore.addForm({ formContext });
    }

    const SubmitForms = async () => {

        let issetError = false;

        for( let i = 0; i < AddMultipleSaleItemStore.forms.length; i++ ) {
            const formContext = AddMultipleSaleItemStore.forms[i].formContext;
            await formContext.validate();
            if( !formContext.isValid )
                issetError = true;  
        }

        if( issetError )
            return;

        AddMultipleSaleItemStore.setIsSendRequest( true );
        try {
            for( let i = 0; i < AddMultipleSaleItemStore.forms.length; i++ ) {
                const formContext = AddMultipleSaleItemStore.forms[i].formContext;
                await formContext.submit();
            }
        }
        catch( error ) {
            (async () => {
                const response = await error.response.json();
                if( response && response['@type'] === 'hydra:Error' ) {
                    showErrorNotificationTask('Помилка', response['hydra:description']);
                }
            })();
        }
        

        AddMultipleSaleItemStore.setIsSendRequest( false );
        AddMultipleSaleItemStore.setIsDrawerOpen( false );
        AddMultipleSaleItemStore.setForms([]);
        crudContext.actions.reload();
        crudContext.listContext.actions.reload();
    }

    const SaveButton = observer(() => 
        <Button 
            type='primary' 
            loading={AddMultipleSaleItemStore.isSendRequest} 
            onClick={() => SubmitForms()} 
            size='middle'
        >
            Зберегти
        </Button>
    );

    const AddMultipleApplication = observer(() => {

        if( AddMultipleSaleItemStore.forms.length === 0 )
            AddForm(); 

        return <>
            <Drawer 
                title={'Додати'}
                width={500}
                open={AddMultipleSaleItemStore.isDrawerOpen} 
                destroyOnClose={true} 
                onClose={() => AddMultipleSaleItemStore.setIsDrawerOpen(false) } 
                extra={<SaveButton />}
                className='multiple-sale-item'
            >
                {AddMultipleSaleItemStore.forms.map( ( form, index ) => {
                    return (
                        <FormBlock 
                            title={index + 1 } 
                            key={`k-${index}`}
                            cardProps={{ 
                                size: 'small',
                                extra: index !== 0 ? <Button 
                                    type='primary' 
                                    icon={<MdOutlineDeleteSweep style={{ fontSize: 22 }} />} 
                                    danger={true}
                                    onClick={() => {
                                        AddMultipleSaleItemStore.removeForm(index);
                                    } }
                                /> : null
                            }}
                        >
                            <Form {...form}>
                                <FormField name='purchase' />
                                <FormField name='quantity' depFn={({ formContext, field }) => {
                                    
                                    const count = formContext?.values?.purchase?.quantity ?? 0;
                                    formContext.setInitialValue('quantity', count);
                                    field.setInputProps({ max: count });
                                    
                                }} />
                            </Form>
                        </FormBlock>
                    );
                })}
                {/* <Button type='primary' style={{ width: '100%' }} onClick={() => AddForm()}>Додати ще...</Button> */}
            </Drawer>
        </>
    });

    return { 
        AddButton: {
            label: i18n.t("Add", { ns: 'Core' }), 
            icon: <AiOutlineAppstoreAdd style={{ fontSize: 24 }} />, 
            sort: 100, 
            priority: 'button',
            size: 'middle',
            type: 'primary',
            depFn: ({ crudContext }) => crudContext.accessManager.isCan('create') === true,
            style: { display: 'flex', alignItems: 'center', position: 'relative' },
            onClick: () => AddMultipleSaleItemStore.setIsDrawerOpen(true)
        }, 
        AddButtonDrawer: <AddMultipleApplication />
    }
}