import { Form, FormField } from "@/Core/UI/Form";
import { Button, Card, theme } from "antd";
import { GrClose } from "react-icons/gr";

import { useCrudResourceContext } from "../CrudResourceContextProvider";
import './Filter.css';
const { useToken } = theme;
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";

export const Filter = () => {
    
    const crudContext = useCrudResourceContext();
    const { token } = useToken();
    const { t } = useTranslation();

    let fields = [];

    crudContext.filterFormContext.fields.forEach( field => {
        if( Array.isArray(crudContext.filterFormContext.props.includeFields) && !crudContext.filterFormContext.props.includeFields.includes(field.name) )
            return;
        
        let fieldProps = {};

        if( crudContext.filterFormContext.props?.fieldProps && typeof crudContext.filterFormContext.props?.fieldProps[field.name] === 'object' )
            fieldProps = crudContext.filterFormContext.props?.fieldProps[field.name];

        fields.push(<FormField key={field.name} name={field.name} {...fieldProps} />);
    });

    if( Array.isArray(crudContext.filterFormContext.props.addFields) )
        crudContext.filterFormContext.props.addFields.map( field => fields.push(<FormField key={field.name} {...field} />));

    if( Array.isArray(crudContext.filterFormContext.props.includeFields) ) {
        const orderMap = crudContext.filterFormContext.props.includeFields.reduce((acc, key, index) => {
            acc[key] = index;
            return acc;
          }, {});
          fields.sort((a, b) => orderMap[a.key] - orderMap[b.key]);
    }

    const filterClear = () => {
        crudContext.listContext.setFilter({});
        crudContext.filterFormContext.reset();
        crudContext.listContext.actions.reload();

        if( typeof crudContext.storages?.singleFilterForms === 'object' ) {
            Object.keys( crudContext.storages.singleFilterForms ).map( key => {
                const form = crudContext.storages.singleFilterForms[key];
                form.setInitialValues({});
            });
        }

        if( crudContext.isMobileMode )
            crudContext.setIsOpenMobileFilter(false);
    };

    const submitHandler = () => {
        crudContext.listContext.setFilter(
            crudContext.filterFormContext.values
        );
        crudContext.listContext.actions.reload();
    }

    crudContext.filterFormContext.addEventHandlers('onChange', {submitHandler});

    return <div style={{ width: 300 }} className='filter-col'>                    
        <Card 
            className='wl-card'
            title={<span style={{fontSize: 20}}>{t("Filters", { ns: 'Core' })}</span>}
            bordered={false}
            extra={
                <a onClick={() => {
                    if( crudContext.isMobileMode )
                        crudContext.setIsOpenMobileFilter(false);
                    else
                        crudContext.setIsFilterOpen(false);
                }}>
                    <GrClose style={{ fontSize: 22, color: token.colorPrimary, marginTop: 10 }} />
                </a>
            }
            actions={[
                <Button size="middle" key='crudresource-filter-apply' type='primary' onClick={submitHandler}>{t("Filter", { ns: 'Core' })}</Button>,
                <Button size="middle" key='crudresource-filter-clear' onClick={filterClear}>{t("Clear", { ns: 'Core' })}</Button>
            ]}
        >
            <Form formContext={crudContext.filterFormContext}>
                {fields}
            </Form>
        </Card>
    </div>
}