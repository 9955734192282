import { CrudResource } from "@/Core/UI/CrudResource";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { ApplicationItemFormTemplate } from '../Templates/ApplicationItemFormTemplate';
import { useAddButtonHook } from '../Hooks/useAddButtonHook';
import { Form, FormField, createFormContext } from "@/Core/UI/Form";
import { Button, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { LoadApplicationItemTask } from "../Tasks/LoadApplicationItemTask";
// import { AiOutlineEdit } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { EditApplicationItemTask } from "../Tasks/EditApplicationItemTask";
import { DeleteApplicationItemTask } from "../Tasks/DeleteApplicationItemTask";
import { toJS } from "mobx";
import './ApplicationItemCrud.css';
import { useImportTransportHook } from '../Hooks/useImportTransportHook';

export const ApplicationItemCrud = () => {

    const crudContext = useCrudResourceContext();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ dataSource, setDataSource ] = useState([]);
    const formContext = createFormContext({
        onChange: ({ changedValues, formContext }) => {
            const keys = Object.keys(changedValues);
            const currentKey = keys[0].split(',');
            const index = currentKey[0];
            const data = toJS(formContext.values[index]);
            const item = dataSource[index];

            (async () => {

                const response = await EditApplicationItemTask(item['@id'], data);

                if( response?.entity?.totalUnloadedQuantity !== undefined )
                    crudContext.formContext.setInitialValue('totalUnloadedQuantity', response.entity.totalUnloadedQuantity );
                if( response?.entity?.totalTargetWeight !== undefined )
                    crudContext.formContext.setInitialValue('totalTargetWeight', response.entity.totalTargetWeight );
                if( response?.entity?.totalLoadedQuantity !== undefined )
                    crudContext.formContext.setInitialValue('totalLoadedQuantity', response.entity.totalLoadedQuantity );
                
                crudContext.listContext.actions.reload();
            })();
        }
    });
    const { AddButton, AddButtonDrawer } = useAddButtonHook();
    const { ImportTransportButton, ImportTransportDrawer } = useImportTransportHook();

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const items = await LoadApplicationItemTask(crudContext.formContext.values['@id']);

            if( items.length > 0 )
                setDataSource( items );

            setIsLoading(false);
        })();
    }, []);

    if( !crudContext )
        return;

    const columns = [
        { 
            title: 'Номер автомобіля / Номер вагону', 
            key: 'transportNumber', 
            dataIndex: 'transportNumber',
            render: ( value, item, index ) => {
                return <FormField name={[index, 'transportNumber']} initialValue={value} />
            }
        },
        { 
            title: 'Завантажена кількість', 
            key: 'loadedQuantity', 
            dataIndex: 'loadedQuantity',
            render: ( value, item, index ) => {
                return <FormField type='number' name={[index, 'loadedQuantity']} initialValue={value} />
            }
        },
        { 
            title: 'Вивантажена кількість', 
            key: 'unloadedQuantity', 
            dataIndex: 'unloadedQuantity',
            render: ( value, item, index ) => {
                return <FormField type='number' name={[index, 'unloadedQuantity']} initialValue={value} />
            }
        },
        { 
            title: 'Залікова вага', 
            key: 'targetWeight', 
            dataIndex: 'targetWeight',
            render: ( value, item, index ) => {
                return <FormField type='number' name={[index, 'targetWeight']} initialValue={value} />
            }
        },
        { 
            title: 'ТТН', 
            key: 'ttn', 
            dataIndex: 'ttn',
            render: ( value, item, index ) => {
                return <FormField name={[index, 'ttn']} initialValue={value} />
            }
        },
        {
            title: '',
            key: '_actions', 
            dataIndex: '_actions',
            render: ( value, item, index ) => {
                return <Space>
                    {/* <Button icon={<AiOutlineEdit />} onClick={async () => {
                        const values = toJS(formContext.values);
                        const data = values[index];
                        await EditApplicationItemTask(item['@id'], data);
                        crudContext.actions.reload();
                        crudContext.listContext.actions.reload();
                    }}/> */}
                    <Button icon={<AiOutlineDelete />} onClick={async () => {
                        await DeleteApplicationItemTask(item['@id']);
                        crudContext.actions.reload();
                        crudContext.listContext.actions.reload();
                    }} danger={true} />
                </Space>
            }
        }
    ];

    return <div className="purchase-application-item">
        <Space style={{ marginLeft: 'auto' }}>
            <Button {...ImportTransportButton} style={{ margin: '0px 0px 10px auto'}}>{ImportTransportButton.label}</Button>
            <Button {...AddButton} style={{ margin: '0px 0px 10px auto'}}>{AddButton.label}</Button>
        </Space>
        <Form formContext={formContext}> 
            <Table columns={columns} dataSource={dataSource} loading={isLoading} pagination={false} />
            {AddButtonDrawer}
            {ImportTransportDrawer}
        </Form>
    </div>
}