import { Space } from "antd";
import { FormField } from "@/Core/UI/Form";
import { Button } from "@/Core/UI/Button";
import { LoadUseCoreTask } from '@/Ship/Tasks/LoadUseCoreTask';
import { useShowInfoNotificationTask } from "@/Ship/Tasks/ShowInfoNotificationTask";
import { useFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';

export const YouControlField = ({ name }) => {

    const ShowInfoNotificationTask = useShowInfoNotificationTask();
    const formContext = useFormContext();
    return <div>
        <Space>
            <FormField name={name} inputProps={{
                suffix: <Button 
                    size='middle' 
                    type='primary'
                    onClick={() => LoadUseCoreTask(formContext.values[name], formContext, ShowInfoNotificationTask)}
                    depFn={ ({ formContext }) => formContext.isEditable && formContext.values[name] !== undefined && formContext.values[name].length > 0 }
                >
                    YouControl
                </Button>
            }} />
        </Space>
    </div>;
}