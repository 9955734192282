import { GiOrganigram } from "react-icons/gi";
import { IoSettingsOutline } from "react-icons/io5";
import { TbVocabulary } from "react-icons/tb";
import { IoPeopleCircleOutline } from "react-icons/io5";
import { IoHomeOutline } from "react-icons/io5";
import i18n from '@/Ship/i18n';
import { UserStore } from "@/Core/Stores/UserStore";
import { PiTrain } from "react-icons/pi";

import { NotificationBarIcon } from "@/Containers/CommunicationSection/NotificationContainer/Components/NotificationBarIcon";
import { IoMdNotificationsOutline } from "react-icons/io";
import { 
    HiOutlineClipboardList, 
    HiOutlineHome,
    HiOutlineServer,
    HiOutlineIdentification,
    HiOutlineTruck,
    HiOutlineShoppingCart,
    HiOutlineClipboardCheck,
    HiOutlineUsers,
    HiOutlineUserGroup,
    HiOutlineCurrencyDollar,
    HiOutlineCreditCard
} from 'react-icons/hi';
import { BsTruckFront } from "react-icons/bs";
import { MdSwitchAccessShortcut } from "react-icons/md";

export const Menu = [
    {
        label: 'Робочий стіл',
        key: 'section_home',
        icon: <IoHomeOutline style={{ fontSize: 22 }} />,
        path: '/',
    },
    {
        label: <NotificationBarIcon />,
        key: 'section_notifications',
        icon: <IoMdNotificationsOutline style={{ fontSize: 22 }} />,
        path: '#'
    },
    {
        type: 'group',
        label: 'Закупівлі',
        key: 'section_purchase',
        children: [
            {
                label: 'Постачальники',
                path: '/purchase/supplier',
                key: 'section_purchase_supplier',
                icon: <HiOutlineTruck style={{ fontSize: 22 }} />
            },
            {
                label: 'Перевізники',
                path: '/purchase/carrier',
                key: 'section_purchase_carrier',
                icon: <HiOutlineUserGroup style={{ fontSize: 22 }} />
            },
            {
                label: 'Закупівлі',
                path: '/purchase/purchase',
                key: 'section_purchase_purchase',
                icon: <HiOutlineShoppingCart style={{ fontSize: 22 }} />
            },
            {
                label: 'Заявки на переміщення',
                path: '/purchase/application',
                key: 'section_purchase_application',
                icon: <HiOutlineClipboardCheck style={{ fontSize: 22 }} />
            },
            {
                label: 'Транспорт',
                path: '/purchase/application-item',
                key: 'section_purchase_application_item',
                icon: <BsTruckFront style={{ fontSize: 22 }} />
            }
        ]
    },
    {
        type: 'group',
        label: 'Продажі',
        key: 'section_sale',
        accessFn: () => !['ROLE_BUYER'].some(value => UserStore.user?.roles.includes(value)) ? true : false,
        children: [
            {
                label: 'Клієнти',
                path: '/sale/client',
                key: 'section_sale_client',
                icon: <HiOutlineUsers style={{ fontSize: 22 }} />
            },
            {
                label: 'Продажі',
                path: '/sale/sale',
                key: 'section_sale_sale',
                icon: <HiOutlineCurrencyDollar style={{ fontSize: 22 }} />
            },
            // {
            //     label: 'Заявки на транспорт (ЖД)',
            //     path: '/sale/application',
            //     key: 'section_sale_application',
            //     icon: <PiTrain style={{ fontSize: 22 }} />
            // },
            // {
            //     label: 'Оплати',
            //     path: '#',
            //     key: 'section_sale_payment',
            //     icon: <HiOutlineCreditCard style={{ fontSize: 22 }} />
            // },
            {
                label: 'Статус контрактів',
                path: '/sale/sale-status',
                key: 'section_sale_status',
                icon: <MdSwitchAccessShortcut style={{ fontSize: 22 }} />
            }
        ]
    },
    {
        type: 'group',
        label: 'Номенклатура',
        key: 'section_company',
        children: [
            {
                label: 'Номенклатура',
                path: '/company/product',
                key: 'section_company_product',
                icon: <HiOutlineClipboardList style={{ fontSize: 22 }} />
            },
            {
                label: 'Склади',
                path: '/company/warehouse',
                key: 'section_company_warehouse',
                icon: <HiOutlineHome style={{ fontSize: 22 }} />
            },
            {
                label: 'Послуги складів',
                path: '/company/warehouse-service',
                key: 'section_company_warehouse_service',
                icon: <HiOutlineServer style={{ fontSize: 22 }} />
            }
        ]
    },
    {
        type: 'group',
        label: 'Команда',
        key: 'section_team',
        children: [
            {
                label: 'Структура компанії',
                path: '/company/department',
                key: 'section_team_department',
                icon: <GiOrganigram style={{ fontSize: 22 }} />
            },
            {
                label: 'Співробітники',
                path: '/company/user',
                key: 'section_team_user',
                icon: <IoPeopleCircleOutline style={{ fontSize: 22 }} />
            }
        ],
    },
    {
        type: 'group',
        label: 'Налаштування',
        accessFn: () => ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => UserStore.user?.roles.includes(value)) ? true : false,
        children: [
            {
                label: 'Налаштування порталу',
                path: '/company/setting',
                key: 'section_team_setting',
                icon: <IoSettingsOutline style={{ fontSize: 22 }} />,
                accessFn: (mode) => {

                    const roles = UserStore.user?.roles ?? [];
                    const rules = {
                        create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => roles.includes(value)) ? true : false,
                        read: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => roles.includes(value)) ? true : false,
                        update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => roles.includes(value)) ? true : false,
                        delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => roles.includes(value)) ? true : false,
                    };

                    if( rules[mode] !== undefined )
                        return rules[mode];
            
                    return false;
                }
            },
            {
                label: 'Довідники',
                path: '/list',
                key: 'section_list',
                accessFn: () => ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some(value => UserStore.user?.roles.includes(value)) ? true : false,
                icon: <TbVocabulary style={{ fontSize: 22 }} />
            }
        ]
    }
];