import { App } from "antd";

export const useShowErrorNotificationTask = () => {

    const { notification } = App.useApp();

    const ShowErrorNotificationTask = (message, description) => {
    
        notification.error({
            duration: 3,
            message,
            description,
            placement: 'bottomRight'
        });
    
        return false;
    }

    return ShowErrorNotificationTask;
}