import { NotificationResource } from "../Resources/NotificationResource";
import { IoAddCircleOutline } from "react-icons/io5";
import { NotificationFilter } from "./NotificationFilter";
import { LoadNotificationListTask } from "../Tasks/LoadNotificationListTask";
import { UserStore } from "@/Core/Stores/UserStore";
import { NotificationList } from "./NotificationList";
import { useFormModal } from "@/Ship/Hooks/useFormModal";
import { observer } from "mobx-react-lite";
import { Button, Drawer, Space } from "antd";
import { NotificationStore } from "../Stores/NotificationStore";
import { LuFilter } from "react-icons/lu";
import { LuFilterX } from "react-icons/lu";
import { AiOutlineRead } from "react-icons/ai";

import './NotificationDrawer.css';
import { useEffect } from "react";
import { ReadAllNotificationTask } from "../Tasks/ReadAllNotificationTask";

export const NotificationDrawer = observer(() => {

    const { Modal, localStorage } = useFormModal({
        fields: NotificationResource.fields,
        restUri: '/core_api/communications/notifications',
        onBeforeSubmit: ({ values }) => {
            values.type = 'user';
            values.user = values.user['@id'];
            values.title = 'Повідомлення'
            values.initiatedBy = UserStore.user['@id']
        },
        onAfterSuccessRequest: () => {
            LoadNotificationListTask();
        },
        requestSuccessMessage: 'Повідомлення успішно відправлено.'
    });

    useEffect(() => {
        LoadNotificationListTask();
    }, []);

    return <>
        <Drawer 
            width={600}
            title='Повідомлення' 
            open={NotificationStore.isDrawerOpen}
            extra={<Space>
                <Button onClick={() => NotificationStore.setIsFilterOpen(!NotificationStore.isFilterOpen) } size="middle" key='filter-button'>
                    {NotificationStore.isFilterOpen ? <LuFilterX style={{ fontSize: 20 }} /> : <LuFilter style={{ fontSize: 20 }} /> }
                </Button>
                <Button onClick={() => {
                    ReadAllNotificationTask();
                } } size="middle" key='read-all-button'>
                    <AiOutlineRead style={{ fontSize: 20 }} />
                </Button>
                <Button onClick={() => localStorage.setIsModalOpen(true) } size="middle" key='add-button'>
                    <IoAddCircleOutline style={{ fontSize: 20 }} />
                </Button>
            </Space>}
            className="notification-drawer"
            destroyOnClose={true}
            onClose={() => NotificationStore.setIsDrawerOpen(false)}
        >
            <NotificationFilter />
            <NotificationList />
            <Modal />
        </Drawer>
    </>
});