import { observer } from 'mobx-react-lite';
import React, { useState, useRef, useEffect } from 'react';
import { Input } from 'antd';
import AirDatepicker from 'air-datepicker';
import 'air-datepicker/air-datepicker.css';
import './DatePickerInput.css';
import localeUk from 'air-datepicker/locale/uk';
import localeEn from 'air-datepicker/locale/en';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { useDefaultLayoutContext } from '@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider';
import { CiCalendarDate } from "react-icons/ci";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const DatePickerInput = ({ field, index, options = {} }) => {

    const fieldRef = useRef(null);
    const datepickerRef = useRef(null);

    const defaultLayoutContext = useDefaultLayoutContext();
    const AppConfig = ConfigStore.get('AppConfig');

    const { onChange, ...props } = field.bind({ index });

    useEffect(() => {
        if (fieldRef.current) {

            const isShowTime = field.type === 'datetime' ? true : false;

            let datepickerOptions = {
                timepicker: isShowTime,
                locale: AppConfig?.currentLocale === 'uk' ? localeUk : localeEn,
                dateFormat: 'yyyy-MM-dd',
                onSelect: ({ date }) => {

                    if(
                        !datepickerOptions?.range && !isShowTime && date
                    ) {
                        datepickerRef.current.hide();
                    }

                    if( !Array.isArray(date) ) {

                        if( date instanceof Date && !dayjs.isDayjs(date) )
                            date = new dayjs(date);

                        onChange(date);
                    }
                },
                ...options,
                isMobile: true,
                range: false
            };

            if ( isShowTime ) {
                datepickerOptions.timeFormat = 'HH:mm';
                datepickerOptions.minutesStep = 5;
                datepickerOptions.buttons = [
                    {
                        content: 'Обрати',
                        onClick: function(dp) {
                            dp.hide();
                        }
                    }
                ];
            }

            datepickerRef.current = new AirDatepicker(fieldRef.current, datepickerOptions);

            if( field.value )
                datepickerRef.current.selectDate(field.value);
            else
                datepickerRef.current.clear();
        }

        return () => {
            if( datepickerRef.current && typeof datepickerRef.current.destroy === 'function' ) {
                datepickerRef.current.destroy();
                datepickerRef.current = null;
            }
        };
    }, [ fieldRef.current, field.value ]);
    
    const IconObserver = observer(() => {
        return <>
            {field.value && !props?.disabled && <span className="wl-datepicker-input-clear ant-select-clear" unselectable="on" aria-hidden="true" style={{ userSelect: 'none' }} onClick={() => {
                if( datepickerRef.current )
                    datepickerRef.current.clear();
            }}>
                <span role="img" aria-label="close-circle" className="anticon anticon-close-circle">
                    <svg fillRule="evenodd" viewBox="64 64 896 896" focusable="false" data-icon="close-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                        <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z"></path>
                    </svg>
                </span>
            </span>}
            {field.value && !props?.disabled && <span className="wl-datepicker-suffix">
                <CiCalendarDate />
            </span>}
            {!field.value && !props?.disabled && <span className="wl-datepicker-suffix no-hide">
                <CiCalendarDate />
            </span>}
        </>
    });

    const UpdateValue = observer(() => {
        
        const value = field.value;

        if (datepickerRef.current) {
            const currentDate = datepickerRef.current.selectedDates;
            
            const isDifferent = (a, b) => {
                if (Array.isArray(a) && Array.isArray(b)) {
                    return a.length !== b.length || a.some((date, i) => !dayjs(date).isSame(b[i]));
                }
                return !dayjs(a).isSame(b);
            };

            if (value && isDifferent(value, currentDate)) {
                datepickerRef.current.selectDate(value);
            } else if (!value && currentDate.length > 0) {
                datepickerRef.current.clear();
            }
        }
    });

    return (
        <span className="wl-datepicker">
            <input 
                ref={fieldRef} 
                className='wl-datepicker-input'
                readOnly={true}
                {...props}
            />
            <IconObserver />
            <UpdateValue />
        </span>
    );
};