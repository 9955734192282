import { App, ConfigProvider } from "antd";

import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { ServiceStore } from '@/Core/Stores/ServiceStore';

export const BaseLayout = ({ configs, services, children }) => {
    
    configs ??= {};
    services ??= {};

    ConfigStore.clear();
    ConfigStore.sets( configs );

    ServiceStore.clear();
    ServiceStore.sets( services );
    
    return <ConfigProvider 
        {...(configs.AntdConfig ?? {})}
    >
        <App>{children}</App>
    </ConfigProvider>;
}