import { AiOutlineLike } from "react-icons/ai";
import { useFormModal } from '@/Ship/Hooks/useFormModal';
import { UserStore } from "@/Core/Stores/UserStore";

export const useFactShipmentButton = (crudContext, entity) => {

    const { Modal, localStorage } = useFormModal({
        fields: {
            dateUnloading: {
                type: 'date',
                isRequired: true
            }
        },
        modalProps: {
            title: 'Вкажіть дату вивантаження'
        },
        method: 'patch',
        restUri: '/core_api/purchase/applications/fact_shipment',
        onAfterSuccessRequest: () => {
            crudContext.actions.reload();
            crudContext.listContext.actions.reload();
        },
        onBeforeSubmit: ({ values }) => {
            values.entity = entity ?? crudContext.formContext.values['@id'];
        }
    });

    const roles = UserStore?.user?.roles ?? [];

    const factShipmentButton = {
        label: 'Підтвердити вивантаження',
        icon: <AiOutlineLike />,
        type: 'primary',
        depFn: ({ crudContext }) => !!crudContext.formContext.values?.id && crudContext.formContext.values?.isUnloadedConfirmed !== true && [ 'ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER' ].some( role => roles.includes(role)),
        onClick: () => localStorage.setIsModalOpen(true)
    };

    return {
        factShipmentButton,
        FactShipmentModal: Modal
    }
}