import { ListBaseFields } from './ListBaseFields';
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListExpenseCategoryResource = {
    title: 'Категорії витрат',
    fields: {
        ...ListBaseFields,
        code: {
            label: 'Код',
            isRequired: true
        }
    },
    restUri: '/core_api/settings/list/expense_categories',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};