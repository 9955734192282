import { App, Button, Dropdown, List, Modal, Tag } from "antd"
import { ConfigProvider } from 'antd';
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { observer } from "mobx-react-lite";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useState } from 'react';
import { Form, FormField } from '@/Core/UI/Form';
import { createFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';
import { useTranslation } from 'react-i18next';
import i18n from '@/Ship/i18n';

const showHelpModal = ( item, application, crudContext, notification ) => {

    crudContext.formContext.setErrors({});
    const restClient = ServiceStore.get('restClient');
    restClient.post(`/core_api/crm/applications/${application.id}/status`, {
        json: { status: item['@id'] }
    })
    .getResponse()
    .then( () => {
        notification.success({
            duration: 10,
            message: i18n.t("Information"),
            className: 'wl-status-bar-detail-notification',
            description: i18n.t("All conditions are met to transfer to the {{title}} status.", { title: item.title }),
            placement: 'bottomRight'
        });
    } )
    .catch( async error => {

        const response = await error.response.json();
        crudContext.formContext.setIsEditable( true );

        if( response && Array.isArray(response.violations) ) {

            let errors = {};
            let otherErrors = [];

            response.violations.map( field => {

                if( field.propertyPath.length > 0 )
                    errors[ field.propertyPath ] = field.message;
                // else
                    otherErrors.push(`- ${field.message}`);
            });
            
            if( otherErrors.length > 0 )
                notification.info({
                    duration: 10,
                    message: i18n.t("To change the status, you need"),
                    className: 'wl-status-bar-detail-notification',
                    description: <List
                        size="small"
                        bordered={true}
                        dataSource={otherErrors}
                        renderItem={(listItem) => <List.Item>{listItem}</List.Item>}
                    />,
                    placement: 'bottomRight'
                });
            
            if( Object.keys(errors).length > 0) {
                crudContext.formContext.setErrors(errors);
            }   
        }
    });
}

export const StatusBarKanban = observer(({ kanbanStore, currentStatus, application }) => {

    const crudContext = useCrudResourceContext();
    const { notification } = App.useApp();
    const [ isShowLossModal, setIsShowLossModal ] = useState( false );
    const [ currentStatusState, setCurrentStatusState ] = useState(currentStatus);
    const restClient = ServiceStore.get('restClient');
    const { t } = useTranslation();

    const items = Object.values(kanbanStore.containersData).map( item => {
        return ({
            key: item['@id'],
            code: item.code,
            label: ( 
                <Button type={item.code === 'application-loss' ? 'primary' : 'dashed'} danger={item.code === 'application-loss' ? true : false} style={{ width: '100%', padding: 0 }}>
                    <span style={{ color: '#010101' }}>{item.title}</span>
                </Button> 
            ),
        })
    });

    const loseFormContext = createFormContext({
        fields: {
            lossReason: {
                label: null,
                type: 'select',
                isRequired: true,
                resource: 'Setting.ListLossReason'
            }
        },
        onSubmit: ({ values }) => {

            restClient.post(`/core_api/crm/applications/${application.id}/status_lose`, {
                json: {
                    lossReason: values.lossReason['@id']
                }
            })
            .getResponse()
            .then( () => {
                notification.success({
                    duration: 5,
                    message: t("Success"),
                    description: t("Application lost successfully."),
                    placement: 'bottomRight'
                });

                setIsShowLossModal(false);

                const loseStatus = kanbanStore.containersData['application-loss'];
                setCurrentStatusState(loseStatus);
        })
        }
    });

    const index = items.findIndex(obj => obj.key === currentStatusState['@id']);
    const filteredStatuses = items.slice(index + 1);

    if( filteredStatuses.length === 0 )
        return <Button type='link' onClick={(e) => e.preventDefault()} style={{ padding: 0 }}>
            <Tag color={currentStatusState.color}>{currentStatusState.title}</Tag>
        </Button>;

    return <>
        <ConfigProvider componentSize="small">
            <Dropdown menu={{ items: filteredStatuses, defaultSelectedKeys: [ currentStatusState['@id'] ], onClick: ( { key } ) => {

                const selectedStatus = Object.values(kanbanStore.containersData).filter( status => status['@id'] === key )[0];
                
                if( selectedStatus.code === 'application-loss' )
                    setIsShowLossModal(true);
                else
                    showHelpModal(selectedStatus, application, crudContext, notification );
            } }} placement="bottomRight" trigger={['click']} >
                <Button type='link' onClick={(e) => e.preventDefault()} style={{ padding: 0 }}>
                    <Tag color={currentStatusState.color}>{currentStatusState.title}</Tag>
                </Button>
            </Dropdown>
            <ConfigProvider componentSize="large">
                <Modal 
                    title={t("Specify the reason for the loss")}
                    open={isShowLossModal} 
                    destroyOnClose={true} 
                    onCancel={() => setIsShowLossModal(false)}
                    onOk={() => loseFormContext.submit()}
                    okText={t("Save")}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <Form formContext={loseFormContext}>
                        <FormField name='lossReason' />
                    </Form>
                </Modal>
            </ConfigProvider>
        </ConfigProvider>
    </>;
});