import { ServiceStore } from '@/Core/Stores/ServiceStore';

export const AutoSearchPurchaseBySaleTask = async id => {

    const restClient = ServiceStore.get('restClient');

    const items = await restClient
                            .get(`/core_api/sale/sales/${id}/purchases`)
                            .getResponse();

    return items;
}