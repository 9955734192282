import { AiOutlineLike } from "react-icons/ai";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { UserStore } from "@/Core/Stores/UserStore";
import { useFormModal } from '@/Ship/Hooks/useFormModal';

export const useQuantityUnloadingButtonHook = ({ crudContext }) => {

    const restClient = ServiceStore.get('restClient');
    const showErrorNotificationTask = useShowErrorNotificationTask();
    const { Modal, localStorage, isLoading } = useFormModal({
        fields: {
            quantity: {
                type: 'number',
                isRequired: true
            }
        },
        modalProps: {
            title: 'Внесення факту вивантаження'
        },
        method: 'patch',
        restUri: '/core_api/sale/sales/quantity_unloading',
        onAfterSuccessRequest: () => {
            crudContext.actions.reload();
            crudContext.listContext.actions.reload();
        },
        onBeforeSubmit: ({ values }) => {
            values.entity = crudContext.formContext.values['@id'];
        }
    });

    const roles = UserStore?.user?.roles ?? [];

    const quantityUnloadingButtonObject = {
        label: 'Підтвердити вивантаження',
        icon: <AiOutlineLike />,
        type: 'primary',
        loading: isLoading,
        onClick: () => {
            localStorage.setIsModalOpen( true );
        },
        depFn: ({ crudContext }) => {
            
            if( !crudContext.formContext.values?.id )
                return false;
            
            if( crudContext.formContext.values.status?.code !== 'implementation' )
                return false;

            if( [ 'ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER' ].some( role => UserStore.user?.roles?.includes( role ) ) )
                return true;
           
            return false;
        }
    };

    return { quantityUnloadingButtonObject, QuantityUnloadingModal: Modal };
}