import {
    observable,
    action,
    computed,
    toJS,
    makeObservable,
    extendObservable
} from "mobx";

import dayjs from "dayjs";
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import i18n from '@/Core/i18n';

export class FieldStore extends UIBaseStore {

    formContext = null;
    label = null;
    type = 'string';
    name = null;
    input = null;
    output = value => value;
    view = null;
    access = null;
    defaultValue = undefined;
    isMultiple = false;
    isRequired = false;
    isDisableTransform = false;
    validators = null;
    inputProps = {};
    valueName = 'value';

    debounceTimeoutId = null;

    isEditable = true;

    path = [];
    fields = {};

    constructor({
        formContext,
        label,
        type,
        name,
        input,
        output,
        view,
        access,
        initialValue,
        defaultValue,
        isMultiple,
        isRequired,
        validators,
        inputProps,
        valueName,
        path,
        fields,
        isEditable,
        ...props
    }) {

        super({ ...props });

        makeObservable(this, {
            valueName: observable,
            isMultiple: observable,
            isEditable: observable,
            // inputProps: observable,
            isValid: computed,
            value: computed,
            initialValue: computed,
            error: computed,
            setIsRequired: action,
            // setInputProps: action,
            setIsEditable: action
        });

        this.eventManager.run('onInit', { context: this });

        this.formContext = formContext;
        this.label = label;

        if( type ) this.type = type;
        if( Array.isArray(path)) this.path = path;
        if( typeof fields === 'object' ) extendObservable(this, fields);

        this.name = name;

        if( isRequired === true )
            this.setIsRequired(true);
        
        if( valueName ) this.valueName = valueName;

        if( initialValue )
            this.formContext.setInitialValue(this.path, initialValue);

        if( defaultValue )
            this.defaultValue = defaultValue;

        if( typeof isEditable === 'boolean' )
            this.setIsEditable( isEditable );

        // TODO: !!!
        if( isMultiple && type === 'select' ) {
            isMultiple = false;
            inputProps ??= {};
            inputProps.mode = 'multiple';
        }

        // TODO: !!!
        if( this.type === 'switch' || this.type === 'checkbox')
            this.valueName = 'checked';

        this.isMultiple = isMultiple === true ? true : false;

        if( input !== undefined ) this.input = input;
        if( output !== undefined ) this.output = output;
        if( view !== undefined ) this.view = view;
        if( access !== undefined ) this.access = access;
        if( validators !== undefined ) this.validators = validators;
        if( inputProps !== undefined ) this.inputProps = inputProps;

        const fieldTypeTransformers = this.formContext.transformes?.fieldType[this.type];

        if( typeof fieldTypeTransformers === 'object' ) {
            Object.keys( fieldTypeTransformers ).map( key => {
                if( typeof fieldTypeTransformers[key] === 'function' ) {
                    fieldTypeTransformers[key](this);
                }
            });
        }
    }

    setDebounceTimeoutId( debounceTimeoutId ) {
        this.debounceTimeoutId = debounceTimeoutId;
    }

    setIsDisableTransform( isDisableTransform ) {
        this.isDisableTransform = isDisableTransform;
    }

    setIsEditable( isEditable ) {
        this.isEditable = isEditable;
    }

    setLabel( label ) {
        this.label = label;
    }

    setIsRequired( isRequired ) {
        this.isRequired = isRequired === true ? true : false;
    }

    setInputProps( inputProps ) {
        if( typeof inputProps === 'object' )
            this.inputProps = inputProps;
    }

    bind(props) {

        props = props ?? {};
        let value = this.value;

        return {
            [this.valueName]: this.isMultiple === true && props?.index !== undefined ? value[props.index] : value,
            onChange: (e) => {

                let value = e?.target ? e.target[this.valueName] : e;

                // TODO: !!!
                if( this.type === 'color' && typeof value === 'object' )
                    value = value.toHexString();
                
                if( this.type === 'mask' )
                    value = e.unmaskedValue;

                if( this.type === 'date' && dayjs.isDayjs(value) ) {
                    value = value.format();
                }

                if( this.type === 'datetime' && dayjs.isDayjs(value) )
                    value = value.format();

                if( this.type === 'daterange' && Array.isArray(value) )
                    value = [value[0].startOf('day'), value[1].startOf('day')];

                let prevValue = this.formContext.getValue( this.path );

                if( this.isMultiple && props.index !== undefined ) {
                    if( !Array.isArray( prevValue ) )
                        value = [ value ];
                    else {
                        prevValue[ props.index ] = value;
                        value = prevValue;
                    }
                }

                this.formContext.setValue( this.path, value );

                if( typeof props?.onChange === 'function' ) {
                    props.onChange( e );
                }

            },
            ...this.inputProps
        };
    }

    get isValid() {
        return !!this.formContext.getError( this.path );
    }

    get value() {
        const value = this.formContext.getValue(this.path);
        return value !== undefined ? this.valueTransformer(value) : this.defaultValue;
    }

    get initialValue() {
        return this.formContext.getInitialValue( this.path );
    }

    get error() {
        return this.formContext.getError( this.path );
    }

    valueTransformer( value ) {
        return this.formContext.isEditable && this.isEditable ? this.valueEditTransformer( value ) : this.valueViewTransformer( value );
    }

    valueViewTransformer( value ) {

        if( this.isDisableTransform )
            return toJS(value);

        if( typeof this.view === 'function' )
            return this.view( toJS(value), this.formContext );
        
        let view = toJS(value);
        // TODO: !!! multiple!
        if( 
            ( this.type === 'date' || this.type === 'datetime' ) 
            && typeof view === 'string' 
            && view.length > 0 
        ) {
            view = dayjs(view).tz("Europe/Kiev");
            view = view.format( this.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm' );
        }

        if( this.type === 'switch' || this.type === 'checkbox' ) {
            view = (view === true ? i18n.t("Yes", { ns: 'Core' }) : i18n.t("No", { ns: 'Core' }));
        }

        if( typeof view === 'object' && !Array.isArray(view) && view !== null ) {
            
            if( view['lastName'] !== undefined )
                view = `${view['lastName']} ${view['name']}`;
            else if( view['title'] !== undefined )
                view = view['title'];
            else if( view['name'] !== undefined )
                view = view['name'];
            else
                view = '-';
        } 
        else if( Array.isArray(view) ) {
            view = view.map( i => {
                let v = i;
                if( typeof i === 'object' ) {
                    if( i['lastName'] !== undefined )
                        v = `${i['lastName']} ${i['name']}`;
                    else if( i['title'] !== undefined )
                        v = i['title'];
                    else if ( i['name'] !== undefined )
                        v = i['name'];
                    else
                        v = '-';
                }
                return v;
            });
        }

        if( !view || ( Array.isArray(view) && view.length === 0))
            view = '-';
        
        return view;
    }

    valueEditTransformer( value ) {
        
        value = toJS(value);

        if( this.isDisableTransform )
            return value;

        if( typeof this.input === 'function' )
            return this.input( value );

        if(
            Array.isArray(value)
            && value.length === 2
            && dayjs.isDayjs(value[0])
            && dayjs.isDayjs(value[1])
        )
            return value;

        if( typeof value === 'object' && !Array.isArray(value) && value !== null && value['@id'] !== undefined ) {
            value = value['@id'];
        } else if( Array.isArray(value) ) {
            value = value.map( i => {      
                let vi = i;
                if( typeof i === 'object' ) {
                    vi = i['@id'];
                }
                return vi;
            });
        }
        
        if( (this.type === 'datetime' || this.type === 'date') && typeof value === 'string' && value.length > 0 ) {
            value = dayjs(value).tz("Europe/Kiev");
        }

        return value;
    }

    setView( view ) {
        if( typeof view === 'function' )
            this.view = view;
    }

    mergeInputProps( inputProps ) {
        if( typeof inputProps === 'object' ) {
            this.inputProps = { ...this.inputProps, ...inputProps };
        }
    }
}