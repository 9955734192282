import i18n from '@/Ship/i18n';

export const PurchaseDocumentResource = {
    title: 'Документи закупівель',
    fields: {
        title: {
            label: 'Назва'
        },
        number: {
            label: 'Номер'
        },
        date: {
            label: 'Дата',
            type: 'date'
        },
        entity: {
            type: 'select',
            resource: 'CRM.Carrier'
        },
        type: {
            label: 'Тип документу',
            type: 'select',
            resource: 'Setting.ListDocumentType',
            isRequired: true,
            // inputProps: {
            //     filter: {
            //         isUseInCarrier: true
            //     }
            // }
        },
        wordFile: {
            label: 'Word файл',
            type: 'file',
            uploadUrl: '/core_api/purchase/purchases/document/word_file',
            uploadUrlReplacer: ({ uploadUrl, formContext }) => uploadUrl.replace('{id}', formContext.values.id ),
            officeViewUrl: '/core_api/purchase/purchases/document/files'
        },
        pdfFile: {
            label: 'Pdf файл',
            type: 'file',
            depFn: ({ formContext }) => !!formContext.values.id,
            uploadUrl: '/core_api/purchase/purchases/document/pdf_file',
            uploadUrlReplacer: ({ uploadUrl, formContext }) => uploadUrl.replace('{id}', formContext.values.id ),
            officeViewUrl: '/core_api/purchase/purchases/document/files',
        }
    },
    restUri: {
        getCollection: '/core_api/purchase/purchases/{entity_id}/documents',
        get: '/core_api/purchase/purchases/documents/{id}',
        post: '/core_api/purchase/purchases/documents',
        patch: '/core_api/purchase/purchases/documents/{id}',
        delete: '/core_api/purchase/purchases/documents/{id}'
    }
}