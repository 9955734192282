import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Space, Tooltip } from 'antd';
import { IoMdMore } from "react-icons/io";
import { makeAutoObservable, runInAction, toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { createRoot } from 'react-dom/client';
import { ButtonGroupStore } from "./ButtonGroupStore";
import { useDependency } from '../Dependency/useDependency';

import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { UserStore } from '@/Core/Stores/UserStore';

import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { useFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';
import { useHeaderPageContext } from '@/Core/UI/HeaderPage/HeaderPageContextProvider';
import { useListContext } from '@/Core/UI/List/ListContextProvider';
import { useDrawerContext } from "@/Core/UI/Drawer/DrawerContextProvider";

import './ButtonGroup.css';

function filterObject(obj, keysArray) {

    let filteredObj = {};
  
    for (let key in obj) {
        if (keysArray.includes(key)) {
            filteredObj[key] = obj[key];
        }
    }
  
    return filteredObj;
}

/**
    const buttons = {
        Home: { label: 'Home', icon: <RiTempHotLine />, sort: 1, priority: 'button' },
        Settings: { label: 'Settings', icon: <RiTempHotLine />, sort: 3, priority: 'dropdown' },
        Custom: { children: <MyComponent /> }
    };
*/
export const ButtonGroup = ({ 
    buttons, 
    content = [],
    dropdownButtonWidth = 40,
    dropdownButtonIcon,
    mobileWidth = 670,
    recalculationThreshold = 50
}) => {

    if( !dropdownButtonIcon )
        dropdownButtonIcon = <IoMdMore style={{ fontSize: 22 }} />;

    const buttonGroupStore = new ButtonGroupStore();
    const containerRef = useRef(null);
    const [isMobile, setIsMobile] = useState( window.innerWidth <= mobileWidth );
    const savedInnerWidth = window.innerWidth;
    const formContext = useFormContext();
    const crudContext = useCrudResourceContext();
    const listContext = useListContext();
    const drawerContext = useDrawerContext();
    const headerPageContext = useHeaderPageContext();
    const config = ConfigStore.getAll();

    const [lastCalculatedWidth, setLastCalculatedWidth] = useState(window.innerWidth);

    const shouldRecalculate = (currentWidth) => {
        return Math.abs(currentWidth - lastCalculatedWidth) >= recalculationThreshold;
    };

    let buttonsFilteredKeysMemory = [];

    function arraysAreEqual(arr1, arr2) {
        if (arr1.length !== arr2.length) {
          return false;
        }
      
        return arr1.every((value, index) => value === arr2[index]);
    }

    const ButtonGroupObserver = observer(() => {
                
        const buttonsFilteredKeys = Object.keys(toJS(buttons)).filter( key => {
            const button = buttons[key];
            
            if( typeof button?.depFn !== 'function' && typeof button?.accessFn !== 'function') return true;
            
            let result = true;

            if(
                typeof button.depFn === 'function' && button.depFn({
                    formContext,
                    crudContext,
                    listContext,
                    drawerContext,
                    headerPageContext,
                    config,
                    UserStore,
                    ...( button?.depStore ?? {} )
                }) === false
            )
                result = false;

            if(
                typeof button.accessFn === 'function' && button.accessFn({
                    formContext,
                    crudContext,
                    listContext,
                    drawerContext,
                    headerPageContext,
                    config,
                    UserStore,
                    ...( button?.depStore ?? {} )
                }) === false
            )
                result = false;
            return result;
        });

        const buttonsFiltered = filterObject(buttons, buttonsFilteredKeys);

        useEffect(() => {
            if( !arraysAreEqual(buttonsFilteredKeys, buttonsFilteredKeysMemory) ) {
                buttonsFilteredKeysMemory = [...buttonsFilteredKeys];
                buttonGroupStore.setOriginalButtons(buttonsFiltered);
            }
        }, [buttonsFilteredKeys]);
    
        const calculateVisibleButtons = () => {
    
            if (!containerRef?.current) 
                return;
            
            const currentWidth = window.innerWidth;
            if (!shouldRecalculate(currentWidth)) return;

            const primaryButtons = buttonGroupStore.originalButtons.filter(btn => btn.priority === 'button'); 
            const dropdownButtons = buttonGroupStore.originalButtons.filter(btn => btn.priority === 'dropdown');   
    
            const containerWidth = containerRef.current.offsetWidth + 5;
            let availableWidth = containerWidth;
            let visibleCount = 0;
            
            if( primaryButtons.length > 1 || dropdownButtons.length > 0 )
                availableWidth = containerWidth - dropdownButtonWidth;
    
            const tempContainer = document.createElement('div');
            tempContainer.style.visibility = 'hidden';
            tempContainer.style.position = 'absolute';
            tempContainer.style.display = 'flex';
            document.body.appendChild(tempContainer);
    
            for (const btn of primaryButtons) {
                const tempButton = document.createElement('button');
                tempButton.innerText = !isMobile ? btn.label : '';
                tempButton.style.whiteSpace = 'nowrap';
                tempContainer.appendChild(tempButton);
                const buttonWidth = tempButton.offsetWidth + (!isMobile ? 45 : 30 );
                
                if (availableWidth - buttonWidth >= 0) {
                    availableWidth -= buttonWidth;
                    visibleCount++;
                } else {
                    break;
                }
            }
    
            if( primaryButtons.length > 0 && visibleCount === 0 )
                visibleCount = 1;
    
            document.body.removeChild(tempContainer);
            buttonGroupStore.adjustButtons(visibleCount);

            setLastCalculatedWidth(currentWidth);
        };
    
        useEffect(() => {
    
            const resizeObserver = new ResizeObserver(() => {
                if (shouldRecalculate(window.innerWidth)) {
                    setTimeout(calculateVisibleButtons, 0);
                }
            });
            
            if (containerRef.current) {
                resizeObserver.observe(containerRef.current);
            }
    
            const handleResize = () => {
                const currentWidth = window.innerWidth;
                if (shouldRecalculate(currentWidth)) {
                    setIsMobile(currentWidth <= mobileWidth);
                    setTimeout(calculateVisibleButtons, 0);
                }
            };
    
            window.addEventListener('resize', handleResize);
    
            return () => {
                resizeObserver.disconnect();
                window.removeEventListener('resize', handleResize);
            };
        }, []);
    
        useEffect(() => {
            setTimeout(calculateVisibleButtons, 0);
        }, [isMobile]);
    
        const renderButton = ({ children, dropdown, dropdownButton, key, ...btn }) => {
            
            if( children )
                return children;
            
            let buttonRender = <Button key={key ?? Math.random()} className="button-group-item" size='middle'
                                    {...btn}
                                >
                                    {btn.label}
                                </Button>

            if( isMobile )
                buttonRender = <Tooltip placement="bottom" title={btn.label} key={key}>
                    <Button
                        className="button-group-item"
                        size='middle'
                        {...btn}
                    />
                </Tooltip>
            
            if( typeof dropdown === 'object' )
                return <Dropdown trigger={['click']} {...dropdown}>
                    {buttonRender}
                </Dropdown>

            if( typeof dropdownButton === 'object' && isMobile) {
                return <Tooltip placement="bottom" title={btn.label} key={btn.key}>
                    <Dropdown.Button className="button-group-item" size='middle' {...btn} {...dropdownButton} />
                </Tooltip>
            }

            if( typeof dropdownButton === 'object' && !isMobile) {
                return <Dropdown.Button className="button-group-item" size='middle' {...btn} {...dropdownButton}>
                    {btn.label}
                </Dropdown.Button>
            }

            return buttonRender;
        };
    
        const dropdownItems = toJS(buttonGroupStore.dropdownButtons);
     
        return <>
            <div ref={containerRef} style={{ width: '100%', display: 'flex', justifyContent: 'end' }}>
                <Space>
                    {toJS(buttonGroupStore.buttons).map(renderButton)}
                    {dropdownItems.length > 0 && (
                        <Dropdown menu={{ items: dropdownItems }} placement="bottomRight" key='group-button-dropdown'>
                            <Button icon={dropdownButtonIcon} className="button-group-item" size='middle' />
                        </Dropdown>
                    )}
                </Space>
            </div>
            {content}
        </>;
    });

    return <ButtonGroupObserver />;
};