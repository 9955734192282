import React, { forwardRef } from 'react';
import classNames from 'classnames';

import styles from './Container.module.css';
import { OverlayScrollbars } from "@/Core/UI/OverlayScrollbars";

export const Container = forwardRef(
  (
    {
      children,
      columns = 1,
      horizontal,
      hover,
      onClick,
      label,
      placeholder,
      style,
      shadow,
      unstyled,
      ...props
    },
    ref
  ) => {
    const Component = onClick ? 'button' : 'div';
    return (
      <Component
        {...props}
        ref={ref}
        style={
          {
            ...style,
            '--columns': columns,
          }
        }
        className={classNames(
          styles.Container,
          unstyled && styles.unstyled,
          horizontal && styles.horizontal,
          hover && styles.hover,
          placeholder && styles.placeholder,
          shadow && styles.shadow
        )}
        onClick={onClick}
        tabIndex={onClick ? 0 : undefined}
      >
        {label ? (
          <div className={styles.Header}>
            {label}
          </div>
        ) : null}
        {placeholder ? children : <OverlayScrollbars style={{maxHeight: 'calc(100vh - 300px)'}} events={{
          scroll: ( instance ) => {
            
            if (
              instance &&
              (
                (
                  instance.elements().viewport.clientHeight 
                  + instance.elements().viewport.scrollTop 
                )
                > ( instance.elements().viewport.scrollHeight - 20 )
              )
            ) {
              // Send the request only once
              console.log('Additional content is uploaded');
            }
            
          }
          
        }}>
          <ul style={{
            padding: 0
          }}>
            {children}
          </ul>
        </OverlayScrollbars>}
      </Component>
    );
  }
);
