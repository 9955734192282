import {
    observable,
    action,
    makeObservable,
} from "mobx";

import { UserStore } from "@/Core/Stores/UserStore";
import { EventManager } from '@/Core/Services/Managers/EventManager';
import { AccessManager } from '@/Core/Services/Managers/AccessManager';

export class UIBaseStore {

    states = {};
    storages = {};
    settings = {};

    template = null;
    templateWrapper = null;
    templateError403 = null;
    depFn = null;
    templateWrapperProps = {};
    props = {};

    eventManager = new EventManager({ context: this });
    accessManager = new AccessManager({ context: this });

    constructor( props ) {
        
        makeObservable(this, {
            states: observable,
            setStates: action,
            setState: action
        });

        if( typeof props !== 'object' )
            props = {};

        props.states ??= {};
        this.mergeToInstance( props );
    }

    setStates( states ) {
        if( typeof states === 'object' ) 
            this.states = states;    
    }

    setState( path, value ) {

        if( path.indexOf('.') === -1 ) {
            this.states[path] = value;
        } else {

            const paths = path.split('.');
            let currentState = this.states;
    
            for (let i = 0; i < paths.length - 1; i++) {
                const currentKey = paths[i];
                if (!currentState[currentKey]) {
                    currentState[currentKey] = {};
                }
                currentState = currentState[currentKey];
            }
    
            currentState[paths[paths.length - 1]] = value;
        }
    }

    setStorages( storages ) {
        if( typeof storages === 'object' ) {
            this.storages = storages;
        }
    }

    setStorage( name, value ) {
        this.storages[name] = value;
    }

    addEventsHandlers( eventsHandlers ) {
		if( typeof eventsHandlers === 'object' ) {
            Object.keys(eventsHandlers).map( eventName => {
                this.addEventHandlers( eventName, eventsHandlers[eventName] );
            });
        }
	}

    addEventHandlers( eventName, eventHandlers = {} ) {
        Object.keys( eventHandlers ).map( callbackName => {
            const callbackFn = eventHandlers[callbackName];

            if( typeof callbackFn !== 'function' )
                return;

            this.eventManager.on( eventName, callbackName, callbackFn );
        });
    }

    mergeStates( states ) {
        if( typeof states === 'object' ) 
            this.states = { ...this.states, ...states };
    }

    mergeStorages( storages ) {
        if( typeof storages === 'object' )
            this.storages = { ...this.storages, ...storages };
    }

    mergeSettings( settings ) {
        if( typeof settings === 'object' )
            this.settings = { ...this.settings, ...settings };
    }

    mergeProps( props ) {
        if( typeof props === 'object' )
            this.props = { ...this.props, ...props };
    }

    mergeToInstance({ 
        states = {}, 
        storages = {}, 
        settings = {}, 
        eventHandlers = {},
        accessFn = null, 
        template,
        templateError403,
        templateWrapper,
        templateWrapperProps,
        depFn,
        ...props
    }) {
        
        this.mergeStates(states);
        this.mergeStorages(storages);
        this.mergeSettings(settings);
        this.addEventsHandlers(eventHandlers);

        this.accessManager.setAccessFn( accessFn );

        if( template ) 
            this.template = template;

        if( templateWrapper ) 
            this.templateWrapper = templateWrapper;

        if( templateError403 )
            this.templateError403 = templateError403;

        if( typeof depFn === 'function' ) 
            this.depFn = depFn;
        
        this.mergeProps( props );
    }

    render() {
        
        if( typeof this.depFn === 'function' && this.depFn({ context: this }) === false )
            return;

        if( !this.template )
            return;

        const Component = this.template;

        return <Component {...this.props} />;
    }
}