import { useDefaultLayoutContext } from '../DefaultLayoutContextProvider';

export const MobileBarContent = () => {
    
    const defaultLayoutContext = useDefaultLayoutContext();

	if( defaultLayoutContext.mobileBarContent !== undefined )
		return defaultLayoutContext.mobileBarContent;
	
	return null;
}