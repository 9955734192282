import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListCurrencyResource = {
    title: 'Валюти',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        code: {
            label: 'Код',
            isRequired: true
        },
        isBasic: {
            label: 'Базова',
            type: 'switch'
        },
        formatting: {
            label: 'Рядок формату для виведення валюти'
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        }
    },
    restUri: '/core_api/settings/currencies',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};