import { Divider } from 'antd';

import { UserInfo } from "@/Core/Components/Layout/FirstBar/UserInfo";
import { Menu } from "@/Core/Components/Layout/FirstBar/Menu";

import { useDefaultLayoutContext } from '../DefaultLayoutContextProvider';

export const FirstBarContent = () => {
	
	const defaultLayoutContext = useDefaultLayoutContext();

	if( defaultLayoutContext.firstBarContent !== undefined )
		return defaultLayoutContext.firstBarContent;

	return <>
		<UserInfo key='firstbar-userinformation' />
		<Divider key='firstbar-divider-1' />
		<div style={{ height: 'calc(100% - 110px)'}}>
			<Menu key='firstbar-menu' />
		</div>
	</>;
};