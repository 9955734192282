import { ResourceStore } from "@/Core/Stores/ResourceStore";
import { GetRestUriTask } from './GetRestUriTask';

export const GetResourceCodeByDetailUri = ( uri ) => {

    const resources = ResourceStore.getAll();
    let resourceCode = null;
    uri = uri.replace(/\/\d+$/, '');

    Object.keys( resources ).map( key => {

        const resource = resources[ key ];
        const resourceUri = GetRestUriTask(resource.restUri);

        if( resourceUri && resourceUri == uri )
            resourceCode = key;
    });

    return resourceCode;
}