import { AddActionsMenuFeature } from "@/Core/Features/UI/Table/AddActionsMenuFeature";
import { TransformItemDataByDefaultFeature } from "@/Core/Features/UI/Table/TransformItemDataByDefaultFeature";
import { AddDefaultRowKeyFeature } from "@/Core/Features/UI/Table/AddDefaultRowKeyFeature";

export const TableConfig = {
    features: {
        TransformItemDataByDefaultFeature,
        AddActionsMenuFeature,
        AddDefaultRowKeyFeature
    },
    pagination: {
        defaultPageSize: 20,
        hideOnSinglePage: true
    }
};