import { makePersistable } from 'mobx-persist-store';
import { makeAutoObservable, toJS } from 'mobx';
import { enc, AES } from 'crypto-js';

const secretKey = 'nceNRcSTIongLabELiCUlIChbUmISEpiErAi';

function encrypt(data) {
  return AES.encrypt(JSON.stringify(data), secretKey).toString();
}

function decrypt(data) {
  const bytes = AES.decrypt(data, secretKey);
  const string = bytes.toString(enc.Utf8);
  
  if( !string )
    return {};

  return JSON.parse(string);
}

// TODO: rewrite store. add fron ship
const state = {
  isAuthorized: false,
  user: {},
  token: null,
  mercureToken: null,
  refreshToken: null,
  refreshTokenExpiration: null,
  setToken(token) {
    state.token = token;
  },
  setMercureToken(mercureToken) {
    state.mercureToken = mercureToken;
  },
  setRefreshToken(refreshToken) {
    state.refreshToken = refreshToken;
  },
  setRefreshTokenExpiration(refreshTokenExpiration) {
    state.refreshTokenExpiration = refreshTokenExpiration;
  },
  setIsAuthorized(isAuthorized) {
    state.isAuthorized = isAuthorized;
  },
  setUser(user) {
    state.user = user;
  },
  clear() {
    state.isAuthorized = false;
    state.user = {};
    state.token = null;
    state.mercureToken = null;
    state.refreshToken = null;
    state.refreshTokenExpiration = null;
  },
};

export const UserStore = makeAutoObservable(state);

makePersistable(UserStore, {
  storage: window.localStorage,
  name: 'UserStore',
  properties: [
    'isAuthorized',
    'token',
    'mercureToken',
    {
      key: 'refreshToken',
      serialize: (value) => encrypt(value),
      deserialize: (value) => decrypt(value)
    },
    {
      key: 'refreshTokenExpiration',
      serialize: (value) => encrypt(value),
      deserialize: (value) => decrypt(value)
    },
    {
      key: 'user',
      serialize: (value) => encrypt(toJS(value)),
      deserialize: (value) => decrypt(value)
    }
  ]
});