import { Col, Row, Skeleton } from "antd";
import { observer } from "mobx-react-lite";
import { useCrudResourceContext } from "../CrudResourceContextProvider";
import { FormTemplate } from "./FormTemplate";

let loadingTemplate = <Row gutter={[24, 24]}>
    <Col span={12}>
        <Skeleton active={true} />
        <Skeleton active={true} />
        <Skeleton active={true} />
        <Skeleton active={true} />
    </Col>
    <Col span={12}>
        <Skeleton active={true} />
        <Skeleton active={true} />
        <Skeleton active={true} />
        <Skeleton active={true} />
    </Col>
</Row>;

export const Form = observer(() => {

    const crudContext = useCrudResourceContext();

    if( crudContext.formContext.isLoading )
        return loadingTemplate;

    return <FormTemplate />;
});