import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';

export const OverlayScrollbars = ( props ) => {

    let overlayScrollbarsProps = {...props};

    if( overlayScrollbarsProps?.defer !== false )
        overlayScrollbarsProps.defer = true;

    if( typeof overlayScrollbarsProps?.options !== 'object' )
        overlayScrollbarsProps.options={
            scrollbars: {
                visibility: 'auto',
                autoHide: 'leave',
                autoHideDelay: 300
            }
        }

    return <OverlayScrollbarsComponent {...overlayScrollbarsProps} />;
}