import { Form, FormField } from "@/Core/UI/Form";
import { Tabs } from "@/Core/UI/Tabs";
import { PurchaseCrud } from '../../PurchaseContainer/Components/PurchaseCrud';
import { YouControlField } from '@/Ship/Components/Fields/YouControlField';
import { Dependency } from "@/Core/UI/Dependency";

export const SupplierFormTemplate = ({ formContext }) => {

    const tabItems = [
        {
            label: 'Загальне',
            key: 'general',
            children: <Form formContext={formContext}>
                <FormField name='title' />
                <FormField name='fullName' />
                <FormField name='isNonResident' />
                <Dependency depFn={({ formContext }) => !formContext.values.isNonResident }>
                    <YouControlField name='edrpou' />
                    <YouControlField name='ipn' depFn={({ field, formContext }) => {
                        if( !formContext.values.isNonResident )
                            field.setIsRequired( true );
                        else
                            field.setIsRequired( false );
                    }} />
                </Dependency>
                <Dependency depFn={({ formContext }) => formContext.values.isNonResident === true }>
                    <FormField name='vat' />
                    <FormField name='country' />
                </Dependency>
                <FormField name='email' />
                <FormField name='phones' />
                <FormField name='otherContact' />
                <FormField name='director' />
                <FormField name='onBasis' />
                <FormField name='address' />
                <FormField name='iban' />
                <FormField name='bank' />
                <FormField name='comment' />
            </Form>
        },
        {
            label: 'Закупівлі',
            key: 'purchase',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <PurchaseCrud 
                form={{
                    initialValues: {
                        supplier: formContext.values
                    }
                }}
                prefilter={{
                    supplier: formContext.values['@id']
                }}
                isFilter={false}
                modeItems={{}}
            />
        }
    ];

    return <Tabs items={tabItems} />
}