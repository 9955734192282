import React from 'react';
import { Row, Col } from 'antd';

export const FormRow = ({ children }) => {

  const totalColCount = React.Children.count(children);
  let customColCount = 0;
  let remainingSpan = 24;

  const modifiedChildren = React.Children.map(children, (child) => {
    
    if (child.type === FormRow.Col) {
      customColCount += 1;
      const span = child.props.span || Math.floor(remainingSpan / (totalColCount - customColCount + 1));
      remainingSpan -= span;

      const { span: colSpan, xs, sm, md, lg, xl, ...rest } = child.props;
      const colProps = { span, ...rest };

      if (xs) colProps.xs = xs;
      if (sm) colProps.sm = sm;
      if (md) colProps.md = md;
      if (lg) colProps.lg = lg;
      if (xl) colProps.xl = xl;

      if( !colProps?.xs )
        colProps.xs = 24;
      if( !colProps?.sm )
        colProps.sm = 24;
      if( !colProps?.md )
        colProps.md = colSpan ?? span;

      return <Col {...colProps}>{child.props.children}</Col>;
    } else {
      return child;
    }
  });

  return <Row gutter={[24, 24]}>{modifiedChildren}</Row>;
};

FormRow.Col = ({ children, span, ...props }) => <Col span={span} {...props}>{children}</Col>;
