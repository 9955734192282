import {Item} from './Item';
import { useSortable } from '@dnd-kit/sortable';
import { useEffect, useState } from 'react';
import { KanbanStore } from '../Stores/KanbanStore';

export function SortableItem({
    disabled,
    id,
    index,
    handle,
    containerId,
    getIndex,
    renderItem
  }) {
    const {
      setNodeRef,
      setActivatorNodeRef,
      listeners,
      isDragging,
      isSorting,
      over,
      overIndex,
      transform,
      transition,
    } = useSortable({ id });
    const mounted = useMountStatus();
    const mountedWhileDragging = isDragging && !mounted;
  
    return (
      <Item
        ref={disabled ? undefined : setNodeRef}
        value={renderItem({ KanbanStore, containerId, index })}
        dragging={isDragging}
        sorting={isSorting}
        handle={handle}
        handleProps={handle ? {ref: setActivatorNodeRef} : undefined}
        index={index}
        wrapperStyle={{index}}
        style={{
          index,
          value: id,
          isDragging,
          isSorting,
          overIndex: over ? getIndex(over.id) : overIndex,
          containerId,
        }}
        color={KanbanStore.containersData[containerId]?.color}
        transition={transition}
        transform={transform}
        fadeIn={mountedWhileDragging}
        listeners={listeners}
      />
    );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);

    return () => clearTimeout(timeout);
  }, []);

  return isMounted;
}