// import { GetCollectionFromHydraItems } from "@/Core/Services/Transformers/UI/List/GetCollectionFromHydraItems";
// import { GetTotalFromHydraItems } from "@/Core/Services/Transformers/UI/List/GetTotalFromHydraItems";

export const ListConfig = {
    features: {
        items: {
            // GetCollectionFromHydraItems,
            // GetTotalFromHydraItems
        },
    },
    pagination: { current: 1, pageSize: 20 },
    sorter: { key: 'id', order: 'desc' }
}