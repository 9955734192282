export class EventManager {

    debug = false;

    context = null;

    constructor({ context = null }) {
        this.listeners = {};
        this.activeEvents = new Set();
        this.context = context;
    }

    on( eventName, callbackName, callbackFn, options = { once: false } ) {

        if( typeof eventName !== 'string' )
            return;

        if( typeof callbackName !== 'string' )
            return;

        if( typeof callbackFn !== 'function' )
            return;

        if ( !this.listeners[eventName] ) {
            this.listeners[eventName] = {};
        }

        this.listeners[eventName][callbackName] = {
            callbackFn,
            options
        };

        this.log(`Listener added for event: ${eventName}`);
    }

    off( eventName, callbackName = null ) {

        if( typeof eventName !== 'string' ) {
            return;
        }

        if (!this.listeners[eventName]) {
            this.log(`No listener found for event: ${event}`);
            return;
        }

        if( !callbackName ) {
            this.listeners[eventName] = undefined;
            return;
        }

        if( typeof callbackName !== 'string' ) {
            return;
        }

        this.listeners[eventName][callbackName] = undefined;
    }

    run( eventName, ...args ) {

        if (this.activeEvents.has( eventName )) {
            this.warn(`Attempted to trigger a recursive call for event: ${eventName}`);
            return;
        }

        this.activeEvents.add( eventName );
        this.log(`Running event: ${eventName}`);

        const handlers = this.listeners[eventName];

        args ??= [];
        // args.context = this.context;
        args.push(this.context);
        if ( typeof handlers === 'object' ) {
            Object.keys(handlers).forEach( listenerName => {

                const listener = handlers[listenerName];

                if( this.context ) {
                    listener.callbackFn.apply( this.context, args );
                }
                else
                    listener.callbackFn( ...args );

                if ( listener.options?.once === true ) {
                    this.off( eventName, listenerName );
                }
            });
        }

        this.activeEvents.delete( eventName );
    }

    async runAsync( eventName, ...args ) {

        if (this.activeEvents.has( eventName )) {
            this.warn(`Attempted to trigger a recursive call for event: ${eventName}`);
            return;
        }

        this.activeEvents.add( eventName );
        this.log(`Running event: ${eventName}`);

        const handlers = this.listeners[eventName];

        args ??= [];
        // args.context = this.context;
        args.push(this.context);
        let eventResult = [];
        if ( typeof handlers === 'object' ) {

            const keys = Object.keys(handlers);

            for( let i = 0; i < keys.length; i++ ) {

                const listenerName = keys[i];
                const listener = handlers[listenerName];
                let result = null;
                if( this.context ) {
                    result = await listener.callbackFn.apply( this.context, args );
                }
                else
                    result = await listener.callbackFn( ...args );

                if ( listener.options?.once === true ) {
                    this.off( eventName, listenerName );
                }

                eventResult.push( result );
            }
        }

        this.activeEvents.delete( eventName );

        return eventResult;
    }

    hasHandlers( eventName ) {
        return this.listeners[eventName] !== undefined && Object.keys(this.listeners[eventName]).length > 0 ? true : false;
    }

    log( message ) {
        if( this.debug === true )
            console.log( message );
    }

    warn( message ) {
        if( this.debug === true )
            console.warn( message );
    }
}