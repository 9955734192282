import { makeAutoObservable } from 'mobx';

export const ImportTransportStore = makeAutoObservable({
    isDrawerOpen: false,
    isSendRequest: false,
    setIsDrawerOpen( isDrawerOpen ) {
        this.isDrawerOpen = isDrawerOpen;
    },
    setIsSendRequest( isSendRequest ) {
        this.isSendRequest = isSendRequest;
    }
});