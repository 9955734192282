import React, {useEffect} from 'react';
import classNames from 'classnames';

import { RiDragMoveFill } from "react-icons/ri";
import styles from './Item.module.css';
import { Action } from './Action';

export const Item = React.memo(
  React.forwardRef(
    (
      {
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        handleProps,
        index,
        listeners,
        onRemove,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      useEffect(() => {

        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return <li
        className={classNames(
          styles.Wrapper,
          fadeIn && styles.fadeIn,
          sorting && styles.sorting,
          dragOverlay && styles.dragOverlay
        )}
        style={
          {
            ...wrapperStyle,
            transition: [transition, wrapperStyle?.transition]
              .filter(Boolean)
              .join(', '),
            '--translate-x': transform
              ? `${Math.round(transform.x)}px`
              : undefined,
            '--translate-y': transform
              ? `${Math.round(transform.y)}px`
              : undefined,
            '--scale-x': transform?.scaleX
              ? `${transform.scaleX}`
              : undefined,
            '--scale-y': transform?.scaleY
              ? `${transform.scaleY}`
              : undefined,
            '--index': index,
            '--color': color,
            border: '1px solid #dfdfdf',
            // borderLeft: `5px solid ${color}`,
            borderRadius: '8px',
            margin: '15px 10px 0',
            boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
            background: '#fff'
          }
        }
        ref={ref}
      >
        <div
          className={classNames(
            styles.Item,
            dragging && styles.dragging,
            handle && styles.withHandle,
            dragOverlay && styles.dragOverlay,
            disabled && styles.disabled,
            color && styles.color
          )}
          style={style}
          data-cypress="draggable-item"
          // {...(!handle ? listeners : undefined)}
          {...props}
          tabIndex={!handle ? 0 : undefined}
        >
          {value}
          <span className={styles.Actions}>
            {onRemove ? (
              <button
                tabIndex={0}
                onClick={onRemove}
                className={styles.Remove}
                active={{
                  fill: 'rgba(255, 70, 70, 0.95)',
                  background: 'rgba(255, 70, 70, 0.1)',
                }}
                style={
                  {
                    backgroundColor: 'transparent',
                    borderRadius: '5px',
                    border: '1px solid #dfdfdf',
                    outline: 'none',
                    appearance: 'none',
                    cursor: 'pointer'
                  }
                }
              >
                -
              </button>
            ) : null}
            {handle ? <button
              cursor="grab"
              data-cypress="draggable-handle"
              {...handleProps} {...listeners}
              tabIndex={0}
              style={
                {
                  backgroundColor: 'transparent',
                  borderRadius: '5px',
                  border: '1px solid #dfdfdf',
                  outline: 'none',
                  appearance: 'none',
                  cursor: 'pointer'
                }
              }
            >
              <RiDragMoveFill style={{ fontSize: 22 }} />
            </button>
            : null}
          </span>
        </div>
      </li>;
    }
  )
);
