export const ApplicationResource = {
    title: 'Заявки на транспорт',
    fields: {
        sale: {
            label: 'Контракт',
            isRequired: true,
            type: 'select',
            resource: 'Sale.Sale'
        },
        client: {
            label: 'Постачальник',
            type: 'select',
            resource: 'Sale.Client',
            inputProps: {
                disabled: true
            }
        },
        date: {
            label: 'Дата',
            isRequired: true,
            type: 'date'
        },
        responsible: {
            label: 'Відповідальний',
            type: 'select',
            resource: 'Company.User'
        },
        product: {
            label: 'Номенклатура',
            type: 'select',
            resource: 'Company.Product',
            inputProps: {
                disabled: true
            }
        },
        loadedQuantity: {
            label: 'Завантажена кількість',
            type: 'number'
        },
        price: {
            label: 'Ціна продажу (т)',
            type: 'number',
            inputProps: {
                disabled: true
            }
        },
        priceCurrency: {
            label: 'Валюта ціни продажу (т)',
            type: 'select',
            resource: 'Setting.ListCurrency',
            inputProps: {
                disabled: true
            }
        },
        warehouse: {
            label: 'Склад',
            isRequired: true,
            type: 'select',
            resource: 'Company.Warehouse'
        },
        gdnNumber: {
            label: 'Номер ЖДН',
        },
        wagons: {
            label: 'Номери вагонів',
        },
        transportPrice: {
            label: 'Вартість транспорту (т)',
            type: 'number'
        },
        transportPriceCurrency: {
            label: 'Валюта вартості транспорту (т)',
            type: 'select',
            resource: 'Setting.ListCurrency'
        },
        transportPriceUAH: {
            label: 'Вартість транспорту в (т) грн',
            type: 'number'
        },
        unloadedQuantity: {
            label: 'Вивантажена кількість (транспортний тариф)',
            type: 'number'
        },
        actualUnloadedQuantity: {
            label: 'Вивантажена кількість (факт)',
            type: 'number'
        },
        isUnloaded: {
            label: 'Відвантажено',
            type: 'switch'
        },
        isDelivered: {
            label: 'Доставлено',
            type: 'switch'
        },
    },
    restUri: '/core_api/sale/applications',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;
        
        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: !['ROLE_BUYER'].some(value => UserStore.user?.roles.includes(value)) ? true : false,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};