import { createContext, useContext } from 'react';
import { HeaderPageStore } from './Stores/HeaderPageStore';

export const HeaderPageContext = createContext();

export const createHeaderPageContext = (props = {}) => new HeaderPageStore(props);

export const useHeaderPageContext = () => useContext( HeaderPageContext );

export const HeaderPageContextProvider = ({ value, children }) => <HeaderPageContext.Provider value={value}>
    {children}
</HeaderPageContext.Provider>