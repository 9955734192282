import { makeAutoObservable } from "mobx";

export const NotificationStore = makeAutoObservable({
    isDrawerOpen: false,
    isFilterOpen: false,
    isNotificationLoading: false,
    notifications: [],
    totalCount: 0,
    setIsDrawerOpen( isDrawerOpen ) {
        this.isDrawerOpen = isDrawerOpen;
    },
    setIsFilterOpen( isFilterOpen ) {
        this.isFilterOpen = isFilterOpen;
    },
    setIsNotificationLoading( isNotificationLoading ) {
        this.isNotificationLoading = isNotificationLoading;
    },
    setNotifications( notifications ) {
        if( Array.isArray(notifications))
            this.notifications = notifications;
    },
    setTotalCount( totalCount ) {
        this.totalCount = totalCount;
    }
});