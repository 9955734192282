import { ServiceStore } from '@/Core/Stores/ServiceStore';

export const LoadSaleItemTask = async (uri) => {

    const restClient = ServiceStore.get('restClient');

    const response = await restClient
        .get(`/core_api/sale/items?entity=${uri}`)
        .getResponse();

    return response && response['hydra:member'] ? response['hydra:member'] : [];
}