import { useDefaultLayoutContext } from '../DefaultLayoutContextProvider';

export const HeaderContent = () => {

    const defaultLayoutContext = useDefaultLayoutContext();

	if( defaultLayoutContext.headerContent !== undefined )
		return defaultLayoutContext.headerContent;
	
	return null;
}