export const NotificationResource = {
    title: 'Повідомлення',
    fields: {
        user: {
            label: 'Отримувач',
            isRequired: true,
            type: 'select',
            resource: 'Company.User'
        },
        message: {
            label: 'Текст повідомлення',
            isRequired: true
        }
    },
    restUri: '/core_api/communications/notifications'
};