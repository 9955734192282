import { ServiceStore } from '@/Core/Stores/ServiceStore';

export const LoadApplicationItemTask = async (uri) => {

    const restClient = ServiceStore.get('restClient');

    const response = await restClient
        .get(`/core_api/purchase/application/items?entity=${uri}`)
        .getResponse();

    return response['hydra:member'] ?? [];
}