import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { UserStore } from '@/Core/Stores/UserStore';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useEffect } from 'react';
import { CounterStore } from '@/Ship/Stores/CounterStore';
import { useShowInfoNotificationTask } from '@/Ship/Tasks/ShowInfoNotificationTask';
import { LoadNotificationListTask } from '@/Containers/CommunicationSection/NotificationContainer/Tasks/LoadNotificationListTask';

export const MercureHandler = () => {

    const appConfig = ConfigStore.get('AppConfig');
    const restClient = ServiceStore.get('restClient');
    const ShowInfoNotificationTask = useShowInfoNotificationTask();

    useEffect(() => {
        restClient
            .get('/core_api/company/user/counters')
            .getResponse()
            .then( response => {
                if( response ) {
                    if( response.notifications !== undefined )
                        CounterStore.setNotifications(response.notifications);
                }
            });
    }, []);

    if( !appConfig?.mercureUrl )
        return;

    if( typeof window.wlMercureTopics === 'object' ) {
        window.wlMercureTopics.close();
    }
    
    const user = UserStore.user;

    if( !user?.id )
        return;

    const url = new URL(appConfig.mercureUrl);

    url.searchParams.append( 'topic', `/core_api/company/user/${user.id}/counters` );
    url.searchParams.append( 'topic', `/core_api/company/user/${user.id}/notifications` );

    url.searchParams.append( 'authorization', UserStore.mercureToken);
    const eventSource = new EventSource(url);
    
    eventSource.onmessage = e => {

        const result = JSON.parse(e.data);
        if( result.notifications !== undefined )
            CounterStore.setNotifications(result.notifications);
        
        if( result?.action && result.action === 'post' ) {
            ShowInfoNotificationTask(result.data.title, result.data.message);
            LoadNotificationListTask();
        }
    };
    
    window.wlMercureTopics = eventSource;

    window.addEventListener("beforeunload", function(e){
        eventSource.close();
        return null;
    }, false);
    
}