import { Badge, Button } from "antd";
import { IoMdNotificationsOutline } from "react-icons/io";
import { NotificationStore } from "../Stores/NotificationStore";
import { NotificationDrawer } from "./NotificationDrawer";
import { observer } from "mobx-react-lite";
import { CounterStore } from "@/Ship/Stores/CounterStore";

export const NotificationBarIcon = () => {

    const BadgeObserver = observer(() => {
        return <Badge
                count={CounterStore.notifications}
                style={{
                    backgroundColor: '#52c41a',
                }}
            >
                <span style={{ paddingRight: 15 }}>Повідомлення</span>
            </Badge>
    });

    return <>
        <a 
            href="#"
            onClick={() => NotificationStore.setIsDrawerOpen(true)}
        >
            <BadgeObserver />
        </a>
        <NotificationDrawer />
    </>;
}