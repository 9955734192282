import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { StatusBarDetailStore } from '../Stores/StatusBarDetailStore';

export const LoadDetailCardStatusListTask = () => {

    const resourceClient = ServiceStore.get('resourceClient');

    resourceClient.getCollection('Setting.ListPurchaseStatus')
        .getResponse()
        .then( response => {
            if( response && Array.isArray(response) )
                StatusBarDetailStore.setStatusList(response);
        });
}