import { ResourceStore } from "@/Core/Stores/ResourceStore";

export const GetResourceTask = resource => {

    if( typeof resource === 'string' ) {
        
        if( ResourceStore.get(resource) === undefined )
            return null;

        return {...ResourceStore.get(resource), key: resource};
    }

    if( typeof resource === 'object' && resource.key == undefined ) {
        console.warn('Resource without key parameter', resource);
    }

    if( typeof resource === 'object' && resource.key !== undefined )
        return {...resource};

    return null;
}