import { makeAutoObservable } from 'mobx';
import { Form, FormField, createFormContext } from '@/Core/UI/Form';
import { observer } from 'mobx-react-lite';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowSuccessNotificationTask } from '@/Ship/Tasks/ShowSuccessNotificationTask'; 
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { Modal as ModalAntd } from "antd";
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

export const useFormModal = ({ 
    fields = {},
    modalProps = {},
    initialValues = {},
    restUri = null,
    method = 'post',
    depFn = () => {},
    restUriReplaceParams = restUri => restUri,
    requestSuccessMessage = '',
    onBeforeSubmit = null,
    onAfterSuccessRequest = null
}) => {

    const { t } = useTranslation();
    const restClient = ServiceStore.get('restClient');

    const localStorage = makeAutoObservable({
        isModalOpen: false,
        setIsModalOpen( isModalOpen ) {
            this.isModalOpen = isModalOpen;
        }
    });

    const [ isLoading, setIsLoading ] = useState( false );

    const ShowSuccessNotificationTask = useShowSuccessNotificationTask();
    const ShowErrorNotificationTask = useShowErrorNotificationTask();

    const formContext = createFormContext({
        fields,
        onSubmit: ({ values }) => {

            if( typeof onBeforeSubmit === 'function' )
                onBeforeSubmit({ values, formContext });

            if( !restUri ) {
                console.error('"restUri" parameter not set.');
                return;
            }
            
            localStorage.setIsModalOpen( false );

            restClient[method](restUriReplaceParams(restUri), {
                json: values
            })
                .getResponse()
                .then( () => {

                    ShowSuccessNotificationTask( 'Успіх', requestSuccessMessage );

                    if( typeof onAfterSuccessRequest === "function" )
                        onAfterSuccessRequest({ values, formContext, localStorage });
                })
                .catch( async error => {
                    
                    const response = await error.response.json();

                    if( response && response['hydra:description'] != undefined ) {
                        ShowErrorNotificationTask( t("Error", { ns: 'Core' }), response['hydra:description'] );
                    }

                });
        },
        initialValues
    });

    if( !modalProps?.cancelText )
        modalProps.cancelText = 'Закрити';
    if( !modalProps?.okText )
        modalProps.okText = 'Зберегти';
    if( typeof modalProps.onOk !== 'function' )
        modalProps.onOk = () => formContext.submit();
    if( typeof modalProps.onCancel !== 'function' )
        modalProps.onCancel = () => localStorage.setIsModalOpen(false);
    if( modalProps.destroyOnClose === undefined )
        modalProps.destroyOnClose = true

    const Modal = observer(() => {

        depFn({ formContext });

        return <ModalAntd 
            open={localStorage.isModalOpen} 
            onCancel={() => localStorage.setIsModalOpen(false)}
            {...modalProps}
        >
            <Form formContext={formContext}>
                {Object.keys(fields).map( name => <FormField name={name} key={name} /> )}
            </Form>
        </ModalAntd>
    });

    return {
        Modal,
        localStorage,
        formContext,
        isLoading
    }
}