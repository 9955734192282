import { LuListPlus } from "react-icons/lu";
import { Button, Drawer, Tooltip } from 'antd';
import { observer } from "mobx-react-lite";
import { Form, FormField, FormBlock, FormRow } from "@/Core/UI/Form";
import { createFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { AddMultipleApplicationStore } from '../Stores/AddMultipleApplicationStore';
import { ApplicationItemResource } from '../Resources/ApplicationItemResource'; 
import { MdOutlineDeleteSweep } from "react-icons/md";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import i18n from '@/Ship/i18n';
import { AiOutlineAppstoreAdd } from "react-icons/ai";

import './useAddButtonHook.css';

export const useAddButtonHook = () => {

    const resourceClient = ServiceStore.get('resourceClient');
    const crudContext = useCrudResourceContext();

    const AddForm = () => {
        const formContext = createFormContext({
            fields: ApplicationItemResource.fields,
            initialValues: {
                entity: crudContext.formContext.values['@id']
            },
            onSubmit: async ({ values }) => {
                values.entity = crudContext.formContext.values['@id'];
                const response = await resourceClient.post('Purchase.ApplicationItem', {
                    json: values
                }).getResponse();
                return response;
            }
        });
        AddMultipleApplicationStore.addForm({ formContext });
    }

    const SubmitForms = async () => {

        let issetError = false;

        for( let i = 0; i < AddMultipleApplicationStore.forms.length; i++ ) {
            const formContext = AddMultipleApplicationStore.forms[i].formContext;
            await formContext.validate();
            if( !formContext.isValid )
                issetError = true;  
        }

        if( issetError )
            return;

        AddMultipleApplicationStore.setIsSendRequest( true );
        for( let i = 0; i < AddMultipleApplicationStore.forms.length; i++ ) {
            const formContext = AddMultipleApplicationStore.forms[i].formContext;
            await formContext.submit();
        }

        AddMultipleApplicationStore.setIsSendRequest( false );
        AddMultipleApplicationStore.setIsDrawerOpen( false );
        AddMultipleApplicationStore.setForms([]);
        crudContext.actions.reload();
        crudContext.listContext.actions.reload();
    }

    const SaveButton = observer(() => 
        <Button 
            type='primary' 
            loading={AddMultipleApplicationStore.isSendRequest} 
            onClick={() => SubmitForms()} 
            size='middle'
        >
            Зберегти
        </Button>
    );

    const AddMultipleApplication = observer(() => {

        if( AddMultipleApplicationStore.forms.length === 0 )
            AddForm(); 

        return <>
            <Drawer 
                title={'Додати'}
                width={500}
                open={AddMultipleApplicationStore.isDrawerOpen} 
                destroyOnClose={true} 
                onClose={() => AddMultipleApplicationStore.setIsDrawerOpen(false) } 
                extra={<SaveButton />}
                className='multiple-application'
            >
                {AddMultipleApplicationStore.forms.map( ( form, index ) => {
                    return (
                        <FormBlock 
                            title={index + 1 } 
                            key={`k-${index}`}
                            cardProps={{ 
                                size: 'small',
                                extra: index !== 0 ? <Button 
                                    type='primary' 
                                    icon={<MdOutlineDeleteSweep style={{ fontSize: 22 }} />} 
                                    danger={true}
                                    onClick={() => {
                                        AddMultipleApplicationStore.removeForm(index);
                                    } }
                                /> : null
                            }}
                        >
                            <Form {...form}>
                                <FormField name='transportNumber' />
                                <FormRow>
                                    <FormRow.Col>
                                        <FormField name='loadedQuantity' />
                                    </FormRow.Col>
                                    <FormRow.Col>
                                        <FormField name='unloadedQuantity' />
                                    </FormRow.Col>
                                </FormRow>
                                <FormRow>
                                    <FormRow.Col>
                                        <FormField name='targetWeight' />
                                    </FormRow.Col>
                                    <FormRow.Col>
                                        <FormField name='ttn' />
                                    </FormRow.Col>
                                </FormRow>
                            </Form>
                        </FormBlock>
                    );
                })}
                <Button type='primary' style={{ width: '100%' }} onClick={() => AddForm()}>Додати ще...</Button>
            </Drawer>
        </>
    });

    return { 
        AddButton: {
            label: i18n.t("Add", { ns: 'Core' }), 
            icon: <AiOutlineAppstoreAdd style={{ fontSize: 24 }} />, 
            sort: 100, 
            priority: 'button',
            size: 'middle',
            type: 'primary',
            depFn: ({ crudContext }) => crudContext.accessManager.isCan('create') === true,
            style: { display: 'flex', alignItems: 'center', position: 'relative' },
            onClick: () => AddMultipleApplicationStore.setIsDrawerOpen(true)
        }, 
        AddButtonDrawer: <AddMultipleApplication />
    }
}