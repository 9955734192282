import { ConfigStore } from '@/Core/Stores/ConfigStore';
// import { GetRestUriTask } from '@/Core/Tasks/GetRestUriTask';
import { UserStore } from '@/Core/Stores/UserStore';

export const MercureHandler = ({ crudContext, response, loadData }) => {

    const appConfig = ConfigStore.get('AppConfig');

    if( !appConfig?.mercureUrl )
        return;

    if( typeof window.wlMercureTopics === 'object' ) {
        window.wlMercureTopics.close();
    }
    
    const url = new URL(appConfig.mercureUrl);

    url.searchParams.append( 'topic', response['@id'] );
    url.searchParams.append( 'authorization', UserStore.mercureToken);
    const eventSource = new EventSource(url);
    
    eventSource.onmessage = e => {
        const result = JSON.parse(e.data);
        // const message = result.message;
        // const link = result.link ? result.link : null;
        loadData();
    };
    
    window.wlMercureTopics = eventSource;

    window.addEventListener("beforeunload", function(e){
        eventSource.close();
        return null;
    }, false);
    
}