import { UserFormTemplate } from "../Templates/UserFormTemplate";
// import { AddToFavorite } from "@/Ship/Components/AddToFavorite";
import i18n from '@/Ship/i18n';
import { UserStore } from "@/Core/Stores/UserStore";

export const UserResource = {
    title: 'Співробітники',
    fields: {
        isActive: {
            label: 'Активність',
            type: 'switch'
        },
        login: {
            label: 'Логін',
            isRequired: true
        },
        painPassword: {
            label: 'Пароль',
            type: 'password'
        },
        name: {
            label: 'Ім`я',
            isRequired: true
        },
        lastName: {
            label: 'Прізвище',
            isRequired: true
        },
        patronymic: {
            label: 'По-батькові'
        },
        birthDay: {
            label: 'Дата народження',
            type: 'date'
        },
        email: {
            label: i18n.t("E-mail"),
            isRequired: true
        },
        phones: {
            label: 'Телефон',
            isMultiple: true
        },
        position: {
            label: 'Посада'
        },
        image: {
            label: 'Фото',
            type: 'file'
        },
        departments: {
            label: 'Департамент',
            isRequired: true,
            isMultiple: true,
            type: 'select',
            resource: 'Company.Department'
        }
    },
    restUri: '/core_api/company/users',
    drawer: { 
        width: 900, 
        // title: <AddToFavorite /> 
    },
    template: UserFormTemplate,
    form: {
        eventHandlers: {
            onBeforeCreateRequestSend: ({ values }) => ({
                ...values,
                plainPassword: `p${Math.random()}`
            })
        }
    },
    accessFn: (mode) => {

        const roles = UserStore?.user?.roles ?? [];

        const rules = {
            create: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false,
            read: true,
            update: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false,
            delete: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false
        };

        if( rules[mode] !== undefined )
            return rules[mode];

        return false;
    },
};