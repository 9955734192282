import { ServiceStore } from '@/Core/Stores/ServiceStore';
import i18n from '@/Ship/i18n';

export const LoadUseCoreTask = ( code, formContext, ShowInfoNotificationTask ) => {

    const restClient = ServiceStore.get('restClient');

    restClient
        .get(`/core_api/system/youscore/legal_person/${code}`)
        .getResponse()
        .then( response => {

            Object.keys(response).map( key => {
                formContext.setInitialValue( key, response[key] );
            });

            formContext.setInitialValue( 'fullName', response['fullNameUA'] );
            formContext.setInitialValue( 'phones', [response['phone']] );
        })
        .catch( async error => {
            const response = await error.response.json();
            ShowInfoNotificationTask('Інформація', response['hydra:description']);
        });

}