import { useState, useSyncExternalStore } from 'react';
import { AiOutlineLike } from "react-icons/ai";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { UserStore } from "@/Core/Stores/UserStore";
import { toJS } from "mobx";

export const useApproveCalculationButtonHook = ({ crudContext }) => {

    const [ loading, setLoading ] = useState(false);

    const restClient = ServiceStore.get('restClient');

    const showErrorNotificationTask = useShowErrorNotificationTask();

    const approveCalculationButtonObject = {
        label: 'Підтвердити розрахунок',
        icon: <AiOutlineLike />,
        type: 'primary',
        loading,
        onClick: () => {
            
            setLoading( true );
            restClient.patch(
                '/core_api/purchase/purchases/is_calculation_confirmed',
                { json: { entity: crudContext.formContext.values['@id'] }}
            )
            .getResponse()
            .then( (response) => {
                Object.keys(response).map( key => {
                    crudContext.formContext.setInitialValue(key, response[key]);
                });
                // crudContext.actions.reload();
                setTimeout(() => {
                    crudContext.listContext.actions.reload();
                }, 1000);
            })
            .catch( async error => {

                const response = await error.response.json();

                if( response && response['@type'] === 'hydra:Error' ) {
                    showErrorNotificationTask('Помилка', response['hydra:description']);
                }
            })
            .finally(() => {
                setLoading( false );
            })

        },
        depFn: ({ crudContext }) => {

            if( !crudContext.formContext.values?.id )
                return false;

            if( crudContext.formContext.values.isCalculationConfirmed === true )
                return false;

            if( crudContext.formContext.values.status.code !== 'awaiting_confirmation' )
                return false;

            if( [ 'ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER' ].some( role => UserStore.user?.roles?.includes( role ) ) )
                return true;

            return false;
        }
    };

    return { approveCalculationButtonObject };
}