import { AiOutlineLike } from "react-icons/ai";
import { UserStore } from "@/Core/Stores/UserStore";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { useState } from 'react';

export const useSendToApproveButtonHook = ({ crudContext }) => {
    
    const [ loading, setLoading ] = useState(false);
    
    const restClient = ServiceStore.get('restClient');

    const showErrorNotificationTask = useShowErrorNotificationTask();

    const sendToApproveButtonObject = {
        label: 'Відправити на підтвердження',
        icon: <AiOutlineLike />,
        loading,
        type: 'primary',
        onClick: () => {

            setLoading( true );
            restClient.patch(
                '/core_api/purchase/purchases/awaiting_confirmation_status',
                { json: { entity: crudContext.formContext.values['@id'] }}
            )
            .getResponse()
            .then( () => {
                crudContext.actions.reload();
            })
            .catch( async error => {

                const response = await error.response.json();

                if( response && response['@type'] === 'hydra:Error' ) {
                    showErrorNotificationTask('Помилка', response['hydra:description']);
                }
            })
            .finally(() => {
                setLoading( false );
            })
        },
        depFn: ({ crudContext }) => {

            if( !crudContext.formContext.values?.id )
                return false;

            if(
                crudContext.formContext.values.status.code === 'done'
                && crudContext.formContext.values?.responsible?.id === UserStore.user.id
            )
                return true;

            return false;
        }
    };

    return {
        sendToApproveButtonObject
    };
}