import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const coreFiles = import.meta.glob('/src/Core/Locales/*/*.json', { eager: true });
const shipFiles = import.meta.glob('/src/Ship/Locales/*/*.json', { eager: true });
const containersFiles = import.meta.glob('/src/Containers/*Section/*Container/Locales/*/*.json', { eager: true });

let resources = {};
let namespaces = [];

if( Object.keys(coreFiles).length > 0 ) {

	namespaces.push('Core');

	Object.entries(coreFiles).forEach(([key, value]) => {

		const match = key.match(/\/Core\/Locales\/(\w+)\/(.+)\.json$/);
	
		if (match) {
	
			const lang = match[1];
			const ns = "Core";
	
			if (!resources[lang]) {
				resources[lang] = {};
			}
	
			resources[lang][ns] = {...resources[lang][ns], ...value.default};
		}
	});
}

if( Object.keys(shipFiles).length > 0 ) {

	namespaces.push('Ship');

	Object.entries(shipFiles).forEach(([key, value]) => {

		const match = key.match(/\/Ship\/Locales\/(\w+)\/(.+)\.json$/);
	
		if (match) {
	
			const lang = match[1];
			const ns = "Ship";
	
			if (!resources[lang]) {
				resources[lang] = {};
			}
	
			resources[lang][ns] = {...resources[lang][ns], ...value.default};
		}
	});
}

if( Object.keys(containersFiles).length > 0 ) {

	Object.entries(containersFiles).forEach(([key, value]) => {

		const match = key.match(/\/Containers\/(.+)Section\/(.+)Container\/Locales\/(\w+)\/(.+)\.json$/);

		if (match) {

			const sectionName = match[1];
			const containerName = match[2];
			const lang = match[3];
			const ns = `${sectionName}.${containerName}`;

			if (!resources[lang]) {
				resources[lang] = {};
			}

			if (!resources[lang][ns]) {
				resources[lang][ns] = {};
				namespaces.push(ns);
			}

			resources[lang][ns] = {...resources[lang][ns], ...value.default};
		}
	});
}

export let fallbackLng = 'en';
export let lng = 'en';

i18n
	.use(initReactI18next)
	.init({
		resources,
		ns: namespaces,
		fallbackLng,
		lng,
		defaultNS: 'Ship',
		debug: false,
		interpolation: {
			escapeValue: false,
		},
		parseMissingKeyHandler: function(missingKey) {
			return missingKey.substring(missingKey.lastIndexOf(':') + 1);
		}
	});

export const Resources = resources;
export const Namespaces = namespaces;

export default i18n;