import { Flex, Radio } from "antd";
import { observer } from 'mobx-react-lite';

const { Group } = Radio;

export const RadioGroupInput = observer(({ field, index }) => {

    if( field === undefined )
        return null;
    
    return <Group 
        options={field.props?.options ?? []}
        {...field.bind({ index: index })} 
    />
});