export class AccessManager {

    debug = false;

    context = null;
    accessFn = null;

    constructor({ context = null }) {
        this.context = context;
    }

    isCan(...args) {
        
        if( typeof this.accessFn !== 'function' )
            return true;

        if( this.accessFn.apply(this.context, args) === false )
            return false;

        return true;
    }

    setAccessFn( accessFn = null ) {
        if( typeof accessFn === 'function' )
            this.accessFn = accessFn;
    }

    log( message ) {
        if( this.debug === true )
            console.log( message );
    }

    warn( message ) {
        if( this.debug === true )
            console.warn( message );
    }
}