import { CrudResource } from "@/Core/UI/CrudResource";
import { ApplicationExpenseFormTemplate } from '../Templates/ApplicationExpenseFormTemplate';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';

export const ApplicationExpenseCrud = () => {

    const crudContext = useCrudResourceContext();

    if( !crudContext )
        return;

    return <CrudResource 
        resource='Purchase.ApplicationExpense'
        form={{
            template: ApplicationExpenseFormTemplate,
            initialValues: {
                entity: crudContext.formContext.values['@id']
            }
        }}
        list={{
            restUriReplaceParams: {
                entity_id: crudContext.formContext.values.id
            }
        }}
        table={{
            showColumns: [ 'expenseCategories', 'amountUAH', 'isVAT', 'amountWithVAT' ]
        }}
        isFilter={false}
    />
}