export const ListBaseStatusFields = {
    title: {
        label: 'Назва',
        isRequired: true
    },
    code: {
        label: 'Код',
        isRequired: true
    },
    color: {
        label: 'Колір',
        isRequired: true,
        type: 'color'
    },
    sorting: {
        label: 'Сортування',
        type: 'number'
    }
};