import { CheckNodeHelperTask } from '@/Core/Tasks/Utils/CheckNodeHelperTask';

function isObject(item) {

    return (
        item 
        && typeof item === 'object' 
        && !Array.isArray(item)
        && !CheckNodeHelperTask.isReactComponent(item)
        && !CheckNodeHelperTask.isObserver(item)
        && !CheckNodeHelperTask.isElement(item)
    );
}

function mergeDeep(target, ...sources) {
    if (!sources.length) return target;

    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                target[key] = source[key];
            }
        }
    }

    return mergeDeep(target, ...sources);
}

export const MergeConfigAndPropsTask = (configs) => {
    let objects = [];

    Object.keys(configs).forEach((keyConfig) => {
        const config = configs[keyConfig];

        if (config.features) {
            Object.keys(config.features).forEach((keyFeature) => {
                let feature = config.features[keyFeature];
                feature = typeof feature === 'function' ? feature() : {...feature};
                objects.push(feature);
            });
            // delete config.features;
        }

        objects.push(config);
    });

    const mergedObjects = mergeDeep({}, ...objects);
    return mergedObjects;
}