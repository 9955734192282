import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { UserStore } from "@/Core/Stores/UserStore";
import { ConfigStore } from '@/Core/Stores/ConfigStore';

export const ProtectedProvider = ({ protectedProviderCheckFn, children }) => {

    const navigate = useNavigate();
    const appConfig = ConfigStore.get('AppConfig');
    
    if( !UserStore.isAuthorized || Object.keys(UserStore.user).length === 0) {
        useEffect(() => {
            navigate(appConfig.authPageUrl);
        }, []);
        return null;
    }

    if( typeof protectedProviderCheckFn === 'function' && protectedProviderCheckFn() === false ) {
        return null;
    }

    return <>{children}</>
}