import { Dropdown } from "antd";
import { AiOutlineMenu } from "react-icons/ai";

export const AddActionsMenuFeature = {
    eventHandlers: {
        onBuildColumns: {
            AddActionsMenuColumns: ({ columns, tableContext }) => {

                if( tableContext.settings?.isActionsColumnHide === true )
                    return;

                if( columns.filter( column => column.key === '_actions').length === 0)
                    columns.unshift({
                        title: '',
                        dataIndex: '_actions',
                        key: '_actions',
                        width: 40,
                        fixed: 'left'
                    });
            }
        },
        onBuildDataSourceItem: {
            AddActionsMenuItem: ({ item, tableContext, crudContext }) => {

                if( tableContext.settings?.isActionsColumnHide === true )
                    return;
                
                const transformerStorage = tableContext.storages?.AddActionsMenuDataSourceItemTransformer ?? tableContext.props?.storages?.AddActionsMenuDataSourceItemTransformer ?? {}

                let menuItems = [];

                if( typeof transformerStorage?.menuItems === 'function' )
                    menuItems = transformerStorage.menuItems({ item, tableContext });
                else if( transformerStorage?.menuItems )
                    menuItems = transformerStorage.menuItems;

                tableContext.eventManager.run('onMenuItemsBuild', { menuItems, item, tableContext, crudContext });

                item['_actions'] = Array.isArray(menuItems) && menuItems.length > 0 ? <Dropdown
                    menu={{ items: menuItems }}
                    trigger={['click']}
                    {...(transformerStorage?.dropdownProps ?? {})}
                >
                    <a onClick={(e) => e.preventDefault()}>
                        {transformerStorage?.menuIcon ?? <AiOutlineMenu style={{color: '#010101', fontSize: 18}} /> }
                    </a>
                </Dropdown> : null;
            } 
        }
    }
};