import { DefaultLayout as CoreDefaultLayout } from "@/Core/Layouts/DefaultLayout";

import { AntdConfig } from "@/Ship/Configs/AntdConfig";
import { AppConfig } from "@/Ship/Configs/AppConfig";
import { FormConfig } from '@/Ship/Configs/FormConfig';
import { ListConfig } from "@/Ship/Configs/ListConfig";
import { TableConfig } from "@/Ship/Configs/TableConfig";
import { CrudResourceConfig } from "@/Ship/Configs/CrudResourceConfig";
import { DefaultValueConfig } from "@/Ship/Configs/DefaultValueConfig";

import { Services } from "@/Ship/Services";

import { Menu } from "@/Ship/Menu";
import { Resources } from "@/Ship/Resources";

// import { FavoriteList } from "@/Ship/Components/FavoriteList";

import styles from './DefaultLayout.module.css';
import './CustomContent.css';
import { MercureHandler } from './Components/MercureHandler';

export const DefaultLayout = () => {
    
    return <>
        <CoreDefaultLayout 
            configs={{
                AntdConfig, AppConfig, FormConfig,
                ListConfig, TableConfig, CrudResourceConfig,
                DefaultValueConfig
            }}
            services={Services}
            resources={Resources}
            storages={{
                menuItems: Menu
            }}
            isShowSecondBar={false}
            secondBarContent={[
                // <FavoriteList key='secondbar-favorite' />
                // <NotificationBarIcon key='secondbar-notification-bar' />
            ]}
            mobileBarContent={[
                // <FavoriteList key='secondbar-favorite-mobile' />
            ]}
            customStyle={styles}
            scripts={<MercureHandler />}
        />
    </>
}