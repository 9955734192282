import { observer } from 'mobx-react-lite';

import { Upload, Progress, List, Modal, Empty, Typography, Tooltip, App, Drawer, Button, Popconfirm, Space } from 'antd';
import { IoCloudUploadOutline } from "react-icons/io5";
import { MdOutlineDelete } from "react-icons/md";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { makeAutoObservable } from 'mobx';

// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import 'react-pdf/dist/esm/Page/TextLayer.css';

const { Dragger } = Upload;
const { Paragraph } = Typography;

import i18n from '@/Core/i18n';
import { useTranslation } from 'react-i18next';
import { FaRegFileWord } from "react-icons/fa";
import { FaRegFileExcel } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import { FaRegFileArchive } from "react-icons/fa";
import { FaRegFileAudio } from "react-icons/fa";
import { FaRegFileVideo } from "react-icons/fa";
import { FaRegFile } from "react-icons/fa6";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { UserStore } from "@/Core/Stores/UserStore";
import { GrView } from "react-icons/gr";
import { FiEdit } from "react-icons/fi";
import { ConfigStore } from '@/Core/Stores/ConfigStore';

import './FileInput.css';
import { useEffect } from 'react';

// https://gist.github.com/tzmartin/1cf85dc3d975f94cfddc04bc0dd399be
// https://github.com/wojtekmaj/react-pdf

const makeFileList = ( value ) => {

    if( !value )
        return [];
    
    if( !Array.isArray(value) ) {
        return [
            {
                uid: '-1',
                name: value.originalName,
                status: 'done',
                url: value.contentUrl,
                thumbUrl: value.contentUrl,
            }
        ];
    }

    return value.map( file => ({
        uid: file['@id'],
        name: file.originalName,
        status: 'done',
        url: file.contentUrl,
    }));
}

const RenderProccess = observer(({ field }) => {

    const fileList = makeFileList( field.value );

    return <Progress
        percent={Math.floor(
            (fileList.filter( file => file.status === 'done' ).length / fileList.length) * 100 
        )}
        status="active"
    />
});

function getFileTypeByMIME(mimeType) {

    if( mimeType === undefined || mimeType === null || mimeType === '' )
        return 'other';
    if (mimeType.match(/^image\//)) {
        return 'image';
    } else if (mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || mimeType === 'application/msword') {
        return 'word';
    } else if (mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || mimeType === 'application/vnd.ms-excel') {
        return 'excel';
    } else if (['application/acrobat', 'application/nappdf', 'application/pdf', 'application/x-pdf', 'image/pdf', 'application/octet-stream'].includes(mimeType)) {
        return 'pdf';
    } else if (mimeType.match(/(zip|rar|7z|tar|tar.gz|tar.bz2)$/)) {
        return 'archive';
    } else if (mimeType.match(/^audio\//)) {
        return 'audio';
    } else if (mimeType.match(/^video\//)) {
        return 'video';
    } else {
        return 'other';
    }
}

const getAvatar = (file) => {

    const type = getFileTypeByMIME(file.mimeType);
    
    switch (type) {
        case 'image':
            return <img src={file.contentUrl} style={{ maxHeight: 50, maxWidth: 50 }}/>;
        case 'word':
            return <><FaRegFileWord style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        case 'excel':
            return <><FaRegFileExcel style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        case 'pdf':
            return <><FaRegFilePdf style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        case 'archive':
            return <><FaRegFileArchive style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        case 'audio':
            return <><FaRegFileAudio style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        case 'video':
            return <><FaRegFileVideo style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
        default:
            return <><FaRegFile style={{ fontSize: 22, color: 'var(--ant-color-primary)' }} /></>;
    }
}

const RenderUploadList = observer(({ field, restClient, localStore, values, crudContext }) => {
    const { t } = useTranslation();
    return <List
        dataSource={values}
        renderItem={(file) => {

            const showViewIcon = field.props?.showViewIcon === false ? false : true;
            const showEditIcon = field.props?.showEditIcon === false ? false : true;
            const showDeleteIcon = field.props?.showDeleteIcon === false ? false : true;

            let actions = [];

            if( showViewIcon )
                actions.push(<a href='#' onClick={() => onItemClick(file, localStore, crudContext, false, field)}><GrView style={{ fontSize: 24, color: 'var(--ant-color-primary)' }} /></a>);

            const type = getFileTypeByMIME( file.mimeType );
            if( field.isEditable && field.formContext.isEditable && type === 'word' && showEditIcon ) {
                actions.push(<a href='#' onClick={() => onItemClick(file, localStore, crudContext, true, field)}><FiEdit style={{ fontSize: 24, color: 'var(--ant-color-primary)' }} /></a>);    
            }

            if( field.isEditable && field.formContext.isEditable && showDeleteIcon )
                actions.push(<Popconfirm
                    title={t("Delete file", { ns: 'Core' })}
                    description={t("Are you sure you want to delete the file?", { ns: 'Core' })}
                    onConfirm={() => onRemove(file, field, restClient, crudContext)}
                    okText={t("Yes", { ns: 'Core' })}
                    cancelText={t("No", { ns: 'Core' })}
                >
                    <Tooltip placement="top" title={t("Delete", { ns: 'Core' })}>
                        <Button key="delete" type='link' danger={true} style={{ padding: 0}}>
                            <MdOutlineDelete style={{ fontSize: 24 }} />
                        </Button>
                    </Tooltip>
                </Popconfirm>);
            
            if( actions.length === 0 )
                actions = null;

            return (
                <List.Item actions={actions}>
                    <List.Item.Meta 
                        avatar={<a href={file.contentUrl ?? file.fileUrl}>{getAvatar(file, localStore)}</a> } 
                        title={ 
                            <a href={file.contentUrl ?? file.fileUrl}>
                                <Tooltip title={file.originalName ?? file.name}>
                                    <Paragraph ellipsis={true}>{file.originalName ?? file.name}</Paragraph>
                                </Tooltip>
                            </a>
                        }
                    />
                </List.Item>
            )
        }}
    />
});

const RenderUploadButton = observer(({ field, customRequest }) => {

    // const fileList = makeFileList( field.value );
    const { t } = useTranslation();

    const isMultiple = field.inputProps?.mode === 'multiple' || field.props?.mode === 'multiple';

    if( 
        !isMultiple 
        && (
            (!Array.isArray(field.value) && field.value)
            || (Array.isArray(field.value) && field.value.length > 0) 
        )
    )
        return;
    
    return <Dragger
        customRequest={customRequest}
        // fileList={fileList}
        multiple={isMultiple}
        showUploadList={false}
        disabled={field.inputProps?.disabled === true ? true : false }
    >
        <IoCloudUploadOutline style={{ fontSize: 36, position: 'relative', top: 5 }} />
        <p style={{ fontSize: 22, marginBottom: 10 }}> {t("Select file", { ns: 'Core' })} </p>
        <p style={{ fontSize: 11, padding: 0 }}> {t("Choose or drag a file to this area", { ns: 'Core' })}</p>
    </Dragger>;
});

const buildContentUrl = (filePath, fileType, crudContext, isEditable = true, field) => {
    const appConfig = ConfigStore.get('AppConfig');
    const user = UserStore.user;
    let viewUrl = field.props?.officeViewUrl ?? '/core_api/crm/printable';
    
    if( typeof viewUrl === 'function' )
        viewUrl = viewUrl({ filePath, fileType, crudContext, isEditable, field });
    if( field.props?.officeViewUrlType )
        fileType = field.props.officeViewUrlType;

    let url =  `${appConfig.collaboraOfficeBrowserUrl}file_path=${appConfig.backendUrl}${viewUrl}/${crudContext.formContext.values.id}/wopi/user=${user.id}|type=${fileType}|write=${isEditable}&lang=${appConfig.currentLocale}&NotWOPIButIframe=true`;

    if( typeof field.props?.officeViewUrlCustom === 'function' )
        url = field.props.officeViewUrlCustom({
            collaboraOfficeBrowserUrl: appConfig.collaboraOfficeBrowserUrl,
            backendUrl: appConfig.backendUrl,
            viewUrl,
            id: crudContext.formContext.values.id,
            crudContext, 
            user,
            fileType,
            currentLocale: appConfig.currentLocale
        });

    return url;
}

const onItemClick = ( file, localStore, crudContext, isEditable, field ) => {
    
    const type = getFileTypeByMIME( file.mimeType );
    localStore.setFileType(type);
    localStore.setDrawerTitle(file.originalName);
    switch (type) {
        case 'image':
            localStore.setImageSrc(file.contentUrl ?? file.fileUrl);
            localStore.setModalOpen(true);
            break;
        case 'word':
            localStore.setContentUrl(buildContentUrl(file.contentUrl ?? file.fileUrl, type, crudContext, isEditable, field));
            localStore.setDrawerOpen(true);
            break;
        case 'excel':
            localStore.setContentUrl(buildContentUrl(file.contentUrl ?? file.fileUrl, type, crudContext, isEditable, field));
            localStore.setDrawerOpen(true);
            break;
        case 'pdf':
            localStore.setContentUrl(buildContentUrl(file.contentUrl ?? file.fileUrl, type, crudContext, isEditable, field));
            localStore.setDrawerOpen(true);
            break;
        case 'archive':
            location.href = file.contentUrl ?? file.fileUrl;
            break;
        case 'audio':
            location.href = file.contentUrl ?? file.fileUrl;
            break;
        case 'video':
            location.href = file.contentUrl ?? file.fileUrl;
            break;
        default:
            location.href = file.contentUrl ?? file.fileUrl;
            break;
    }
};

const onRemove = (file, field, restClient, crudContext) => {

    restClient.delete(file['@id']).getResponse().then(() => {

        let newList = undefined; 

        if( Array.isArray(field.value) )
            newList = field.value.filter( fValue => file['@id'] !== fValue['@id'] );
        
        if( newList.length === 0 )
            newList = null;

        field.formContext.setValue(field.path, newList);

        if( typeof field.props.eventHanlers?.onAfterDelete === 'object' ) {
            Object.values(field.props.eventHanlers.onAfterDelete).map( handler => {
                if( typeof handler === 'function' )
                    handler({ file, field, formContext: field.formContext, crudContext });
            })
        }
    });
};

function post(msg) {
    window.frames[0].postMessage(JSON.stringify(msg), '*');
}

function save() {
    post({'MessageId': 'Action_Save',
        'Values': { 'Notify': true, 'ExtendedData': 'CustomFlag=Custom Value;AnotherFlag=AnotherValue' }
    });
}

function closeDocument() {
    post({'MessageId': 'Action_Close',
        'Values': null
    });
}



export const FileInput = ({ field, index }) => {

    const crudContext = useCrudResourceContext();
    const { notification } = App.useApp();
    const { t } = useTranslation();

    function receiveMessage(event) {

        let msg = event.data;

        if( typeof event.data === 'string' )
            msg = JSON.parse(event.data);

        if (!msg)
            return;
        
        if (msg.MessageId == 'App_LoadingStatus') {
          if (msg.Values) {
            if (msg.Values.Status == 'Document_Loaded') {
              post({'MessageId': 'Host_PostmessageReady'});
              var iframe = document.getElementById('iframe-collabora');
              var style = document.createElement('style');
              style.textContent = `
                .iframe-welcome-wrap,
                .unotoolbutton.notebookbar.unoNavigator,
                #Help-tab-label,
                #renamedocument,
                .main-nav.hasnotebookbar #document-header,
                #view-navigator {
                  display: none;
                }
              `;
              iframe.contentWindow.document.head.appendChild(style);
            }
          }
        }else if (msg.MessageId == 'Action_Save_Resp') {
          if (msg.Values) {
            if (msg.Values.success == true) {
                notification.success({
                    duration: 3,
                    message: t("Success", { ns: 'Core' }),
                    description: t("The file was successfully saved.", { ns: 'Core' }),
                    placement: 'bottomRight'
                });
            } else {
                notification.error({
                    duration: 3,
                    message: t("Error", { ns: 'Core' }),
                    description: t("Error saving the file.", { ns: 'Core' }),
                    placement: 'bottomRight'
                });
            }
          }
        }
    }

    useEffect(() => {

        window.addEventListener('message', receiveMessage, false);

        return () => {
          window.removeEventListener('message', receiveMessage, false);
        };
      }, []
    );

    if( field === undefined )
        return null;

    // pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    field.setIsDisableTransform( true );

    const localStore = makeAutoObservable({
        imageSrc: null,
        setImageSrc( src ){ this.imageSrc = src; },
        setDrawerOpen( isOpen ) { this.drawerOpen = isOpen },
        drawerOpen: false,
        numPages: 0,
        setNumPages( numPages ) { this.numPages = numPages; },
        setContentUrl( url ) { this.contentUrl = url }, 
        contentUrl: null,
        fileType: null,
        isFileEditable: true,
        modalOpen: false,
        setModalOpen( open ) {
            this.modalOpen = open;
        },
        setFileType( fileType ) { this.fileType = fileType },
        setIsFileEditable( isFileEditable ) { this.isFileEditable = isFileEditable },
        drawerTitle: null,
        setDrawerTitle( title ) { this.drawerTitle = title }
    });

    const restClient = ServiceStore.get('restClient');
    
    const customRequest = async ({ file, onSuccess, onError, ...props }) => {

        try {

            if( field.props?.uploadUrl?.length > 0 ) {

                let uploadUrl = field.props?.uploadUrl;

                if( typeof field.props?.uploadUrlReplacer === 'function' ) {
                    uploadUrl = field.props?.uploadUrlReplacer({ uploadUrl, field, formContext: field.formContext });
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = async (e) => {

                    field.setState('isUpload', true);
                    const body = new FormData();
                    const base64String = e.target.result;
                    const base64Data = base64String.replace(/^data:[\w+.-]+\/[\w+.-]+;base64,/, '');
                    const decodedData = atob(base64Data);
    
                    const byteArray = new Uint8Array(decodedData.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteArray[i] = decodedData.charCodeAt(i);
                    }
    
                    const blobFile = new Blob([ byteArray ], { type: file.type });
    
                    body.append( 'file', blobFile, file.name );
                    body.append( 'name', file.name );

                    restClient.post( 
                        uploadUrl,
                        { body }, 
                        { isNotHeaderAddContentType: true } 
                    )
                    .getResponse()
                    .then( response => {

                        let initialValue = field.formContext.getInitialValue( field.path );

                        if( typeof initialValue === 'object' && !Array.isArray(initialValue) )
                            initialValue = [initialValue];
    
                        initialValue ??= [];
                        initialValue.push( response );
    
                        field.formContext.setValue( field.path, initialValue );

                        if( typeof field.props.eventHanlers?.onAfterUpload === 'object' ) {
                            Object.values(field.props.eventHanlers.onAfterUpload).map( handler => {
                                if( typeof handler === 'function' )
                                    handler({ field, formContext: field.formContext, crudContext });
                            })
                        }
                    })
                    .catch( async error => {
                        const response = await error?.response?.json();
                        if( response['@type'] === 'hydra:Error' ) {
                            notification.error({
                                duration: 3,
                                message: t("Error", { ns: 'Core' }),
                                description: response['hydra:description'],
                                placement: 'bottomRight'
                            });
                        }
                    })
                    .finally(() => {
                        field.setState('isUpload', false);
                    });
                };
            } else {
                field.formContext.setValue( field.path, file );
            }
        } catch (error) {
            console.error(error);
        }
    };

    // const onDocumentLoadSuccess = ({ numPages }) => {
    //     localStore.setNumPages(numPages);
    // }

    const DrawerSaveButton = observer(() => {
        if( localStore.fileType === 'word' )
            return <Button type='primary' onClick={() => {save()}}>{t("Save", { ns: 'Core' })}</Button>

        return null;
    });

    const FileInputObserver = observer(() => {
        
        let values = Array.isArray(field.value) ? field.value : typeof field.value === 'object' ? [ field.value ] : [];
        
        return <div>
            {field.isEditable && field.formContext.isEditable && <RenderUploadButton field={field} customRequest={customRequest} />}
            {field.states.isUpload && <RenderProccess field={field} />}
            {
                Array.isArray(values)
                && values.length > 0
                && <RenderUploadList field={field} restClient={restClient} localStore={localStore} values={values} crudContext={crudContext} />
            }
            {(!field.isEditable || !field.formContext.isEditable) && Array.isArray(field.value) && field.value.length === 0 && <Empty />}
            <Modal open={localStore.modalOpen} footer={[]} destroyOnClose={true} onCancel={() => {
                localStore.setImageSrc(null);
                localStore.setModalOpen(false);
            }}>
                <img src={localStore.imageSrc} style={{ maxWidth: '100%', maxHeight: '100%' }}/>
            </Modal>
            <Drawer 
                title={localStore.drawerTitle}
                open={localStore.drawerOpen} 
                width={1000} 
                onClose={() => {
                    localStore.setDrawerOpen(false);
                    localStore.setContentUrl(null);
                    closeDocument();

                    if( localStore.isFileEditable ) {
                        crudContext.actions.edit(crudContext.formContext.values);
                    }
                }} 
                destroyOnClose={true}
                extra={<DrawerSaveButton />}
                className='wl-fileinput-drawer'
            >
                <iframe id="iframe-collabora" src={localStore.contentUrl} width='100%' height='100%' style={{ border: 0 }}></iframe>
            </Drawer>
            {/* <Drawer open={localStore.drawerOpen} width={1000} onClose={() => {
                localStore.setDrawerOpen(false);
                localStore.setContentUrl(null);
            }} destroyOnClose={true}>
                <div>
                    <Document
                        file={localStore.contentUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        {Array.from(new Array(localStore.numPages), (el, index) => (
                            <Page
                                renderAnnotationLayer={false}
                                renderTextLayer={false}
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={1.2}
                            />
                        ))}
                    </Document>
                </div>
            </Drawer> */}
        </div>
    });
    
    return ( <FileInputObserver /> );
};