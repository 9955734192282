import { useSortable, defaultAnimateLayoutChanges } from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import { Container } from './Container';

const animateLayoutChanges = (args) => defaultAnimateLayoutChanges({...args, wasDragging: true});

export function DroppableContainer({
    children,
    columns = 1,
    disabled,
    id,
    items,
    style,
    ...props
  }) 
  {
    const {
      active,
      isDragging,
      over,
      setNodeRef,
      transition,
      transform,
    } = useSortable({
      id,
      data: {
        type: 'container',
        children: items,
      },
      animateLayoutChanges,
    });
  
    const isOverContainer = over
      ? (id === over.id && active?.data.current?.type !== 'container') ||
        items.includes(over.id)
      : false;
  
    return (
      <Container
        ref={disabled ? undefined : setNodeRef}
        style={{
          ...style,
          transition,
          transform: CSS.Translate.toString(transform),
          opacity: isDragging ? 0.5 : undefined,
        }}
        hover={isOverContainer}
        columns={columns}
        {...props}
      >
        {children}
      </Container>
    );
}