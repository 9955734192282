import { List, Skeleton } from "antd"
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";
import { AppConfig } from "@/Ship/Configs/AppConfig";
import './NotificationList.css';
import { CiRead } from "react-icons/ci";
import { NotificationStore } from "../Stores/NotificationStore";
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";
import { SetNotificationReadedTask } from "../Tasks/SetNotificationReadedTask";
import { LoadNotificationListTask } from "../Tasks/LoadNotificationListTask";
import { CiCircleRemove } from "react-icons/ci";
import { RemoveNotificationTask } from "../Tasks/RemoveNotificationTask";

export const NotificationList = observer(() => {

    const notifications = toJS(NotificationStore.notifications);

    const resources = {
        application: "Purchase.Application",
        sale: "Sale.Sale",
        purchase: "Purchase.Purchase"
    };

    const defaultLayoutContext = useDefaultLayoutContext();

    const createClickHandler = (type, id) => (e) => {
        e.preventDefault();
        defaultLayoutContext.openResource({ 
            resourceCode: resources[type], 
            resourceId: id
        });
    };

    const replaceBBCode = (str) => {
        return str.replace(/\[(\w+)=(\d+)\]\[#\d+\]([^\[]+)\[\/\1\]/g, (match, type, id, content) => {
            if (resources[type]) {
                return `<a href="#" data-type="${type}" data-id="${id}">${content.trim()}</a>`;
            }
            return match;
        });
    };

    const handleClick = (e) => {
        if (e.target.tagName === 'A' && e.target.hasAttribute('data-type') && e.target.hasAttribute('data-id')) {
            const type = e.target.getAttribute('data-type');
            const id = e.target.getAttribute('data-id');
            createClickHandler(type, id)(e);
        }
    };

    return (
        <List 
            className="notification-list"
            loading={NotificationStore.initLoading}
            itemLayout="vertical"
            dataSource={notifications}
            renderItem={ notification => (
                <List.Item className={`${!notification.isReaded ? 'unreaded' : ''}`}>
                    <Skeleton avatar title={false} loading={NotificationStore.isNotificationLoading} active>
                        <List.Item.Meta
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                    <span>{notification.title}</span>
                                    {!notification.isReaded && <span>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            SetNotificationReadedTask(notification);
                                        }}>
                                            <CiRead style={{ fontSize: 24, color: '#6b6b6b' }} />
                                        </a>
                                        <a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            RemoveNotificationTask(notification.id);
                                        }}>
                                            <CiCircleRemove style={{ fontSize: 22, color: '#6b6b6b' }} />
                                        </a>
                                    </span>}
                                </div>
                            }
                            description={`${(new dayjs(notification.createdAt)).format(AppConfig.datetimeDefaultFormat)} / ${notification?.initiatedBy?.fullName ?? ''}`}
                        />
                        <div 
                            dangerouslySetInnerHTML={{ __html: replaceBBCode(notification.message) }} 
                            onClick={handleClick}
                        />
                    </Skeleton>
                </List.Item>
            )}
            pagination={{
                onChange: (pageNumber) => {
                    LoadNotificationListTask(pageNumber);
                },
                total: NotificationStore.totalCount,
                defaultCurrent: 1,
                defaultPageSize: 20,
                hideOnSinglePage: true
            }}
        />
    );
});