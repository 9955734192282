import { ApiPlatformResourceClientService } from '@/Core/Services/Clients/ApiPlatformResourceClientService';
import { RestClientService } from '@/Core/Services/Clients/RestClientService';
import { AntdConfig } from '@/Ship/Configs/AntdConfig';

export const Services = {
    restClient: new RestClientService({
        backendUrl: AntdConfig.backendUrl
    }),
    resourceClient: new ApiPlatformResourceClientService({
        backendUrl: AntdConfig.backendUrl
    })
};