import { useEffect, useState } from "react";
import { Button, Space, Table } from "antd";
import { toJS } from "mobx";
import { AiOutlineDelete } from "react-icons/ai";

import { Form, FormField, createFormContext } from "@/Core/UI/Form";
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { useAddSaleItemButtonHook } from '../Hooks/useAddSaleItemButtonHook';
import { LoadSaleItemTask } from "../Tasks/LoadSaleItemTask";
import { EditSaleItemTask } from "../Tasks/EditSaleItemTask";
import { DeleteSaleItemTask } from "../Tasks/DeleteSaleItemTask";
// import { useImportTransportHook } from '../Hooks/useImportTransportHook';

import './SaleItemCrud.css';
import { useAutoSearchSaleItemButtonHook } from '../Hooks/useAutoSearchSaleItemButtonHook';
import { useShowSuccessNotificationTask } from "@/Ship/Tasks/ShowSuccessNotificationTask";
import { useShowErrorNotificationTask } from "@/Ship/Tasks/ShowErrorNotificationTask";

export const SaleItemCrud = () => {

    const crudContext = useCrudResourceContext();

    const [ isLoading, setIsLoading ] = useState(false);
    const [ dataSource, setDataSource ] = useState([]);

    const showSuccessNotificationTask = useShowSuccessNotificationTask();
    const showErrorNotificationTask = useShowErrorNotificationTask();

    const formContext = createFormContext({
        onChange: ({ changedValues, formContext }) => {

            const keys = Object.keys(changedValues);
            const currentKey = keys[0].split(',');
            const index = currentKey[0];
            const data = toJS(formContext.values[index]);
            const item = dataSource[index];

            if( data.quantity === null )
                return;

            (async () => {

                data.purchase = data.purchase['@id'];

                try {
                    const response = await EditSaleItemTask(item['@id'], data);

                    if( response?.entity?.optionPercentage !== undefined )
                        crudContext.formContext.setInitialValue('optionPercentage', response.entity.optionPercentage );
                    if( response?.entity?.amountUAH !== undefined )
                        crudContext.formContext.setInitialValue('amountUAH', response.entity.amountUAH );
    
                    crudContext.listContext.actions.reload();

                    showSuccessNotificationTask('Успіх', 'Зміни успішно збережено.');
                } catch( error ) {
                    const response = await error.response.json();
                    if( response['@type'] === "hydra:Error" ) {
                        showErrorNotificationTask('Помилка', response['hydra:description']);
                    }
                }
                
                
            })();
        }
    });
    
    const loadItems = async () => {

        setIsLoading(true);

        let items = await LoadSaleItemTask(crudContext.formContext.values['@id']);

        if( items.length > 0 ) {
            items = items.map( item => {
                if( typeof item.purchase === 'object' )
                    item.purchase = {
                        ...item.purchase,
                        title: item.purchase.id
                    };
                return item;
            });
            
            setDataSource( items );
        }

        setIsLoading(false);
    }

    const { AddButton, AddButtonDrawer } = useAddSaleItemButtonHook();
    // const { ImportTransportButton, ImportTransportDrawer } = useImportTransportHook();
    const { AutoSearchSaleItemButton, AutoSearchSaleItemDrawer } = useAutoSearchSaleItemButtonHook({ loadItems });


    useEffect(() => {
        (async () => {
            await loadItems();
        })();
    }, []);

    if( !crudContext )
        return;

    const columns = [
        { 
            title: 'Закупівля', 
            key: 'purchase', 
            dataIndex: 'purchase',
            width: '25%',
            render: ( value, item, index ) => {
                return <FormField 
                    name={[index, 'purchase']} 
                    initialValue={value} 
                    type='select' 
                    resource='Purchase.Purchase' 
                    responseLabel={ value => value.contractNumber }
                    allowClearOff={true}
                    isLink={true}
                    isEditable={false}
                    view={value => value.contractNumber}
                />
            }
        },
        { 
            title: 'Постачальник', 
            key: 'supplier', 
            dataIndex: 'supplier',
            width: '50%',
            render: ( value, item, index ) => {
                return <FormField 
                    name={[index, 'supplier']} 
                    initialValue={value} 
                    type='select' 
                    resource='Purchase.Supplier' 
                    responseLabel={ value => value.title }
                    allowClearOff={true}
                    isLink={true}
                    isEditable={false}
                    view={value => value.title}
                />
            }
        },
        { 
            title: 'Кількість', 
            key: 'quantity', 
            dataIndex: 'quantity',
            width: '25%',
            render: ( value, item, index ) => {
                return <FormField type='number' name={[index, 'quantity']} initialValue={value} />
            }
        },
        {
            title: '',
            key: '_actions', 
            dataIndex: '_actions',
            render: ( value, item ) => {
                return <Space>
                    {/* <Button icon={<AiOutlineEdit />} onClick={async () => {
                        const values = toJS(formContext.values);
                        const data = values[index];
                        await EditApplicationItemTask(item['@id'], data);
                        crudContext.actions.reload();
                        crudContext.listContext.actions.reload();
                    }}/> */}
                    <Button icon={<AiOutlineDelete />} onClick={async () => {
                        await DeleteSaleItemTask(item['@id']);
                        crudContext.actions.reload();
                        crudContext.listContext.actions.reload();
                    }} danger={true} />
                </Space>
            }
        }
    ];

    return <div className="sale-item">
        <Space style={{ marginLeft: 'auto' }}>
            {/* <Button {...ImportTransportButton} style={{ margin: '0px 0px 10px auto'}}>{ImportTransportButton.label}</Button> */}
            <Button {...AutoSearchSaleItemButton} style={{ margin: '0px 0px 10px auto'}}>{AutoSearchSaleItemButton.label}</Button>
            <Button {...AddButton} style={{ margin: '0px 0px 10px auto'}}>{AddButton.label}</Button>
        </Space>
        <Form formContext={formContext}> 
            <Table columns={columns} dataSource={dataSource} loading={isLoading} pagination={false} />
            {AddButtonDrawer}
            {/* {ImportTransportDrawer} */}
            {AutoSearchSaleItemDrawer}
        </Form>
    </div>
}