import { observer } from "mobx-react-lite";

export const DefaultViewFieldTemplate = observer(({ field, children }) => {
    
    if( field === undefined )
        return;

    return <div className={`form-field form-field-default`}>
        {field.label && <label htmlFor={field.id}>{field.label}</label>}
        <div className='form-field-view-value'>{children}</div>
    </div>;
});