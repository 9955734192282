import { useDefaultLayoutContext } from '../DefaultLayoutContextProvider';

export const SecondBarContent = () => {
    
    const defaultLayoutContext = useDefaultLayoutContext();

	if( defaultLayoutContext.secondBarContent !== undefined )
		return defaultLayoutContext.secondBarContent;
	
	return null;
}