import React, { useRef, useEffect } from 'react';
import EditorJS from '@editorjs/editorjs';
import Quote from '@editorjs/quote';
import SimpleImage from "./SimpleImage.js";
import Header from '@editorjs/header';
import Paragraph from '@editorjs/paragraph';
import List from "@editorjs/list";
import Delimiter from '@editorjs/delimiter';
import Table from '@editorjs/table'
import { EmailSignature } from './EmailSignature';
import './EditorJsInput.css';

const ukrainianTranslation = {
    messages: {
      "ui": {
        "blockTunes": {
            "toggler": {
                "Click to tune": "Натисніть, щоб налаштувати",
                "or drag to move": "або перетягніть для переміщення"
            },
        },
        "toolNames": {
            "Text": "Текст",
            "Heading": "Заголовок"
        },

      },
    }
};

export const EditorJsInput = ({ field, index }) => {

    const editorContainer = useRef(null);
    const editorInstance = useRef(null);
    const { value: data, onChange } = field.bind();

    useEffect(() => {
        if (!editorInstance.current) {

            editorInstance.current = new EditorJS({
                holder: editorContainer.current,
                data: data,
                i18n: ukrainianTranslation,
                onReady: () => {
                },
                onChange: () => {
                    editorInstance.current.save().then((outputData) => {
                        onChange(outputData);
                    }).catch((error) => {
                        console.error('Saving failed: ', error);
                    });
                },
                tools: {
                    table: {
                        class: Table,
                        inlineToolbar: true,
                        config: {
                          rows: 2,
                          cols: 3,
                        },
                    },
                    quote: Quote,
                    header: Header,
                    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                    },
                    list: {
                        class: List,
                        inlineToolbar: true,
                        config: {
                          defaultStyle: 'unordered'
                        }
                    },
                    delimiter: Delimiter,
                    emailSignature: {
                        class: EmailSignature
                    },
                    image: SimpleImage,
                },
            });
        }

        return () => {
            if (editorInstance.current && typeof editorInstance.current.destroy === 'function') {
                editorInstance.current.destroy();
                editorInstance.current = null;
            }
        };
    }, []);

    return <div ref={editorContainer} style={{
        borderRadius: 'var(--ant-border-radius-lg)',
        padding: 'var(--ant-input-padding-block-lg) var(--ant-input-padding-inline-lg)',
        background: 'var(--ant-color-bg-container)',
        borderWidth: 'var(--ant-line-width)',
        borderStyle: 'var(--ant-line-type)',
        borderColor: 'var(--ant-color-border)'
    }}/>;
}