export const GetPathByKeysTask = ( routes, key ) => {
    let pathUrl = null;

    if( Array.isArray( routes ) && typeof key === 'string' ) {
      routes.map( item => {
        if( Array.isArray( item.children ) && pathUrl === null ) {
          pathUrl = GetPathByKeysTask( item.children, key );
          return;
        }
        else if( item.key === key && item?.path ) {
          pathUrl = item.path;
          return;
        }
      })
    }

    return pathUrl;
}