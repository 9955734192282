import { Button, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import './FormMultipleField.css';

export const FormMultipleField = observer(({ field, InputComponent }) => {
    
    // let values = field.formContext.getValue( field.path );
    let values = field.value;
    
    if( !Array.isArray(values) ) {
        field.formContext.setInitialValue(field.path, ['']);
        values = [''];
    }

    return <div className='form-field-multiple'>
        {values?.map( (value, index) => {
            return <Space key={`k${index}`} className='form-field-multiple-item'>
                <InputComponent field={field} index={index} />
                <Button className='form-field-multiple-button-minus' style={{width: '100%'}} type="dashed" onClick={() => field.remove(index)}>-</Button>
            </Space>;
        })}
        <Button className='form-field-multiple-buttom-plus' type="dashed" onClick={field.add}>+</Button>
    </div>;
});