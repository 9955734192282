import { KanbanStore } from '../Stores/KanbanStore';
import { TRASH_ID, findContainer } from '../functions';

export const onDragOverHandler = ({active, over, collisions}) => {

    const overId = over?.id;

    if (overId == null || overId === TRASH_ID || active.id in KanbanStore.items) {
      return;
    }

    const overContainer = findContainer(overId);
    const activeContainer = findContainer(active.id);

    if (!overContainer || !activeContainer) {
      return;
    }
    
    if (activeContainer !== overContainer) {
      KanbanStore.setItems((items) => {

        const activeItems = items[activeContainer];
        const overItems = items[overContainer];
        const overIndex = overItems.indexOf(overId);
        const activeIndex = activeItems.indexOf(active.id);

        let newIndex;

        if (overId in items) {
          newIndex = overItems.length + 1;
        } else {
          const isBelowOverItem =
            over &&
            active.rect.current.translated &&
            active.rect.current.translated.top >
              over.rect.top + over.rect.height;

          const modifier = isBelowOverItem ? 1 : 0;

          newIndex =
            overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        }
        // TODO: !!!
        // recentlyMovedToNewContainer.current = true;

        return {
          ...items,
          [activeContainer]: items[activeContainer].filter(
            (item) => item !== active.id
          ),
          [overContainer]: [
            ...items[overContainer].slice(0, newIndex),
            items[activeContainer][activeIndex],
            ...items[overContainer].slice(
              newIndex,
              items[overContainer].length
            ),
          ],
        };
      });

      let aConId = [];
      
      collisions.map( coll => {
        if( typeof coll.id === 'string' )
          aConId.push(coll.id);
      });
      
      if( aConId.length > 0 )
        KanbanStore.setActiveContainerId(aConId[0]);
    }
}