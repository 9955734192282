import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { UserStore } from "@/Core/Stores/UserStore";
import { NotificationStore } from '../Stores/NotificationStore';

export const LoadNotificationListTask = (page = 1, filter = {}) => {

    const restClient = ServiceStore.get('restClient');

    NotificationStore.setIsNotificationLoading(true);
    restClient
        .get('/core_api/communications/notifications', {
            searchParams: { user: UserStore.user['@id'], page, ...filter }
        })
        .getResponse()
        .then( response => {

            if( response && Array.isArray(response['hydra:member'])) {
                NotificationStore.setNotifications(response['hydra:member']);
                NotificationStore.setTotalCount(response['hydra:totalItems']);
                NotificationStore.setIsNotificationLoading(false);
            }
        });
}