import { Avatar, Drawer, Dropdown, Space } from "antd"
import { CiMenuKebab } from "react-icons/ci";
import { CgProfile } from "react-icons/cg";
import { FiLogOut } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

import './index.css';
import { useNavigate } from "react-router-dom";
import { UserStore } from "@/Core/Stores/UserStore";
import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";

export const UserInfo = observer(() => {

    const navigate = useNavigate();
    const defaultLayoutContext = useDefaultLayoutContext();
    const { t } = useTranslation();

    const items = [
        {
            key: 'profile',
            icon: <CgProfile style={{fontSize: 20}} />,
            label: (
              <a href="#" onClick={() => navigate('/profile')}>{t("My profile", { ns: 'Core' } )}</a>
            ),
        },
        {
            key: 'logout',
            icon: <FiLogOut style={{fontSize: 20}} />,
            label: (
              <a href="#" onClick={() => {
                UserStore.clear();
                navigate('/auth/login');
              }}>{t("Log out", { ns: 'Core' })}</a>
            ),
        },
    ];

    let initials = UserStore.user?.fullName?.match(/[А-Я]/g);
    
    if(initials)
        initials = initials.slice(0, 2).join('');

    return <div className="wl-user-info">
        <Space>
            {!defaultLayoutContext.isCollapsedFirstBar && <Avatar size="large" gap={1} className="avatar" src={UserStore.user?.image?.contentUrl}>
                    {initials}
            </Avatar>}
            {!defaultLayoutContext.isCollapsedFirstBar && <div className="info-block">
                <div className="fullname">{UserStore.user?.lastName ?? ''} {UserStore.user?.name ?? ''}</div>
                <div className="position">{UserStore.user?.position ?? ''}</div>
            </div>}
            <Dropdown menu={{items}}>
                <a onClick={(e) => e.preventDefault()}>
                    <Space>
                        {!defaultLayoutContext.isCollapsedFirstBar && <BsThreeDotsVertical className="down-arrow" />}
                        {defaultLayoutContext.isCollapsedFirstBar && <Avatar size="large" gap={1} className="avatar" src={UserStore.user?.image?.contentUrl}>
                            {initials}
                        </Avatar>}
                    </Space>
                </a>
            </Dropdown>
        </Space>
    </div>
});