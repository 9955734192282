import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListCurrencyRateResource = {
    title: 'Курси валют',
    fields: {
        date: {
            label: "Дата",
            isRequired: true,
            type: 'datetime'
        },
        updateDatetime: {
            label: "Дата та час оновлення",
            isRequired: true,
            type: 'datetime'
        },
        denominationCurrency: {
            label: "Валюта номіналу",
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListCurrency'
        },
        denomination: {
            label: "Номінал",
            type: 'number'
        },
        currency: {
            label: "Валюта",
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListCurrency'
        },
        rate: {
            label: "Курс",
            type: 'number'
        }
    },
    restUri: '/core_api/settings/currencies/rates',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};