import { KanbanStore } from '../Stores/KanbanStore';

export const onDragCancelHandler = () => {
    
    if (KanbanStore.clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      KanbanStore.setItems(KanbanStore.clonedItems);
    }

    KanbanStore.setActiveId(null);
    KanbanStore.setClonedItems(null);
};