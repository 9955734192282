import { Form, FormField } from "@/Core/UI/Form";
import { useEffect, useState } from "react";
import { Card } from 'antd';
import { LoadDocumentTypeVariablesByIdTask } from '../Tasks/LoadDocumentTypeVariablesByIdTask';
import { Dependency } from "@/Core/UI/Dependency";

const Variables = ({ id }) => {

    const [loading, setLoading] = useState(false);
    const [variables, setVariables] = useState({});
    
    useEffect(() => {
        (async () => {
            if( id ) {
                setLoading(true);
                const vars = await LoadDocumentTypeVariablesByIdTask({ id });
                setVariables(vars);
                setLoading(false);
            }
        })();
    }, []);

    if( !id )
        return;
    
    return <Card loading={loading} title='Змінні' style={{ marginTop: 25 }}>
        {Object.keys(variables).map( code => <p><b>{code}</b>: {variables[code]}</p>)}
    </Card>
}

export const ListDocumentTypeFormTemplate = ({ formContext }) => {
    return <Form formContext={formContext}>
        <FormField name='title' />
        <FormField name='provider' />
        <FormField name='file' />
        <FormField name='sorting' />
        <Dependency depFn={({ formContext }) => !!formContext.values?.provider?.id }>
            {() => <Variables id={formContext.values?.provider?.id} /> }
        </Dependency>
    </Form>;
}