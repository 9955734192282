import { Badge, Card } from "antd";
import './FormBlock.css';

export const FormBlock = ({ title, badgeProps, cardProps, children }) => {

    if( title === false )
        return <div className='wl-form-block'>
            <Card title={false} style={{marginBottom: 25}} {...cardProps}>
                {children}
            </Card>
        </div>;
        
    return <div className='wl-form-block'>
        <Badge.Ribbon text={ title ?? null } placement="start" {...badgeProps}>
            <Card title={false} style={{marginBottom: 25}} {...cardProps}>
                {children}
            </Card>
        </Badge.Ribbon>
    </div>;
}