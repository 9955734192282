import { Outlet } from "react-router-dom";

import { ProtectedProvider } from "@/Core/Providers/ProtectedProvider";
import { ResourceStore } from "@/Core/Stores/ResourceStore";

import { BaseLayout } from "../BaseLayout";
import { DefaultTemplate } from './Templates/DefaultTemplate';
import { DefaultLayoutContextProvider, createDefaultLayoutContext } from './DefaultLayoutContextProvider';

import { HeaderContent } from "./Components/HeaderContent";
import { FirstBarContent } from "./Components/FirstBarContent";
import { SecondBarContent } from "./Components/SecondBarContent";
import { MobileBarContent } from './Components/MobileBarContent';
import { GetResourceTask } from "@/Core/Tasks/Resources/GetResourceTask";
import { createCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { CrudResource } from "@/Core/UI/CrudResource";
import { observer } from 'mobx-react-lite';
import { useEffect } from "react";

export const DefaultLayout = ({
    configs = {},
    services = {},
    resources = {},
    template = null,
    protectedProviderCheckFn = null,
    scripts = null,
    ...props
}) => {

    const TemplateLayout = typeof template === 'function' ? template : DefaultTemplate; 
    const defaultLayoutContext = createDefaultLayoutContext({...props});

    const ResourceComponentObserver = observer(() => {

        if( !defaultLayoutContext.resourceCode )
            return;

        const resource = GetResourceTask(defaultLayoutContext.resourceCode);

        if( typeof resource !== 'object' )
            return;

        const crudContext = createCrudResourceContext({ resource });
        defaultLayoutContext.setResourceContext( crudContext );

        return <CrudResource crudContext={crudContext} isDrawerOnly={true} {...defaultLayoutContext.crudResourceProps} />;
    });

    ResourceStore.clear();
    ResourceStore.sets( resources );

    useEffect(() => {

        const handleResize = () => {
            if( !defaultLayoutContext.isMobileMode && window.innerWidth <= defaultLayoutContext.mobileModeWidth  )
                defaultLayoutContext.setIsMobileMode( true );

            if( defaultLayoutContext.isMobileMode && window.innerWidth > defaultLayoutContext.mobileModeWidth  )
                defaultLayoutContext.setIsMobileMode( false );
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return <BaseLayout configs={configs} services={services}>
        <ProtectedProvider protectedProviderCheckFn={protectedProviderCheckFn}>
            <DefaultLayoutContextProvider value={defaultLayoutContext}>
                {(() => {
                    
                    const headerContent = <HeaderContent />;
                    const firstBarContent = <FirstBarContent />;
                    const secondBarContent = <SecondBarContent />;
                    const mobileBarContent = <MobileBarContent />;
                    const outlet = <Outlet />;

                    return <TemplateLayout
                        headerContent={headerContent}
                        outlet={outlet}
                        firstBarContent={firstBarContent}
                        secondBarContent={secondBarContent}
                        mobileBarContent={mobileBarContent}
                    />;

                })()}
                <ResourceComponentObserver />
                {scripts}
            </DefaultLayoutContextProvider>
        </ProtectedProvider>
    </BaseLayout>;
}