import { AiOutlineLike } from "react-icons/ai";
import { useFormModal } from '@/Ship/Hooks/useFormModal';
import { UserStore } from "@/Core/Stores/UserStore";
import { useState } from 'react';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';

export const useExecutionConfirmedStatusButton = (crudContext, entity) => {

    const restClient = ServiceStore.get('restClient');
    const showErrorNotificationTask = useShowErrorNotificationTask();
    const [ loading, setLoading ] = useState(false);
    const { Modal, localStorage } = useFormModal({
        fields: {
            comment: {
                type: 'textarea',
                isRequired: true
            }
        },
        modalProps: {
            title: 'Напишіть коментар'
        },
        method: 'patch',
        restUri: '/core_api/sale/sales/implementation_status',
        onAfterSuccessRequest: () => {
            crudContext.actions.reload();
            crudContext.listContext.actions.reload();
        },
        onBeforeSubmit: ({ values }) => {
            values.entity = entity ?? crudContext.formContext.values['@id'];
        }
    });

    const roles = UserStore?.user?.roles ?? [];

    const executionConfirmedStatusButton = {
        label: 'Підтвердження Execution',
        icon: <AiOutlineLike />,
        type: 'primary',
        loading,
        depFn: ({ crudContext }) => !!crudContext.formContext.values?.id 
                                    && crudContext.formContext.values?.status.code === 'confirmed_logistician'
                                    && [ 'ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_EXECUTOR' ].some( role => roles.includes(role)),
        dropdown: {
            menu: {
                items: [
                    {
                        label: 'Підтвердити',
                        key: 'approve',
                        onClick: () => {
                            setLoading(true);
                            restClient
                            .patch('/core_api/sale/sales/implementation_status', {
                                json: {
                                    entity: crudContext.formContext.values['@id'],
                                    isConfirmed: true
                                }
                            })
                            .getResponse()
                            .then(() => {
                                crudContext.actions.reload();
                                crudContext.listContext.actions.reload();
                            })
                            .catch( async error => {

                                const response = await error.response.json();
                                
                                if( response && response['@type'] === 'hydra:Error' ) {
                                    showErrorNotificationTask('Помилка', response['hydra:description']);
                                }
                            })
                            .finally(() => {
                                setLoading(false);
                            });
                        }
                    },
                    {
                        label: 'Відхилити',
                        key: 'cancel',
                        danger: true,
                        onClick: () => { localStorage.setIsModalOpen(true) }
                    }
                ]
            }
        }
    };

    return {
        executionConfirmedStatusButton,
        ExecutionConfirmedStatusModal: Modal
    }
}