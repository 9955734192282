import { KanbanStore } from '../Stores/KanbanStore';
import { findContainer, getIndex } from '../functions';
import { Item } from '../Components/Item';

export const DragItemTemplate = ({ id }) => (
    <Item
      value={'Card'}
      handle={true}
      style={{
        containerId: findContainer(id),
        overIndex: -1,
        index: getIndex(id),
        value: id,
        isSorting: true,
        isDragging: true,
        isDragOverlay: true,
      }}
      color={KanbanStore.items[findContainer(id)]?.color}
      wrapperStyle={{index: 0}}
      dragOverlay
    />
);