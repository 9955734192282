import { useState } from 'react';
import { AiOutlineLike } from "react-icons/ai";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { UserStore } from "@/Core/Stores/UserStore";

export const useSendToApproveButtonHook = ({ crudContext }) => {

    const [ loading, setLoading ] = useState(false);

    const restClient = ServiceStore.get('restClient');

    const showErrorNotificationTask = useShowErrorNotificationTask();

    const sendToApproveButtonObject = {
        label: 'Відправити на погодження',
        icon: <AiOutlineLike />,
        type: 'primary',
        loading,
        onClick: () => {
            
            if( crudContext.formContext.initialValues.items.length === 0 ) {
                showErrorNotificationTask('Помилка', 'Мають бути обрані закупівлі.');
                return;
            }

            setLoading( true );
            restClient.patch(
                '/core_api/sale/sales/confirmation_status',
                { json: { entity: crudContext.formContext.values['@id'] }}
            )
            .getResponse()
            .then( () => {
                crudContext.actions.reload();
            })
            .catch( async error => {

                const response = await error.response.json();
                
                if( response && response['@type'] === 'hydra:Error' ) {
                    showErrorNotificationTask('Помилка', response['hydra:description']);
                }
            })
            .finally(() => {
                setLoading( false );
            });
        },
        depFn: ({ crudContext }) => {

            if( !crudContext.formContext.values?.id )
                return false;
            
            if( crudContext.formContext.values.status?.code !== 'new' )
                return false;

            if( [ 'ROLE_ADMIN', 'ROLE_SUPER_USER' ].some( role => UserStore.user?.roles?.includes( role ) ) )
                return true;

            if( crudContext.formContext.values.responsible.id === UserStore.user.id )
                return true;
           
            return false;
        }
    };

    return { sendToApproveButtonObject };
}