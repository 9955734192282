
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListOrganizationResource = {
    title: 'Організації',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        fullName: {
            label: 'Повна назва'
        },
        edrpou: {
            label: 'Код ЄДРПОУ',
            isRequired: true
        },
        ipn: {
            label: 'ІПН',
            isRequired: true
        },
        email: {
            label: 'Пошта'
        },
        phones: {
            label: 'Телефони',
            isMultiple: true
        },
        otherContact: {
            label: 'Інші контактні дані',
            type: 'textarea'
        },
        director: {
            label: 'Директор'
        },
        onBasis: {
            label: 'Директор діє на підставі'
        },
        address: {
            label: 'Адреса'
        },
        iban: {
            label: 'Рахунок IBAN'
        },
        bank: {
            label: 'Банк рахунку'
        },
        comment: {
            label: 'Коментар',
            type: 'textarea'
        }
    },
    restUri: '/core_api/company/organizations',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};