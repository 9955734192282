import { DefaultFieldTemplate } from "@/Core/UI/Form/Templates/Field/DefaultFieldTemplate";
import { 
    StringInput,
    NumberInput,
    CheckboxInput,
    PasswordInput, 
    SwitchInput,
    SelectInput,
    DatePickerInput,
    ColorPickerInput,
    MaskedInput,
    TextareaInput,
    DatePickerRangeInput,
    FileInput,
    RadioGroupInput
} from "@/Core/UI/Form";

import { EditorJsInput } from "@/Core/UI/Form/Inputs/EditorJsInput";

export const FormConfig = {
    defaultFieldTemplate: DefaultFieldTemplate,
    fieldTypes: {
        string: StringInput,
        number: NumberInput,
        checkbox: CheckboxInput,
        password: PasswordInput,
        switch: SwitchInput,
        select: SelectInput,
        textarea: TextareaInput,
        color: ColorPickerInput,
        mask: MaskedInput,
        date: DatePickerInput,
        datetime: DatePickerInput,
        daterange: DatePickerRangeInput,
        datetimerange: DatePickerRangeInput,
        file: FileInput,
        editorjs: EditorJsInput,
        radiogroup: RadioGroupInput
    },
    // TODO: coming soon...
    fieldTemplates: {}
};