
import { KanbanStore } from './Stores/KanbanStore'; 
import { defaultDropAnimationSideEffects } from '@dnd-kit/core';

export const findContainer = (id) => {
  if (id in KanbanStore.items) {
    return id;
  }
  return Object.keys(KanbanStore.items).find((key) => KanbanStore.items[key].includes(id));
};
  
export function getNextContainerId() {
  const containerIds = Object.keys(KanbanStore.items);
  const lastContainerId = containerIds[containerIds.length - 1];

  return String.fromCharCode(lastContainerId.charCodeAt(0) + 1);
}
  
export const dropAnimation = {
  sideEffects: defaultDropAnimationSideEffects({
    styles: {
      active: {
        opacity: '0.5',
      },
    },
  }),
};
  
export const TRASH_ID = 'void';

export const getIndex = (id) => {
  const container = findContainer(id);

  if (!container) {
    return -1;
  }

  const index = KanbanStore.items[container].indexOf(id);

  return index;
};