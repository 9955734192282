import { Spin } from 'antd';
import { BaseLayout } from "../BaseLayout";
import { Outlet } from "react-router-dom";
import { Suspense } from 'react';

export const AuthLayout = ({ contentStyles, modalStyles, configs, services }) => {

    contentStyles = contentStyles ?? {};

    contentStyles = {
        display: 'flex',
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#e5e3e3',
        height: '100vh',
        ...contentStyles
    }

    modalStyles = modalStyles ?? {};
    modalStyles = {
        maxWidth: '600px',
        minWidth: '320px',
        padding: '25px',
        position: 'relative',
        zIndex: 20,
        background: '#ffffff',
        borderRadius: '8px', 
        ...modalStyles
    }

    return <BaseLayout configs={configs} services={services}>
        <div style={contentStyles}>
            <div style={modalStyles}>
                <Suspense fallback={
                    <div style={{ 
                        width: '100%', 
                        height: '100%', 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center' 
                    }}>
                        <Spin size="large" />
                    </div>
                }>
                    <Outlet />
                </Suspense>
            </div>
        </div>
    </BaseLayout>
}