import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Menu as MenuAntd, Tooltip } from 'antd';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { GetPathByKeysTask } from "@/Core/Tasks/UI/Menu/GetPathByKeysTask";
import { UserStore } from "@/Core/Stores/UserStore";
import { useDefaultLayoutContext } from '@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider';

import './Menu.css';
import { DeepCloneTask } from '@/Core/Tasks/Utils/DeepCloneTask';

export const Menu = ({ items }) => {
    
    const defaultLayoutContext = useDefaultLayoutContext();

    items = items ?? [];

    if( items.length === 0 && Array.isArray(defaultLayoutContext?.storages?.menuItems) )
        items = defaultLayoutContext.storages.menuItems;

    items = DeepCloneTask(items).filter( menuItem => {
        if( typeof menuItem?.accessFn !== 'function' )
            return true;

        return menuItem.accessFn('read');
    });

    items = items.map( item => {
        if( Array.isArray(item?.children) ) {
            item.children = item.children.filter( menuItem => {
                if( typeof menuItem?.accessFn !== 'function' )
                    return true;

                return menuItem.accessFn('read');
            });
        }
            
        return item;
    });

    items = items.map( ({ accessFn, ...item }) => {
        if( Array.isArray(item?.children) ) {
            item.children = item.children.map(({ accessFn, ...childrenOne }) => {
                if( Array.isArray(childrenOne?.children) ) {
                    childrenOne.children = childrenOne.children.map(({ accessFn, ...subChildrenOne }) => {
                        return subChildrenOne;
                    })
                }
                return childrenOne;
            })
        }
        return item;
    } );

    const navigate = useNavigate();
    const location = useLocation();
    const [selectedKeys, setSelectedKeys] = useState([]);
    const user = UserStore.user;

    if( typeof items === 'function' )
        items = items({user});
    
    const transformedItems = items.map( ({...item}) => {

        if( item.type === 'group' && item?.label ) {
            item.label = <Tooltip placement="right" title={item.label}>
                {item.label}
            </Tooltip>
        }

        return item;
    });

    useEffect(() => {

        const findKeys = (items, path) => {
            for (const item of items) {
            if (item.path && item.path === path) {
                setSelectedKeys([item.key]);
                return true;
            } else if (item.children && findKeys(item.children, path)) {
                return true;
            }
            }
            return false;
        };
    
        findKeys(items, location.pathname);

    }, [location.pathname]);

    const MenuObserver = observer(() => {
        return <>
            <div className={'wl-menu-main' + (defaultLayoutContext.isCollapsedFirstBar ? ' is-collapsed' : '')}>
                <OverlayScrollbarsComponent
                    style={{ width: '100%', height: '100%' }}
                    options={{
                        overflow: { x: 'hidden', y: 'scroll' },
                        scrollbars: { visibility: 'auto', autoHide: 'leave', autoHideDelay: 300 }
                    }}
                    defer
                >
                    <MenuAntd
                        mode="inline"
                        selectedKeys={selectedKeys}
                        style={{ marginBottom: '25px' }}
                        items={ transformedItems }
                        inlineCollapsed={defaultLayoutContext.isCollapsedFirstBar}
                        onClick={ ({ key }) => {
                            const pathUrl = GetPathByKeysTask( items, key );
                            
                            if( pathUrl && pathUrl !== '#') {
                                navigate( pathUrl );

                                if( defaultLayoutContext.isMobileMode )
                                    defaultLayoutContext.setIsOpenMobileFirstBar( false );
                            }
                        }}
                    />
                </OverlayScrollbarsComponent>
            </div>
        </>
    });

    return <MenuObserver />;
}