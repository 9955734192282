import { OverlayScrollbars } from "@/Core/UI/OverlayScrollbars";
import { Kanban } from "@/Ship/Components/Kanban";
import { ListPurchaseStatusResource } from "@/Containers/SettingSection/ListContainer/Resources/ListPurchaseStatusResource";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { StatusBarKanban } from './StatusBarKanban';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { useTranslation } from 'react-i18next';
import { Typography } from "antd";

const { Paragraph, Text } = Typography;

export const PurchaseKanbanMode = () => {
    
    const resourceClient = ServiceStore.get('resourceClient');
    const crudContext = useCrudResourceContext();
    const { t } = useTranslation();

    const onLoad = ({ KanbanStore }) => {
        KanbanStore.setIsLoading(true);
        resourceClient
            .getCollectionNoStore('Setting.ListPurchaseStatus')
            .then( async response => {
                response = await response.json();
                let items = {};
                let containers = [];
                let containersData = {};

                if( response && Array.isArray(response) ) {
                    response.map( item => {
                        items[item.code] = [];
                        containers.push(item.code);
                        containersData[item.code] = {
                            '@id': item['@id'],
                            title: item.title,
                            color: item.color,
                            code: item.code
                        };
                    });
                }

                let promises = [];

                Object.keys(containersData).map( key => {
                    const response = resourceClient
                        .getCollection('Purchase.Purchase', {
                            searchParams: {
                                status: containersData[key]['@id']
                            }
                        })
                        .getResponse();

                    promises.push(response);
                });

                Promise.all(promises).then( responses => {
                    responses.map( response => {
                        if( response && Array.isArray(response) && response.length > 0 )
                            response.map( item => {
                                items[item.status.code].push(item);
                            })
                    });
                    KanbanStore.setItems(items);
                    KanbanStore.setContainers(containers);
                    KanbanStore.setContainersData(containersData);
                    KanbanStore.setIsLoading(false);
                });
            });
    }

    const renderItem = ({ KanbanStore, containerId, index }) => {
        const item = KanbanStore.items[containerId][index];
        return <>
            <div style={{ maxWidth: 280 }}>
                <div><b>{t("ID")}:</b> <a href='#' onClick={(e) => {
                        e.preventDefault();
                        crudContext.actions.view({ id: item.id });
                    }} style={{ whiteSpace: 'normal' }}>{item.id} [відкрити]</a></div>
                {/* <div>
                    <b>{t("Name")}:</b> <a href='#' onClick={(e) => {
                        e.preventDefault();
                        crudContext.actions.view({ id: item.id });
                    }} style={{ whiteSpace: 'normal' }}>{item.title}</a>
                </div> */}
                <div><Text ellipsis={true}><b>Організація:</b> {item.organization?.title ?? '-'}</Text></div>
                <div><Text ellipsis={true}><b>Постачальник:</b> {item?.supplier?.title ?? '-'}</Text></div>
                <div><Text ellipsis={true}><b>Номенклатура:</b> {item.product?.title ?? '-'}</Text></div>
                <div><b>Кількість:</b> {item.quantity ?? '-'}</div>
                <div><b>Ціна:</b> {`${item.price} ${item.priceCurrency.code ?? ''}`}</div>
                <StatusBarKanban kanbanStore={KanbanStore} currentStatus={item.status} application={item} />
            </div>
        </>;
    }

    return <OverlayScrollbars style={{ maxWidth: '100%' }}>
        <Kanban uri={ListPurchaseStatusResource.restUri} onLoad={onLoad} renderItem={renderItem} />
    </OverlayScrollbars>;
}