import React from "react";

export const CheckNodeHelperTask = {
    isClassComponent(component) {
        return (
            typeof component === 'function' && 
            !!component?.prototype?.isReactComponent
        )
    },
    isFunctionComponent(component) {
        return (
            typeof component === 'function'
        )
    },
    isReactComponent(component) {
        return (
            this.isClassComponent(component) || 
            this.isFunctionComponent(component)
        )
    },
    isObserver( component ) {
        
        if( component?.type?.name === 'observerComponent' )
            return true;

        return false;
    },
    isElement(element) {
        return React.isValidElement(element);
    },
    isDOMTypeElement(element) {
        return this.isElement(element) && typeof element?.type === 'string';
    },
    isCompositeTypeElement(element) {
        return this.isElement(element) && typeof element?.type === 'function';
    },
    isNodeObject( node ) {
        return !this.isElement(node) && typeof node?.type === 'string';
    }
}