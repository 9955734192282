import { CrudResource } from "@/Core/UI/CrudResource";
import { TbLayoutKanban } from "react-icons/tb";
import { KanbanMode } from './KanbanMode';
import { UserStore } from "@/Core/Stores/UserStore";
import { Tag } from 'antd';

export const SaleCrud = ({ form = {}, ...props }) => {
    
    form.initialValues ??= {};

    if( !form?.initialValues?.responsible )
        form.initialValues.responsible = UserStore.user;

    return <CrudResource 
        resource='Sale.Sale'
        form={{
            ...form
        }}
        modeItems={{
            kanban: {
                title: 'Канбан',
                icon: TbLayoutKanban,
                component: KanbanMode
            }
        }}
        table={{
            eventHandlers: {
                onBuildColumn: {
                    transformStatus: ({ column }) => {
                        if( column.key === 'status' ) {
                            column.render = ( status, item ) => <Tag color={item._original.status.color}>{status}</Tag>
                        }

                        if( [ 
                                'isDocumentsSended', 'isDocumentsReceived', 
                                'contractAmount', 'amountUAH', 'contractAmountCurrency', 
                                'documentSigningType', 'isConfirmedLogistician', 
                                'commentExecution' ].includes(column.key) 
                        ) {
                            if( column?.title?.props?.children ) {
                                const title = column.title.props.children;
                                column.title = title;
                            }
                        }
                    }
                }
            }
        }}
        {...props}
    />
}