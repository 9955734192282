import { observer } from "mobx-react-lite";
import { NotificationStore } from "../Stores/NotificationStore";
import { Form, FormField, FormRow, createFormContext } from "@/Core/UI/Form";
import { Button } from "antd";
import { LoadNotificationListTask } from "../Tasks/LoadNotificationListTask";

export const NotificationFilter = observer(() => {

    const formContext = createFormContext({
        fields: {
            createdAt: {
                label: 'Створено',
                type: 'daterange'
            },
            type: {
                label: 'Тип',
                type: 'select',
                options: [
                    { label: 'Користувацьке', value: 'user' },
                    { label: 'Системне', value: 'system' },
                ]
            },
            initiatedBy: {
                label: 'Від кого',
                type: 'select',
                resource: 'Company.User'
            },
            title: {
                label: 'Назва'
            }
        },
        onSubmit: ({ values }) => {

            let filter = {};

            if( values.title )
                filter.title = values.title;

            if( values.type )
                filter.type = values.type;

            if( values.initiatedBy )
                filter.initiatedBy = values.initiatedBy['@id'];

            if( values.createdAt ) {
                
                filter["createdAt[after]"] = values.createdAt[0].format("YYYY-MM-DD");
                filter["createdAt[before]"] = values.createdAt[1].format("YYYY-MM-DD");

            }

            LoadNotificationListTask(1, filter);
        }
    });

    if( !NotificationStore.isFilterOpen )
        return;

    return <div style={{ padding: 15 }}>
        <Form formContext={formContext}>
            <FormRow>
                <FormRow.Col>
                    <FormField name='title' />
                </FormRow.Col>
                <FormRow.Col>
                    <FormField name='type' />
                </FormRow.Col>
            </FormRow>
            <FormRow>
                <FormRow.Col>
                    <FormField name='initiatedBy' />
                </FormRow.Col>
                <FormRow.Col>
                    <FormField name='createdAt' />
                </FormRow.Col>
            </FormRow>
            <FormRow>
                <FormRow.Col>
                    <Button type="primary" htmlType="submit" size="middle">Фільтрувати</Button>
                </FormRow.Col>
                <FormRow.Col>
                    <Button size="middle" onClick={() => {
                        formContext.reset();
                        LoadNotificationListTask(1);
                    }}>Очистити</Button>
                </FormRow.Col>
            </FormRow>
        </Form>
    </div>
});