import { makeAutoObservable } from 'mobx';

export const AddMultipleApplicationStore = makeAutoObservable({
    isDrawerOpen: false,
    forms: [],
    isSendRequest: false,
    setIsDrawerOpen( isDrawerOpen ) {
        this.isDrawerOpen = isDrawerOpen;
    },
    setForms( forms ) {
        this.forms = forms;
    },
    addForm( form ) {
        this.forms = [
            ...this.forms,
            form
        ];
    },
    removeForm( index ) {
        this.forms.splice( index, 1 );
    },
    setIsSendRequest( isSendRequest ) {
        this.isSendRequest = isSendRequest;
    }
});