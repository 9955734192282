import { makePersistable, stopPersisting } from 'mobx-persist-store';
import { makeAutoObservable, action } from 'mobx';
// import { RestClientService } from "@/Core/Services/Clients/RestClientService";

export class UserOptionStore {

  options = {};
  key = '';

  constructor({ key }) {
    this.key = key;
    makeAutoObservable(this);
    makePersistable(this, {
      storage: window.localStorage,
      name: `UserOptionStore${key ?? ''}`,
      properties: ['options']
    });
  }

  stopPersisting() {
    stopPersisting(this);
  }

  set( name, value ) {
    // this.options.set(name, value);
    this.options[name] = value;
    // TODO: !!!
    // RestClientService.post(`/core_api/settings/user/option/`, { json: {
    //   code: name,
    //   value
    // }});
  }

  get(name, defaultValue) {
    // const storedOption = this.options.get(name);
    const storedOption = this.options[name];

    if (storedOption !== undefined) {
      return storedOption;
    }

    return defaultValue;

    try {
      // TODO: !!!
      // RestClientService.get(`/core_api/settings/user/option/${name}`).then( response => {
      // });
      this.options[name] = null;
      // this.options.set(name, null);
    } catch(e) {
      action(() => {
        this.options[name] = null;
        // this.options.set(name, null);
      })();
    }

    return null;
  }
}