import { Alert, Button, message } from "antd";
import React from "react";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { Form, FormField } from "@/Core/UI/Form";
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

export const UserChangePassword = observer(() => {

    const crudContext = useCrudResourceContext();
    const originalItem = crudContext.formContext.values;
    const resourceClient = ServiceStore.get('resourceClient');
    const { t } = useTranslation();

    if( !originalItem?.id )
        return <Alert
            message={'Інформація'}
            description={<b>Для редагування пароля потрібно зберегти співробітника.</b>}
            type="info"
        />;

    return <Form
        name='user-change-password'
        onSubmit={({ values, formContext }) => {

            resourceClient.patch(
                'Company.User', 
                {
                    json: { plainPassword: values.password }
                }, 
                {
                    restUriReplaceParams: {
                        id: originalItem.id
                    }
                }
            )
            .getResponse()
            .then(() => {
                message.open({
                    type: 'success',
                    content: 'Пароль успішно оновлено.',
                });
            });
            
            formContext.reset();
        }}
        fields={{
            password: {
                label: 'Пароль',
                type: 'password',
                isRequired: true
            },
            confirm_password: {
                label: 'Повторити пароль',
                type: 'password',
                isRequired: true
            }
        }}
    >
        <FormField name='password' />
        <FormField name='confirm_password' />
        <Button type='primary' htmlType='submit'>Зберегти новий пароль</Button>
    </Form>
});