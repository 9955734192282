
import { Suspense } from 'react';
import { observer } from "mobx-react-lite";
import { Button, Layout, Space, Spin } from 'antd';
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai';
import { IoIosCloseCircleOutline } from "react-icons/io";
import { AiOutlineMenu } from "react-icons/ai";

import { useDefaultLayoutContext } from '../DefaultLayoutContextProvider';

import styles from './DefaultTemplate.module.css';

const { Header, Sider, Content } = Layout;

const DefaultTemplateObserver = observer(({
    mergedStyles,
    defaultLayoutContext,
    firstBarContent,
    headerContent,
    secondBarContent,
    mobileBarContent,
    outlet
}) => {
    // TODO: chunk-EKUBAEPU.js?v=0658912f:360 Warning: [antd: Menu] `inlineCollapsed` not control Menu under Sider. Should set `collapsed` on Sider instead.
    return <Layout className={mergedStyles['wl-default-layout']}>
        {
            defaultLayoutContext.isShowFirstBar && !defaultLayoutContext.isMobileMode
            && <Sider 
                    collapsed={defaultLayoutContext.isCollapsedFirstBar}
                    width={
                        defaultLayoutContext.isCollapsedFirstBar 
                            ? defaultLayoutContext.firstBarWidthCollapsed 
                            : defaultLayoutContext.firstBarWidthExpanded 
                    } 
                    theme='light'
                    className={`${mergedStyles['first-bar']}`}
                >
                    {firstBarContent}
                    <a
                        className={`${mergedStyles['wl-white-box']} ${mergedStyles['wl-collapced-button']}`}
                        onClick={() => defaultLayoutContext.setIsCollapsedFirstBar(!defaultLayoutContext.isCollapsedFirstBar)}
                    >
                        {defaultLayoutContext.isCollapsedFirstBar ? <AiOutlineRight /> : <AiOutlineLeft />}
                    </a>
                </Sider>
        }
        {defaultLayoutContext.isMobileMode && defaultLayoutContext.isOpenMobileFirstBar && <Sider 
            collapsed={false}
            width={defaultLayoutContext.firstBarWidthExpanded} 
            theme='light'
            className={`${mergedStyles['mobile-first-bar']}`}
        >
            {firstBarContent}
            <a
                className={`${mergedStyles['wl-white-box']} ${mergedStyles['wl-collapced-button']}`}
                onClick={() => defaultLayoutContext.setIsOpenMobileFirstBar(false)}
            >
                <IoIosCloseCircleOutline style={{ fontSize: 20 }} />
            </a>
        </Sider>}
        {
            defaultLayoutContext.isMobileMode 
            && defaultLayoutContext.isOpenMobileFirstBar 
            && <div 
                    className={`${mergedStyles['mobile-first-bar-background']}`}
                    onClick={() => {
                        defaultLayoutContext.setIsOpenMobileFirstBar(false)
                    }}
                ></div>
        }
        <Layout className={mergedStyles['content-bar']} style={{
            marginLeft: (
                defaultLayoutContext.isShowFirstBar && !defaultLayoutContext.isMobileMode
                    ? defaultLayoutContext.isCollapsedFirstBar 
                        ? defaultLayoutContext.firstBarWidthCollapsed
                        : defaultLayoutContext.firstBarWidthExpanded
                    : 0
            ),
            marginRight: (
                defaultLayoutContext.isShowSecondBar && !defaultLayoutContext.isMobileMode
                    ? defaultLayoutContext.secondBarWidth
                    : 0
            )
        }}>
            {defaultLayoutContext.isShowHeader && !defaultLayoutContext.isMobileMode && <Header className={`${mergedStyles['header']} ${mergedStyles['wl-white-box']}`}>
                {headerContent}
            </Header>}
            <Content className={`${mergedStyles['main']}`}>
                <Suspense fallback={<div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spin size="large" /></div>}>{outlet}</Suspense>
            </Content>
            {defaultLayoutContext.isMobileMode && <div className={`${mergedStyles['mobile-bar']}`}>
                <Space style={{ width: '100%', justifyContent: 'space-around' }}>
                    <Button 
                        type='link' 
                        onClick={() => {
                            defaultLayoutContext.setIsOpenMobileFirstBar(true)
                        }}
                    >
                        <AiOutlineMenu style={{ fontSize: 22, color: '#010101' }} />
                    </Button>
                    {mobileBarContent}
                </Space>
            </div>}
        </Layout>
        {defaultLayoutContext.isShowSecondBar && !defaultLayoutContext.isMobileMode && <Sider theme='light' width={defaultLayoutContext.secondBarWidth} className={`${mergedStyles['second-bar']}`}>
            {secondBarContent}
        </Sider>}
    </Layout>
});


export const DefaultTemplate = ({
    headerContent,
    firstBarContent,
    secondBarContent,
    mobileBarContent,
    outlet
}) => {

    const defaultLayoutContext = useDefaultLayoutContext();
    const customStyle = defaultLayoutContext?.customStyle ?? {};
    const mergedStyles = {
        ...styles,
        ...customStyle
    };
    
    return <DefaultTemplateObserver 
        mergedStyles={mergedStyles}
        defaultLayoutContext={defaultLayoutContext}
        firstBarContent={firstBarContent}
        headerContent={headerContent}
        secondBarContent={secondBarContent}
        mobileBarContent={mobileBarContent}
        outlet={outlet}
    />;
};