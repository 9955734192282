import { ListBaseReasonLosingFields } from "./ListBaseReasonLosingFields";
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListSaleReasonLosingResource = {
    title: 'Причини програшу продажів',
    fields: {
        ...ListBaseReasonLosingFields
    },
    restUri: '/core_api/settings/sale/reasons_losing',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};