import { Button as ButtonAntd } from "antd";
import { observer } from 'mobx-react-lite';

import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import { useDependency } from '../Dependency/useDependency';

export const Button = ({ depFn, depStore = {}, ...props }) => {

    depFn = useDependency({ depFn, depStore });
    
    const UI = new UIBaseStore({ depFn, ...props, template: ButtonAntd });

    const ObserverComponent = observer(() => UI.render());

    return <ObserverComponent />;
};