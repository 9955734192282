import { Form, FormField, FormRow } from "@/Core/UI/Form";
import { Tabs } from "@/Core/UI/Tabs";
import { Dependency } from "@/Core/UI/Dependency";
import { StatusBarDetail } from "../Components/StatusBarDetail";
import { ApplicationCrud } from "../../ApplicationContainer/Components/ApplicationCrud";
import { PriceCurrencyField } from "@/Ship/Components/PriceCurrencyField";
import { ChangesList } from '../Components/ChangesList';
import { UserStore } from "@/Core/Stores/UserStore";
import { PurchaseDocumentCrud } from '../Components/PurchaseDocumentCrud';

export const PurchaseFormTemplate = ({ formContext }) => {
    
    const tabItems = [
        {
            label: 'Загальне',
            key: 'general',
            children: <>
                <FormRow>
                    <FormRow.Col>
                        <Form formContext={formContext}>
                            <FormField name='contractNumber' />
                            <FormField name='isSigned' />
                            <FormField name='isDocumentsReceived' />
                            <FormField name='isDocumentsSended' />
                            <FormField name='responsible' />
                            <FormField name='dateContract' />
                            <FormField name='deadline' />
                            <FormField name='supplier' />
                            <FormField name='organization' />
                            <FormField name='product' />
                            <FormField name='quantity' />
                            <PriceCurrencyField 
                                priceFieldName='price' 
                                currencyFieldName='priceCurrency' 
                            />
                            <FormField name='termDelivery' />
                            <FormField name='termPayment' />
                            <FormField name='comment' />
                        </Form>
                    </FormRow.Col>
                    <FormRow.Col>
                        <Dependency depFn={({ crudContext }) => !!crudContext.formContext.values?.id }>
                            {() => (<div style={{ marginBottom: 15 }}>
                                <ApplicationCrud 
                                    isFilter={false}
                                    prefilter={{
                                        purchase: formContext.values['@id']
                                    }}
                                    form={{
                                        initialValues: {
                                            purchase: formContext.values,
                                            supplier: formContext.values.supplier,
                                            responsible: UserStore.user,
                                            product: formContext.values.product,
                                            termDelivery: formContext.values.termDelivery,
                                            price: formContext.values.price,
                                            priceCurrency: formContext.values.priceCurrency
                                        }
                                    }}
                                />
                            </div>)}
                        </Dependency>
                        <Form formContext={formContext}>
                            <FormField name='salesRemainder' />
                            <FormField name='reservedSales' />
                            <FormField name='optionPercentage' />
                            <PriceCurrencyField 
                                priceFieldName='contractAmount' 
                                currencyFieldName='contractAmountCurrency' 
                            />
                            <FormField name='amountUAH' />
                            <FormField name='paidUAH' />
                            <FormField name='percentagePayment' />
                            <FormField name='completion' />
                            <FormField name='percentageCompletion' />
                            <FormField name='remainder' />
                            <FormField name='reasonLosing' />
                            <FormField name='isExecutionConfirmed' />
                            <FormField name='isCalculationConfirmed' />
                        </Form>
                    </FormRow.Col>
                </FormRow>
            </>
        },
        {
            label: 'Друковані форми',
            key: 'documents',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <PurchaseDocumentCrud />
        },
        {
            label: 'Історія змін',
            key: 'events',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <ChangesList />
        }
    ];

    return <>
        <StatusBarDetail />
        <Tabs items={tabItems} />
    </>
};