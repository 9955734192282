import ky from 'ky';
import { action, computed, makeObservable, observable } from 'mobx';

export class HTTPResponseStore {

    response = null;
    content = null;
    error = null;

    isLoading = true;
    isOriginalResponse = false;

    requestClient = null;
    requestData = {};
    method = null;

    constructor({ response, isOriginalResponse, requestClient, requestData, method }) {

        makeObservable(this, {
            content: observable,
            error: observable,
            isLoading: observable,
            isError: computed,
            setContent: action,
            setError: action,
            setIsLoading: action
        });

        if( isOriginalResponse === true )
            this.isOriginalResponse = true;

        if( response )
            this.setResponse(response);

        if( requestClient )
            this.requestClient = requestClient;

        if( requestData )
            this.requestData = requestData;

        if( method )
            this.method = method;
    }

    setContent( content ) {
        this.content = content;
    }

    setError( error ) {
        this.error = error;
    }

    get isError() {
        return this.error === null ? false : true;
    }

    setIsLoading( isLoading ) {
        this.isLoading = isLoading;
    }

    setResponse( response ) {
        if( response instanceof Promise ) {
            
            if( !this.isOriginalResponse )
                response = response.then( res => res.json());
            
            this.response = response
                .then( res => {
                    
                    this.setContent( res );
                    
                    if( this.method == ky.get ) {
                        this.requestClient.cacheResponse.set(
                            this.requestClient.getRequestKey({ ...this.requestData }),
                            this
                        );
                        setTimeout(() => {
                            if( this.requestClient.cacheResponse.has(this.requestClient.getRequestKey({ ...this.requestData })))
                                this.requestClient.cacheResponse.delete(this.requestClient.getRequestKey({ ...this.requestData }))
                        }, 2000);
                    }
                    return res;
                } )
                .catch( async (error) => {
                    if( error.name === 'AbortError' )
                        return;

                    if ( error.response ) {

                        const clonedResponse = error.response.clone();
                  
                        try {
                          const errorData = await clonedResponse.json();
                          this.setError( errorData['hydra:description'] );

                        } catch (e) {
                          console.error('Error trying to read JSON from the response body', e);
                        }
                    }

                    throw error;
                } )
                .finally(() => {
                    
                    this.setIsLoading( false );

                    if( 
                        this.requestClient 
                        && this.requestData?.url 
                        && this.requestClient.runningRequest.has(
                            this.requestClient.getRequestKey({ ...this.requestData })
                        ) 
                    ) {
                        this.requestClient.runningRequest.delete( 
                            this.requestClient.getRequestKey({ ...this.requestData }) 
                        );
                    }
                });
        }
    }

    getResponse() {
        return this.response;
    }
}