import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListLegalResource = {
    title: 'Юридичні особи компанії',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        fullName: {
            label: 'Повна назва'
        },
        formTaxation: {
            label: 'Форма оподаткування',
            isRequired: true,
            type: 'select',
            resource: 'ListFormTaxation'
        },
        address: {
            label: 'Адреса'
        },
        iban: {
            label: 'Рахунок IBAN'
        },
        bank: {
            label: 'Банк рахунку'
        },
        mfo: {
            label: 'МФО банку рахунку'
        },
        edrpou: {
            label: 'Код ЄДРПОУ'
        },
        numberVAT: {
            label: 'Номер витягу платника ПДВ'
        },
        ipn: {
            label: 'ІПН'
        },
        manager: {
            label: 'ПІБ директора'
        },
        onBasis: {
            label: 'Діє на підставі'
        },
        email: {
            label: 'E-mail'
        },
        phone: {
            label: 'Телефон'
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        }
    },
    restUri: '/core_api/settings/legal_entities',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};