import { toJS } from "mobx";
import { UserStore } from "@/Core/Stores/UserStore";

export const WarehouseServiceResource = {
    title: 'Послуги складів',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        code: {
            label: 'Код',
            isRequired: true
        },
        accrualType: {
            label: 'Тип нарахування',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListAccrualType'
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        }
    },
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    },
    restUri: '/core_api/company/warehouse/services'
};