// @vite-ignore
// import MaskedInputOriginal  from "antd-mask-input";
import { observer } from 'mobx-react-lite';

export const MaskedInput = observer(({ field, index }) => {

    if( field === undefined )
        return null;

    if( !field.inputProps?.mask ) {
        console.warn(`Mask type field don't have mask property. Field path: ${field.path.join('.')}`);
        return;
    }

    return <div className="antd-mask-input">
        <MaskedInputOriginal {...field.bind({ index: index })} />
    </div>
});