import { makeAutoObservable, runInAction } from 'mobx';

export class ButtonGroupStore {

    originalButtons = [];
    buttons = [];
    dropdownButtons = [];

    constructor() {
        makeAutoObservable(this);
    }

    setOriginalButtons(buttons) {
        if( typeof buttons === 'object' ) {
            Object.keys(buttons).map( key => {
                buttons[key].key = key;
                if( !buttons[key]?.priority )
                    buttons[key].priority = 'button';
                if( !buttons[key]?.sort )
                    buttons[key].sort = 100;
            });

            this.originalButtons = Object.values(buttons).sort((a, b) => a.sort - b.sort);
            this.updateButtons();
        }
    }

    updateButtons() {
        const originalButtons = this.originalButtons.map(({depFn, depStore, sort, ...button }) => button);
        this.buttons = originalButtons.filter(btn => btn.priority === 'button');
        this.dropdownButtons = originalButtons.filter(btn => btn.priority === 'dropdown');
    }

    adjustButtons(visibleCount) {
        runInAction(() => {
            const originalButtons = this.originalButtons.map(({depFn, depStore, sort, ...button }) => button);
            const primaryButtons = this.originalButtons.filter(btn => btn.priority === 'button');
            const dropdownOnlyButtons = this.originalButtons.filter(btn => btn.priority === 'dropdown');

            if (visibleCount >= primaryButtons.length) {
                this.buttons = primaryButtons;
                this.dropdownButtons = dropdownOnlyButtons;
            } else {
                this.buttons = primaryButtons.slice(0, visibleCount);
                this.dropdownButtons = [...primaryButtons.slice(visibleCount), ...dropdownOnlyButtons];
            }
        });
    }
}