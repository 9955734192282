import { GetCollectionRequestParamsTask } from "@/Core/Tasks/Resources/GetCollectionRequestParamsTask";
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { toJS } from 'mobx';
import { ListContextProvider, createListContext } from './ListContextProvider';
import { DeepCloneTask } from "@/Core/Tasks/Utils/DeepCloneTask";
import { useCrudResourceContext } from '../CrudResource/CrudResourceContextProvider';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { MergeConfigAndPropsTask } from '@/Core/Tasks/Utils/MergeConfigAndPropsTask';
import { observer } from "mobx-react-lite";

export const List = (({ 
    queryUri, queryKey, queryParams,
    listContext,
    listContextProps,
    queryParamsTransformer, 
    children, 
    fields, 
    onReload,
    ...listProps 
}) => {

    listContextProps ??= {};
    
    const crudContext = useCrudResourceContext();
    const listConfig = ConfigStore.get('ListConfig');
    const restClient = ServiceStore.get('restClient');

    if( !Array.isArray(queryKey) ) {
        console.warn('List component, param "queryKey" must be array.');
        return null;
    }

    if( !queryUri ) {
        console.warn('List component, param "queryUri" is empty.');
        return null;
    }

    fields = fields ?? {};

    if( !listContext ) 
        listContext = createListContext({
            ...( typeof listConfig === 'object' ? listConfig : {} ),
            ...listContextProps,
            prefilter: crudContext.prefilter ?? {}
        });
    
    const props = MergeConfigAndPropsTask({
        listConfig, listProps
    });

    if( typeof props === 'object' )
        listContext.mergeToInstance({ ...props });

    const paramsTransformer = typeof queryParamsTransformer === 'function' 
                                ? queryParamsTransformer 
                                : GetCollectionRequestParamsTask;

    const loadData = () => {
        
        const loader = {
            isLoading: true,
            items: []
        };
    
        listContext.setLoader( loader );

        queryParams = {
            sorter: toJS(listContext.sorter),
            filter: toJS(listContext.fullFilter),
            pagination: toJS(listContext.pagination)
        };

        queryParams = paramsTransformer(queryParams);
        
        restClient
        .get( queryUri, { searchParams: queryParams } )
        .getResponse()
        .then( data => {

            if(data) {

                listContext.eventManager.run('onAfterRequest', { data, listContext });

                let items = data['hydra:member'];
                
                listContext.eventManager.run('onAfterRequest', { data, listContext });

                for( let i = 0; i < items.length; i++ ) {
                    listContext.eventManager.run('onItem', { item: items[i], data, listContext });
                }
                
                listContext.setPagination({
                    ...listContext.pagination, 
                    total: data['hydra:totalItems']
                });

                loader.items = toJS(items);
                loader.isLoading = false;
                listContext.setLoader(loader);

                if( typeof onReload === 'function' )
                    onReload({ items, loadData });
            }
        });
    };

    loadData();

    listContext.setFields( fields );
    listContext.setActions({
        reload: () => {
            if( 
                crudContext.mode === 'kanban' 
                && typeof crudContext?.kanbanLoad === 'function' 
            ) {
                crudContext.kanbanLoad(toJS(listContext.fullFilter));
            }
            else if( 
                crudContext.mode === 'calendar' 
                && typeof crudContext?.calendarLoad === 'function' 
            ) {
                crudContext.calendarLoad(toJS(listContext.fullFilter));
            }
            else
                loadData();
        },
        loadNextPage: () => {},
        loadPrevPage: () => {}
    });
    
    listContext.eventManager.run('onAfterListBuild', { listContext });

    return <ListContextProvider value={listContext}>{children}</ListContextProvider>;
});