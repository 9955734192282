import dayjs from "dayjs";
import { AppConfig } from "@/Ship/Configs/AppConfig";
import i18n from '@/Core/i18n';
import { GetResourceCodeByDetailUri } from '@/Core/Tasks/Resources/GetResourceCodeByDetailUri';
import { CheckNodeHelperTask } from "@/Core/Tasks/Utils/CheckNodeHelperTask";

const TransformFn = ( field, value, defaultLayoutContext ) => {

    let dateObj = null;
    
    switch( field.type ) {
        case 'date':
            dateObj = new dayjs( value );
            value = dateObj.format( AppConfig?.dateDefaultFormat ?? '' );
            break;
        case 'datetime':
            dateObj = new dayjs(value);
            value = dateObj.format(AppConfig?.datetimeDefaultFormat ?? '');
            break;
        case 'switch':
            value = value === true ? i18n.t("Yes", { ns: 'Core' }) : i18n.t("No", { ns: 'Core' });
            break;
        case 'color':
            if( value.indexOf('#') !== -1 )
                value = <div style={{ padding: 1, border: '1px solid #d9d9d9', borderRadius: 8, width: 29, height: 29 }}>
                    <span style={{ display: 'block', backgroundColor: value, width: 25, height: 25, borderRadius: 8 }}></span>
                </div>

            break;
        default: break;
    }

    if( 
        field.type === 'select'
        && typeof value === 'string'
        && Array.isArray(field.options)
    ) {
        field.options.map( option => {
            if( option.value == value )
                value = option.label;
        })
    }

    if ( 
        field.type === 'select' 
        && typeof field.resource === 'string'
        && typeof value === 'object'
        && field?.isLink === true
        && !Array.isArray( value )
    ) {
        
        let linkTitle = '';
        if( value?.lastName !== undefined )
            linkTitle = `${value.lastName ?? ''} ${value.name ?? ''}`;
        else if( value?.title !== undefined )
            linkTitle = value.title;
        else if( value?.name !== undefined )
            linkTitle = value.name;
        else
            linkTitle = '-';
        
        const resourceCode = GetResourceCodeByDetailUri( value['@id'] );
        
        if( resourceCode ) {
            return <a href='#' onClick={() => {
                defaultLayoutContext.openResource({
                    resourceCode,
                    resourceId: value.id,
                    mode: 'view'
                });
            }}>{linkTitle}</a>
        }
        
    }

    if( typeof value === 'object' && !CheckNodeHelperTask.isElement(value)) {
        if( value?.lastName !== undefined )
            value = `${value.lastName ?? ''} ${value.name ?? ''}`;
        else if( value?.title !== undefined )
            value = value.title;
        else if( value?.name !== undefined )
            value = value.name;
        else
            value = '-';
    }

    return value;
} 

export const TransformItemDataByDefaultFeature = {
    eventHandlers: {
        onBuildDataSourceItem: {
            TransformItemDataByDefaultItem: ({ item, tableContext, crudContext, defaultLayoutContext }) => {
                Object.keys(item).map( key => {
        
                    if( 
                        ( typeof tableContext.props.dataSourceNoTransform === 'boolean' && tableContext.props.dataSourceNoTransform === true )
                        || (Array.isArray( tableContext.props.dataSourceNoTransform ) && tableContext.props.dataSourceNoTransform.includes( key )) 
                        || key === '_original'
                    ) {
                        return;
                    }

                    if( 
                        Array.isArray(tableContext.props.showColumns) 
                        && tableContext.props.showColumns.length > 0 
                        && !tableContext.props.showColumns.includes( key )
                    ) {
                        return;
                    }

                    const field = crudContext?.resource?.fields[key];
                    
                    if( field && item[key] !== undefined && item[key] !== null && item[key] !== '' ) {

                        if( !Array.isArray(item[key]) ) {
                            item[key] = TransformFn( field, item[key], defaultLayoutContext );
                        }
                        else {
                            item[key] = item[key].map( value => TransformFn( field, value, defaultLayoutContext ) );
                            item[key] = item[key].join(', ');
                        }

                        return;
                    }

                    if( item[key] !== undefined && item[key] !== null && item[key] !== '' ) {
                        if( !Array.isArray(item[key]) ) {
                            if( typeof item[key] === 'object' ) {
                                if( item[key]?.lastName !== undefined )
                                    item[key] = `${item[key].lastName ?? ''} ${item[key].name ?? ''}`;
                                else if( item[key]?.title !== undefined )
                                    item[key] = item[key].title;
                                else if( item[key]?.name !== undefined )
                                    item[key] = item[key].name;
                                else
                                    item[key] = '-';
                            }
                        }
                        else if(Array.isArray(item[key])) {
                            let result = [];
                            item[key].forEach( value => {
                                if( typeof value === 'object' ) {
                                    if( value?.lastName !== undefined )
                                        result.push(`${value.lastName ?? ''} ${value.name ?? ''}`);
                                    else if( value?.title !== undefined )
                                        result.push(value.title);
                                    else if( value?.name !== undefined )
                                        result.push(value.name);
                                    else
                                        result.push('-');
                                }
                            });
                            item[key] = result.join(', ');
                        }
                    }
                });
            }
        }
    }
}