import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { UserStore } from '@/Core/Stores/UserStore';

import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { useFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';
import { useHeaderPageContext } from '@/Core/UI/HeaderPage/HeaderPageContextProvider';
import { useListContext } from '@/Core/UI/List/ListContextProvider';
import { useDrawerContext } from "@/Core/UI/Drawer/DrawerContextProvider";

export const useDependency = ({ depFn, depStore = {} }) => {

    const formContext = useFormContext();
    const crudContext = useCrudResourceContext();
    const listContext = useListContext();
    const drawerContext = useDrawerContext();
    const headerPageContext = useHeaderPageContext();
    const config = ConfigStore.getAll();

    if( typeof depFn !== 'function' )
        return (() => {});

    return ((args = {}) => {       
        return depFn({
            crudContext,
            formContext,
            drawerContext,
            listContext,
            headerPageContext,
            config,
            UserStore,
            ...depStore,
            ...args
        })
    });
}