import { createContext, useContext } from 'react';
import { DefaultLayoutStore } from './Stores/DefaultLayoutStore';

export const DefaultLayoutContext = createContext();

export const createDefaultLayoutContext = (props) => {
    props ??= {};
    return new DefaultLayoutStore({ ...props });
}

export const useDefaultLayoutContext = () => useContext( DefaultLayoutContext );

export const DefaultLayoutContextProvider = ({ value, children }) => <DefaultLayoutContext.Provider value={value}>
    {children}
</DefaultLayoutContext.Provider>