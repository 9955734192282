
import { FormField } from "./FormField";
import { 
    FormContextProvider, 
    createFormContext, 
    useFormContext 
} from "../Providers/FormContextProvider";

import { useFormComposerContext } from '../Providers/FormComposerContextProvider';

export const Form = ({ children, formContext, ...props }) => {

    let formContextProvider = formContext ?? createFormContext({ ...props });
    const formComposerContext = useFormComposerContext();

    if( !formContextProvider.name && props?.name )
        formContextProvider.setName( props.name );

    const FormTemplate = () => {
        // TODO: !!! formContext.fields
        const formContext = useFormContext();

        if( typeof children === 'function' ) 
            return children({ fields: formContext.fields });

        if( children !== undefined )
            return children;
    
        let defaultTemplate = null;

        if( typeof formContext.fields === 'object') {
            defaultTemplate = [];
            Object.keys(formContext.fields).map( key => {
                defaultTemplate.push(<FormField key={key} name={key} />);
            });
        }
    
        return defaultTemplate;
    }

    if( formComposerContext && formContextProvider?.name ) {
        formComposerContext.setFormContext( formContextProvider.name, formContextProvider );
    }

    return <FormContextProvider value={ formContextProvider }>
        <form onSubmit={(e) => {
            e.preventDefault();
            formContextProvider.submit();
        }}>
            <FormTemplate />
        </form>
    </FormContextProvider>
}