import { TbTable } from "react-icons/tb";
import { BiEdit, BiShowAlt, BiFilterAlt } from 'react-icons/bi';
import { AiOutlineDelete, AiOutlineAppstoreAdd } from 'react-icons/ai';

import { observer } from "mobx-react-lite";
import { Popconfirm, theme, Tooltip, Segmented } from 'antd';

import { Table } from '@/Core/UI/Table';
import { Button } from "@/Core/UI/Button";

import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import i18n from '@/Core/i18n';
import { toJS } from 'mobx';
import { useDefaultLayoutContext } from "../../../Layouts/DefaultLayout/DefaultLayoutContextProvider";
const { useToken } = theme;

export const AddDrawerModeCrudFeature = {
    headerPage: {
        eventHandlers: {
            onButtons: {
                AddButton: ({ buttons, headerPageContext, crudContext }) => {                    
                    buttons.AddButton = {
                        label: crudContext.settings?.addButtonLabel ?? i18n.t("Add", { ns: 'Core' }), 
                        icon: <AiOutlineAppstoreAdd style={{ fontSize: 24 }} />, 
                        sort: 100, 
                        priority: 'button',
                        size: 'middle',
                        type: 'primary',
                        depFn: ({ crudContext }) => crudContext.accessManager.isCan('create') === true,
                        style: { display: 'flex', alignItems: 'center', position: 'relative' },
                        onClick: () => crudContext.actions.create()
                    };
                }
            },
            beforeInit: {
                SetTitleFromResource: ({ headerPageContext }) => {

                    if( !headerPageContext?.title && headerPageContext?.storages?.resource?.title )
                        headerPageContext.title = headerPageContext?.storages?.resource?.title;
                },
                SetDocumentTitle: ({ headerPageContext }) => {
                    document.title = headerPageContext?.title  ?? '';
                },
                PushFilterButtonToTitleExtra: ({ headerPageContext }) => {

                    const crudContext = useCrudResourceContext();
                    const { token } = useToken();
                
                    if( !crudContext )
                        return;
                
                    headerPageContext.titleExtra ??= [];
                
                    headerPageContext.titleExtra.push(
                        <Button 
                            key='crudresource-filterbutton-headerpage'
                            depFn={ ({ crudContext }) => crudContext.isFilter && !crudContext.isFilterOpen && crudContext.mode === 'table' && !crudContext.isMobileMode }
                            type="link" 
                            onClick={() => crudContext.setIsFilterOpen(true)}
                            icon={<BiFilterAlt style={{ fontSize: 24, color: token.colorPrimary }} />}
                        />
                    );

                    headerPageContext.titleExtra.push(
                        <Button 
                            key='crudresource-filterbutton-headerpage-mobile'
                            depFn={ ({ crudContext }) => crudContext.isFilter && crudContext.mode === 'table' && crudContext.isMobileMode }
                            type="link" 
                            onClick={() => crudContext.setIsOpenMobileFilter(true)}
                            icon={<BiFilterAlt style={{ fontSize: 24, color: token.colorPrimary }} />}
                        />
                    );
                },
                PushModeListToExtra: ({ headerPageContext }) => {

                    const crudContext = useCrudResourceContext();
                    const defaultLayoutContext = useDefaultLayoutContext();

                    if( !crudContext )
                        return;
                    
                    headerPageContext.afterExtra ??= [];
                
                    if( Array.isArray(Object.keys(crudContext?.modeItems)) && Object.keys(crudContext?.modeItems).length > 1 ) {
                
                        let options = [];
                
                        Object.keys(crudContext.modeItems).map( key => {

                            const item = toJS(crudContext.modeItems[key]);
                            const Icon = item.icon ?? (() => {});
                            
                            options.push({
                                key,
                                label: null,
                                value: key,
                                icon: <Tooltip placement="top" title={item.label}>
                                    <div style={{position: 'relative', top: 5}}><Icon style={{ fontSize: 24 }} /></div>
                                </Tooltip>
                            });
                        });
                
                        // TODO: !!!
                        options = options.reverse();
                
                        const ModeListObserver = observer(() => {

                            if( defaultLayoutContext.isMobileMode ) {
                                if( crudContext.mode !== 'table' )
                                    crudContext.setMode('table');
                                return;
                            }

                            return <Segmented 
                                value={crudContext.mode} 
                                size='middle' 
                                options={options} 
                                onChange={ value => {
                                    crudContext.setMode(value);
                                }} 
                            />
                        });
                
                        headerPageContext.afterExtra.push(<div key='crudresource-modelist-headerpage'><ModeListObserver /></div>);
                    }
                }
            }
        }
    },
    eventHandlers: {
        afterInit: {
            AddTableViewModeToList: ({ crudContext }) => {

                let tableProps = crudContext.settings?.AddTableViewToListTransformer?.table ?? {};

                const menuItemsFc = ({ item }) => {
            
                    const menuItems = [];
            
                    if( crudContext.accessManager.isCan('read') && crudContext.actions?.view )
                        menuItems.push({
                            icon: <BiShowAlt style={{fontSize: 18}} />,
                            label: crudContext.settings?.AddDrawerModeCrudFeature?.menuViewItemText ?? i18n.t("View", { ns: 'Core' }),
                            onClick: () => crudContext.actions.view(item),
                            key: 'view',
                        });
                    
                    if( crudContext.accessManager.isCan('update', item) && crudContext.actions?.edit )
                        menuItems.push({
                            icon: <BiEdit style={{fontSize: 18}} />,
                            onClick: () => crudContext.actions.edit(item),
                            label: crudContext.settings?.AddDrawerModeCrudFeature?.menuEditItemText ?? i18n.t("Edit", { ns: 'Core' }),
                            key: 'edit',
                        });
                
                    if( crudContext.accessManager.isCan('delete', item) && crudContext.actions?.delete )
                        menuItems.push({
                            icon: <AiOutlineDelete style={{fontSize: 18}} />,
                            danger: true,
                            label: <Popconfirm
                                title={crudContext.settings?.AddDrawerModeCrudFeature?.menuDeleteItemText ?? i18n.t("Delete", { ns: 'Core' })}
                                description={crudContext.settings?.AddDrawerModeCrudFeature?.menuDeleteConfirmItemText ?? i18n.t("Do you really want to delete?", { ns: 'Core' })}
                                onConfirm={() => crudContext.actions.delete(item.id)}
                                okText={i18n.t("Yes", { ns: 'Core' })}
                                cancelText={i18n.t("No", { ns: 'Core' })}
                            >
                                {crudContext.settings?.AddDrawerModeCrudFeature?.menuDeleteItemText ?? i18n.t("Delete", { ns: 'Core' })}
                            </Popconfirm>,
                            key: 'delete',
                        });
                    
                    return menuItems;
                }
            
                tableProps.storages ??= {};
                tableProps.storages.AddActionsMenuDataSourceItemTransformer ??= {};
                tableProps.storages.AddActionsMenuDataSourceItemTransformer.menuItems ??= menuItemsFc;
            
                if( crudContext.accessManager.isCan('read') && crudContext.actions?.view )
                    tableProps.onRow = record => ({
                        onDoubleClick: () => crudContext.actions.view(record),
                    });
            
                tableProps.onChange = ( props ) => {
                    crudContext.listContext.setCurrentPage( props.current );
                    crudContext.listContext.actions.reload();
                };
            
                if( typeof crudContext.props?.table === 'object' ) {
                    tableProps = { ...tableProps, ...crudContext.props?.table };
                }
            
                if( 
                    crudContext !== undefined
                    && typeof crudContext.resource.fields === 'object' 
                    && Object.keys(crudContext.resource.fields).length > 0
                    && !tableProps?.columns
                ) {
                    let columns = [];
                    Object.keys(crudContext.resource.fields).map( name => {
                        const field = crudContext.resource.fields[name];
                        let column = { 
                            // TODO: !!!
                            title: <div style={{ whiteSpace: 'nowrap' }}>{field?.label}</div> ?? '',
                            dataIndex: name,
                            key: name
                        };
                        columns.push( column );
                    });
                    tableProps.columns = columns;
                }
            
                const tableModeItem = {
                    label: i18n.t("Table", { ns: 'Core' }),
                    icon: TbTable,
                    component: Table,
                    props: tableProps
                };
    
                crudContext.setModeItems({
                    ...crudContext.modeItems,
                    table: tableModeItem
                });
                
                if( crudContext.mode === undefined ) {
                    crudContext.setMode('table');
                }
            }
        }
    }
};