import i18n from '@/Ship/i18n';
import { UserStore } from "@/Core/Stores/UserStore";

export const DepartmentResource = {
    title: i18n.t("Company structure"),
    key: 'Department',
    fields: {
        title: {
            label: i18n.t("Name"),
            isRequired: true
        },
        parent: {
            label: i18n.t("Department"), 
            type: 'select',
            resource: 'Company.Department',
            inputProps: { disabled: true }
        },
        chief: {
            label: i18n.t("Head"),
            isRequired: true, 
            type: 'select',
            resource: 'Company.User',
            inputProps: {
                searchBy: ['lastName', 'name']
            }
        }
    },
    restUri: '/core_api/company/departments',
    accessFn: (mode) => {
        const roles = UserStore.user?.roles ?? [];

        const rules = {
            create: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false,
            read: true,
            update: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false,
            delete: roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_USER') ? true : false
        };

        if( rules[mode] !== undefined )
            return rules[mode];

        return false;
    }
};