import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { Badge, Skeleton, Space } from "antd";
import { KanbanStore } from './Stores/KanbanStore';

import {
  DndContext,
  DragOverlay,
  MeasuringStrategy,
} from '@dnd-kit/core';

import {
  SortableContext,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';

import { observer } from "mobx-react-lite";
import { SortableItem } from './Components/SortableItem';
import { onDragStartHandler } from './Handlers/onDragStartHandler';
import { onDragOverHandler } from './Handlers/onDragOverHandler';
import { onDragEndHandler } from './Handlers/onDragEndHandler';
import { TRASH_ID, dropAnimation, getIndex } from './functions';
import { onDragCancelHandler } from './Handlers/onDragCancelHandler';
import { DroppableContainer } from './Components/DroppableContainer';
import { ItemTemplate } from './Templates/ItemTemplate';
import { DragItemTemplate } from './Templates/DragItemTemplate';
import { useDroppable } from '@dnd-kit/core';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';

function Trash({id}) {
  const {setNodeRef, isOver} = useDroppable({
    id,
  });

  return (
    <div
      ref={setNodeRef}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        left: '50%',
        marginLeft: -150,
        bottom: 20,
        width: 300,
        height: 60,
        borderRadius: 5,
        border: '1px solid',
        borderColor: isOver ? 'red' : '#DDD',
      }}
    >
      Drop here to delete
    </div>
  );
}

const MultipleContainersObserver = observer(function MultipleContainers({
  columns, // ?
  containerStyle, // ?
  minimal = false, // +
  renderItem = (() => {}),
  onLoad
}) {

  const isSortingContainer = KanbanStore.activeId ? KanbanStore.containers.includes(KanbanStore.activeId) : false;

  useEffect(() => {
    if( typeof onLoad === 'function' ) {
      onLoad({ KanbanStore });
    }
  }, []);

  if( KanbanStore.isLoading )
    return <Skeleton active={true} />;

  return (
    <DndContext
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always,
        },
      }}
      onDragStart={onDragStartHandler}
      onDragOver={onDragOverHandler}
      onDragEnd={onDragEndHandler}
      onDragCancel={onDragCancelHandler}
    >
      <div
        style={{ display: 'flex' }}
      >
        <SortableContext
          items={[...KanbanStore.containers]}
          strategy={ horizontalListSortingStrategy }
        >
          {KanbanStore.containers.map((containerId) => {
            return (
              <DroppableContainer
                key={containerId}
                id={containerId}
                label={<Space><span style={{fontSize: 20}}>{KanbanStore.containersData[containerId].title}</span><Badge count={KanbanStore.containersData[containerId].total} showZero color={KanbanStore.containersData[containerId].color} /></Space>}
                columns={columns}
                items={KanbanStore.items[containerId]}
                style={containerStyle}
                unstyled={minimal}
              >
                <SortableContext items={KanbanStore.items[containerId]} key={`sk-${containerId}`}>
                  {KanbanStore.items[containerId].map(( value, index ) => {
                    return (
                      <SortableItem
                        disabled={isSortingContainer}
                        key={value}
                        id={value}
                        index={index}
                        handle={false}
                        containerId={containerId}
                        renderItem={renderItem}
                        getIndex={getIndex}
                      />
                    );
                  })}
                </SortableContext>
              </DroppableContainer>
            )
          })}
        </SortableContext>
      </div>
      {createPortal(
        <DragOverlay dropAnimation={dropAnimation}>
          {KanbanStore.activeId
            ? KanbanStore.containers.includes(KanbanStore.activeId)
              ? <ItemTemplate containerId={KanbanStore.activeId} columns={columns} />
              : <DragItemTemplate id={KanbanStore.activeId} />
            : null}
        </DragOverlay>,
        document.body
      )}
      {KanbanStore.activeId && !KanbanStore.containers.includes(KanbanStore.activeId) ? (
        <Trash id={TRASH_ID} />
      ) : null}
    </DndContext>
  );
});

export const Kanban = ({ onLoad, renderItem }) => {

  const crudContext = useCrudResourceContext();

  if( typeof crudContext?.kanbanLoad !== 'function' )
    crudContext.kanbanLoad = () => onLoad({ KanbanStore });

  // useEffect(() => {
  //   if( typeof onLoad === 'function' ) {
  //     onLoad({ KanbanStore });
  //     // crudContext.formContext.eventManager
  //   }
  // }, []);

  return <MultipleContainersObserver onLoad={onLoad} renderItem={renderItem} />;
}