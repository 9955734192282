import { Form, FormField } from "@/Core/UI/Form";

export const PurchaseDocumentFormTemplate = ({ formContext }) => {
    return <Form formContext={formContext}>
        <FormField name='type' />
        <FormField name='title' />
        <FormField name='number' />
        <FormField name='date' />
        <FormField name='wordFile' />
        <FormField name='pdfFile' />
    </Form>
}