import { theme } from 'antd';

// Show more details - https://ant.design/components/config-provider#api
export const AntdConfig = {
    componentSize: 'large',
    theme: {
        token: {
            colorPrimary: '#00246A',
            colorLink: '#00246A'
        },
        cssVar: true,
        // algorithm: theme.darkAlgorithm,
    }
};