import { Tabs as TabsAntd } from "antd";
import { UserStore } from "@/Core/Stores/UserStore";
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { useFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';
import { observer } from 'mobx-react-lite';

export const Tabs = observer(({
    depFn = null,
    items = [],
    ...props
}) => {

    const crudContext = useCrudResourceContext();
    let formContext = useFormContext();
    
    if( !formContext && crudContext?.formContext )
        formContext = crudContext.formContext;
    
    if( typeof depFn === 'function' && !depFn({ UserStore, crudContext, formContext }) )
        return null;

    items = items.filter(({ depFn }) => (
        ( typeof depFn !== 'function' )
        || ( typeof depFn === 'function' && depFn({ UserStore, crudContext, formContext }) )
    ));
    
    /*
    if( typeof props?.renderTabBar !== 'function' ) {
        // activeKey={activeKey} onChange={setActiveKey}
        const tabStore = makeAutoObservable({
            activeKey: null,
        });
    
        const CustomTabBar = ({ tabs, activeKey, onChange, ...props }) => {
    
            const Tab = observer(({tab, index}) => {
                if( !tabStore.tabs[tab.key] )
                    return null;
    
                return props.panes[index]
            });
    
            return (<div>{tabs.map((tab, index) => <Tab tab={tab} index={index} />)}</div>);
        };
        props.renderTabBar = (props, DefaultTabBar) => {
            return <CustomTabBar {...props} tabs={items} onChange={handleChange} />
        }
    }  
    */
    return <TabsAntd items={items} {...props} />;
});