import { action, makeObservable, observable } from "mobx";
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';

export class DrawerStore extends UIBaseStore {

    extra = [];
    title = false;
    isOpen = false;

    constructor({
        title,
        extra,
        isOpen,
        ...props
    }) {

        super(props);

        if( title )
            this.title = title;

        if( extra )
            this.extra = extra;

        if( typeof isOpen === 'boolean' )
            this.isOpen = isOpen;

        makeObservable( this, {
            isOpen: observable,
            setIsOpen: action
        });
    }

    setIsOpen( isOpen ) {
        if( typeof isOpen === 'boolean' )
            this.isOpen = isOpen;
    }
}