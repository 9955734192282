export const ApplicationExpenseResource = {
    title: 'Витрати на транспорт',
    fields: {
        entity: {
            label: 'Заявка',
            type: 'select',
            resource: 'Purchase.Application',
            isRequired: true
        },
        expenseCategories: {
            label: 'Категорії витрат',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListExpenseCategory',
            isMultiple: true
        },
        amountUAH: {
            label: 'Сума в грн',
            type: 'number'
        },
        isVAT: {
            label: 'ПДВ',
            type: 'switch'
        },
        amountWithVAT: {
            label: 'Сума з ПДВ в грн',
            type: 'number'
        }
    },
    restUri: {
        getCollection: '/core_api/purchase/application/{entity_id}/expenses',
        post: '/core_api/purchase/application/expenses',
        get: '/core_api/purchase/application/expenses/{id}',
        patch: '/core_api/purchase/application/expenses/{id}',
        delete: '/core_api/purchase/application/expenses/{id}'
    }
};