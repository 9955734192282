export class EmailSignature {
    static get toolbox() {
      return {
        title: 'Email Signature',
        icon: '<svg width="20" height="20" viewBox="0 0 20 20"><path d="M10,14.5 L7.5,7 L12.5,7 L10,14.5 Z M10,2 L5,17 L10,17 L15,17 L10,2 Z" fill="#000000"/></svg>'
      };
    }
  
    constructor({data}) {
      this.data = data;
    }
  
    render() {

      const container = document.createElement('div');
      container.style.color = this.data.color;
      container.style.fontSize = this.data.fontSize;
      container.style.marginTop = '25px';
  
      
      if (this.data.title) {
        const title = document.createElement('div');
        title.textContent = this.data.title;
        title.style.margin = '3px 0';
        container.appendChild(title);
      }
  
      if (this.data.position) {
        const position = document.createElement('div');
        position.textContent = this.data.position;
        position.style.margin = '3px 0';
        container.appendChild(position);
      }
  
      if (this.data.phones) {
        const phones = document.createElement('div');
        phones.textContent = this.data.phones;
        phones.style.margin = '3px 0';
        container.appendChild(phones);
      }
      
      if (this.data.image) {
        const img = document.createElement('img');
        img.src = this.data.image;
        img.style.width = '250px';
        img.style.height = 'auto';
        img.style.marginTop = '3px';
        container.appendChild(img);
      }

      return container;
    }
  
    save(blockContent) {
      return {
        color: this.data.color,
        image: this.data.image,
        title: this.data.title,
        phones: this.data.phones,
        fontSize: this.data.fontSize,
        position: this.data.position
      };
    }
  
    validate(savedData) {
      return !!savedData.title && !!savedData.position;
    }
}