import { CrudResource } from "@/Core/UI/CrudResource";
import { Button } from 'antd';
import { ApplicationTransportTable } from './ApplicationTransportTable';
import { useFactShipmentButton } from '../Hooks/useFactShipmentButton';
import { toJS } from "mobx";
import { UserStore } from "@/Core/Stores/UserStore";
import { useDefaultLayoutContext } from "@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider";

export const ApplicationCrud = ({ ...props }) => {

    props.form ??= {};
    
    const defaultLayoutContext = useDefaultLayoutContext();

    return <CrudResource 
        resource='Purchase.Application'
        filter={{
            includeFields: [ 
                'supplier', 'date', 'responsible', 'product', 'termDelivery',
                'carrier', 'transportNumber', 'ttn'
            ],
            addFields: [
                {
                    name: 'filterTransportNumbers',
                    label: 'Номери автомобілів/Номери вагонів'
                }
            ]
        }}
        table={{
            eventHandlers: {
                onBuildColumns: {
                    addApproveColumn: ({ columns, tableContext, crudContext }) => {
                        
                        const roles = toJS(UserStore.user.roles);

                        if( 
                            columns.filter( column => column.key === 'approve_unloading' ).length === 0
                            && [ 'ROLE_ADMIN', 'ROLE_SUPER_USER', 'ROLE_CONTROLLER' ].some( role => roles.includes(role))
                        )
                            columns.splice(1, 0, {
                                title: 'Підтвердити вивантаження',
                                key: 'approve_unloading',
                                dataIndex: 'approve_unloading',
                                render: ( value, item ) => {
                                    const ColumnComponent = () => {

                                        const { factShipmentButton, FactShipmentModal } = useFactShipmentButton(crudContext, item._original['@id']);

                                        if( item._original.isUnloadedConfirmed === true )
                                            return;

                                        return <>
                                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                                <Button 
                                                    {...factShipmentButton}
                                                    depFn={() => {}}
                                                    size='middle'
                                                />
                                            </div>
                                            <FactShipmentModal />
                                        </>
                                    }
                                    return <ColumnComponent />
                                }
                            });

                    }
                },
                onBuildColumn: {
                    transformColumn: ({ column }) => {
                        if( column.key === 'purchase' )
                            column.render = (value, item) => {
                                
                                if( !item._original?.purchase )
                                    return '-';

                                return <a href="#" onClick={(e) => { 
                                    e.preventDefault();
                                    defaultLayoutContext.openResource({ 
                                        resourceCode: 'Purchase.Purchase', 
                                        resourceId: item._original?.purchase?.id
                                    });
                                }}>{item._original?.purchase?.contractNumber}</a>
                            }
                    }
                }
            },
            expandable: {
                expandedRowRender: record => <ApplicationTransportTable record={record} />,
                rowExpandable: (record) => record.name !== 'Not Expandable',
            }
        }}
        {...props}
    />
}