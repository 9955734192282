import { KanbanStore } from '../Stores/KanbanStore';
import { getIndex } from '../functions';
import { Container } from '../Components/Container';
import { Item } from '../Components/Item';

export const ItemTemplate = ({ containerId, columns }) => (
    <Container
      label={KanbanStore.containersData[containerId].name}
      columns={columns}
      style={{
        height: '100%',
      }}
      shadow
      unstyled={false}
    >
      {KanbanStore.items[containerId].map((item, index) => {
        return (
          <Item
            key={item}
            value={item}
            handle={true}
            style={{
                containerId,
                overIndex: -1,
                index: getIndex(item),
                value: item,
                isDragging: false,
                isSorting: false,
                isDragOverlay: false,
            }}
            color={KanbanStore.containersData[containerId]?.color}
            wrapperStyle={{index}}
          />
        );
      })}
    </Container>
);