import { ListDocumentTypeFormTemplate } from '../Templates/ListDocumentTypeFormTemplate';
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListDocumentTypeResource = {
    title: 'Типи документів',
    fields: {
        title: {
            label: 'Назва',
            isRequired: true
        },
        provider: {
            label: 'Провайдер',
            isRequired: true,
            type: 'select',
            resource: 'Setting.ListDocumentProvider'
        },
        file: {
            label: 'Файл шаблону документа',
            type: 'file',
            uploadUrl: '/core_api/settings/printable/type_document/files',
            // uploadUrlReplacer: ({ uploadUrl, formContext }) => uploadUrl.replace('{id}', formContext.values.id ),
            showViewIcon: false,
            showEditIcon: false
        },
        sorting: {
            label: 'Сортування',
            type: 'number'
        }
    },
    drawer: {
        width: 700
    },
    template: ListDocumentTypeFormTemplate,
    restUri: '/core_api/settings/printable/types_documents',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};