import React, {forwardRef} from 'react';

export const Action = forwardRef(
  ({ ...props}, ref) => {
    return (
      <button
        ref={ref}
        {...props}
        tabIndex={0}
        style={
          {
            backgroundColor: 'transparent',
            borderRadius: '5px',
            border: '1px solid #dfdfdf',
            outline: 'none',
            appearance: 'none',
            cursor: 'pointer'
          }
        }
      />
    );
  }
);
