import { observer } from 'mobx-react-lite'
import { ImportTransportStore } from '../Stores/ImportTransportStore';
import { CiImport } from "react-icons/ci";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { createFormContext } from "@/Core/UI/Form/Providers/FormContextProvider";
import { Button, Drawer } from 'antd';
import { Form, FormField } from "@/Core/UI/Form";
import { useShowErrorNotificationTask } from '@/Ship/Tasks/ShowErrorNotificationTask';
import { useShowSuccessNotificationTask } from '@/Ship/Tasks/ShowSuccessNotificationTask';

export const useImportTransportHook = () => {

    const ImportTransportButton = {
        label: 'Імпортувати', 
        icon: <CiImport style={{ fontSize: 24 }} />, 
        sort: 100, 
        priority: 'button',
        size: 'middle',
        type: 'primary',
        depFn: ({ crudContext }) => crudContext.accessManager.isCan('create') === true,
        style: { display: 'flex', alignItems: 'center', position: 'relative' },
        onClick: () => ImportTransportStore.setIsDrawerOpen(true)
    }

    const showErrorNotificationTask = useShowErrorNotificationTask();
    const showSuccessNotificationTask = useShowSuccessNotificationTask();

    const restClient = ServiceStore.get('restClient');
    const crudContext = useCrudResourceContext();

    const ImportTransportDrawer = observer(() => {

        const formContext = createFormContext({
            fields: {
                file: {
                    type: 'file',
                    showViewIcon: false,
                    showDeleteIcon: false,
                    isRequired: true,
                    label: 'Файл'
                }
            },
            initialValues: {
                entity: crudContext.formContext.values['@id']
            },
            onSubmit: async ({ values }) => {
                
                const reader = new FileReader();
                reader.readAsDataURL(values.file);
    
                reader.onload = async (e) => {
    
                    const body = new FormData();
                    const base64String = e.target.result;
                    const base64Data = base64String.replace(/^data:[\w+.-]+\/[\w+.-]+;base64,/, '');
                    const decodedData = atob(base64Data);
            
                    const byteArray = new Uint8Array(decodedData.length);
                    for (let i = 0; i < decodedData.length; i++) {
                        byteArray[i] = decodedData.charCodeAt(i);
                    }
    
                    const blobFile = new Blob([ byteArray ], { type: values.file.type });
            
                    body.append( 'file', blobFile, values.file.name );
                    body.append( 'name', values.file.name );
                    body.append( 'entity', crudContext.formContext.values['@id'] );
    
                    ImportTransportStore.setIsSendRequest(true);
                    restClient.post( 
                        '/core_api/purchase/application/items/import',
                        { body }, 
                        { isNotHeaderAddContentType: true } 
                    )
                    .getResponse()
                    .then( () => {
                        ImportTransportStore.setIsDrawerOpen(false);
                        crudContext.actions.reload();
                        showSuccessNotificationTask('Успіх', 'Інформацію успішно імпортовано.');
                    })
                    .catch( async error => {
                        const response = await error?.response?.json();
                        if( response['@type'] === 'hydra:Error' ) {
                            showErrorNotificationTask('Помилка', response['hydra:description']);
                        }
                        ImportTransportStore.setIsDrawerOpen(false);
                    })
                    .finally(() => {
                        ImportTransportStore.setIsSendRequest(false);
                    });
                }
            }
        });

        const SaveButton = observer(() => 
            <Button 
                type='primary' 
                loading={ImportTransportStore.isSendRequest} 
                onClick={() => formContext.submit()} 
                size='middle'
            >
                Імпортувати
            </Button>
        );

        return <Drawer 
            title={'Імпорт'}
            width={500}
            open={ImportTransportStore.isDrawerOpen} 
            destroyOnClose={true} 
            onClose={() => ImportTransportStore.setIsDrawerOpen(false) } 
            extra={<SaveButton />}
            className='multiple-application'
        >
            <Form formContext={formContext}>
                <FormField name='file' />
            </Form>
        </Drawer>
    });

    return {
        ImportTransportButton, 
        ImportTransportDrawer: <ImportTransportDrawer />
    }
}