import { Drawer as DrawerAnd } from "antd";
import { createDrawerContext, DrawerContextProvider } from './DrawerContextProvider';
import { observer } from 'mobx-react-lite';
import './Drawer.css';
import { useCrudResourceContext } from '../CrudResource/CrudResourceContextProvider';
import { HeaderPage } from '../HeaderPage';

export const Drawer = ({ title, extra, isOpen, children, drawerContext, ...props }) => {

    const crudContext = useCrudResourceContext();

    if( !props?.onClose )
        props.onClose = () => {
            drawerContext.setIsOpen(false);
            // TODO: !!!
            if( typeof crudContext?.Kanban?.loadItems === 'function' )
                crudContext.Kanban.loadItems();

            drawerContext.eventManager.run('onClose', { drawerContext, crudContext });
        }
    
    if( props?.destroyOnClose === undefined )
        props.destroyOnClose = true;
    
    if( !drawerContext ) {
        drawerContext = createDrawerContext({
            title, extra, isOpen, props
        });
    }
    else {
        drawerContext.mergeProps(props);
    }

    const drawerTitle = <HeaderPage 
        headerPageWrapperStyle={{ padding: 0 }}
        title={drawerContext.title}
        extra={drawerContext.extra}
        titleLevel={5}
        customEventHandlers={drawerContext.eventManager}
    />;

    const DrawerObserver = observer(({ children }) => {

        return <DrawerAnd 
            title={drawerTitle}
            open={drawerContext.isOpen} 
            onKeyDown={() => {
                // TODO: !!!
                // if (e.key === 'Escape' && typeof drawerProps?.onClose === 'function' )
                //     drawerProps.onClose();
            }}  
            {...drawerContext.props}
            extra={null}
        >
            {children}
        </DrawerAnd>
    });

    return <DrawerContextProvider value={drawerContext}>
        <DrawerObserver>
            {/* <div style={{maxHeight: 'calc(100vh - 60px)', overflow: 'hidden'}} ref={drawerRef}> */}
            <div>
                <div className="scroll-wrapper">
                    {typeof children === 'function' ? children() : children}
                </div>
            </div>
        </DrawerObserver>
    </DrawerContextProvider>;
};