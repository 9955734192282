import { createContext, useContext } from "react";
import { FormComposerStore } from "./../Stores/FormComposerStore";

export const createFormComposerContext = props => {

    props ??= {};
    props.eventHandlers ??= {};
    
    if( typeof props?.eventHandlers?.onSubmit !== 'function' && typeof props?.onSubmit === 'function' ) {
        props.eventHandlers.onSubmit = { baseOnSubmit: props.onSubmit };
        delete props.onSubmit;
    }

    return new FormComposerStore( props );
};

export const FormComposerContext = createContext();

export const useFormComposerContext = () => useContext(FormComposerContext);

export const FormComposerContextProvider = ({ value, children }) => <FormComposerContext.Provider value={value}>
    {children}
</FormComposerContext.Provider>