import { arrayMove } from '@dnd-kit/sortable';
import { KanbanStore } from '../Stores/KanbanStore';
import { unstable_batchedUpdates } from 'react-dom';
import { TRASH_ID, findContainer, getNextContainerId } from '../functions';

export const onDragEndHandler = async ({ active, over }) => {
  
  KanbanStore.setActiveContainerId(null);

  if (active.id in KanbanStore.items && over?.id) {
    KanbanStore.setContainers((containers) => {
      const activeIndex = containers.indexOf(active.id);
      const overIndex = containers.indexOf(over.id);

      return arrayMove(containers, activeIndex, overIndex);
    });
  }

  const activeContainer = findContainer(active.id);

  if (!activeContainer) {
    KanbanStore.setActiveId(null);
    return;
  }

  const overId = over?.id;

  if (overId == null) {
    KanbanStore.setActiveId(null);
    return;
  }

  if (overId === TRASH_ID) {
    KanbanStore.setItems((items) => ({
      ...items,
      [activeContainer]: items[activeContainer].filter(
        (id) => id !== KanbanStore.activeId
      ),
    }));
    KanbanStore.setActiveId(null);
    return;
  }

  const overContainer = findContainer(overId);

  if (overContainer) {
    const activeIndex = KanbanStore.items[activeContainer].indexOf(active.id);
    const overIndex = KanbanStore.items[overContainer].indexOf(overId);

    if (activeIndex !== overIndex) {
      KanbanStore.setItems((items) => ({
        ...items,
        [overContainer]: arrayMove(
          items[overContainer],
          activeIndex,
          overIndex
        ),
      }));
    }
  }

  KanbanStore.setActiveId(null);
}