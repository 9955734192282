import { observer } from 'mobx-react-lite';
import { App, Button, Modal, Skeleton, Typography } from 'antd';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Tabs } from "@/Core/UI/Tabs";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { Form, FormField } from '@/Core/UI/Form';
import { createFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';

import { LoadDetailCardStatusListTask } from '../Tasks/LoadDetailCardStatusListTask';
import { StatusBarDetailStore } from '../Stores/StatusBarDetailStore';

import { IoMdCheckmark } from "react-icons/io";
import { LuBadgeMinus } from "react-icons/lu";

import './StatusBarDetail.css';
import { MdOutlineRadioButtonUnchecked } from 'react-icons/md';

const { Text } = Typography;

export const StatusBarDetail = () => {

    const restClient = ServiceStore.get('restClient');
    const crudContext = useCrudResourceContext();
    const { notification } = App.useApp();
    const { t } = useTranslation();

    const loseFormContext = createFormContext({
        fields: {
            reasonLosing: {
                label: null,
                type: 'select',
                isRequired: true,
                resource: 'Setting.ListSaleReasonLosing'
            }
        },
        onSubmit: ({ values }) => {

            restClient.patch(`/core_api/sale/sales/lose_status`, {
                json: { 
                    entity: crudContext.formContext.values['@id'],
                    reasonLosing: values.reasonLosing['@id'] 
                }
            })
            .getResponse()
            .then( (response) => {
                notification.success({
                    duration: 5,
                    message: 'Успіх',
                    description: 'Успішно програно.',
                    placement: 'bottomRight'
                });

                StatusBarDetailStore.setIsModalOpen( false );
                StatusBarDetailStore.setCurrentStatus( StatusBarDetailStore.loseStatus );
                crudContext.formContext.setInitialValue('reasonLosing', response?.reasonLosing ?? null);
                crudContext.listContext.actions.reload();
        })
        }
    });

    useEffect(() => {
        LoadDetailCardStatusListTask();
    }, []);

    if( !crudContext || !crudContext?.formContext.values?.status )
        return;

    StatusBarDetailStore.setCurrentStatus(crudContext.formContext.values.status);
    StatusBarDetailStore.setApplicationId(crudContext.formContext.values.id);

    const StatusBarDetailObserver = observer(() => {

        if( StatusBarDetailStore.statusList.length === 0 || !StatusBarDetailStore.currentStatus )
            return <Skeleton paragraph={false} active={true} />;

        let isFillBackground = true;

        return <>
            <Tabs
                className='wl-status-bar-detail'
                activeKey={StatusBarDetailStore.currentStatus['@id']}
                items={StatusBarDetailStore.statusList.map( item => {
                    
                    if( item.code === "loss" )
                        StatusBarDetailStore.setLoseStatus(item);

                    const itemTransformed = {
                        label: <div
                            className='title' 
                            style={{ 
                                backgroundColor: isFillBackground 
                                    ? StatusBarDetailStore.currentStatus.color 
                                    : 'var(--ant-control-item-bg-hover)' 
                            }}
                        >
                            {!isFillBackground && <span style={{
                                paddingRight: 4,
                                height: 22,
                                position: 'relative'
                            }}>
                                <MdOutlineRadioButtonUnchecked style={{ fontSize: 22 }}/>
                            </span>}
                            {isFillBackground && <span style={{
                                paddingRight: 4,
                                height: 22,
                                position: 'relative'
                            }}>
                                <IoMdCheckmark style={{ fontSize: 22 }}/>
                            </span>}
                            <Text
                                style={{ width: 110 }}
                                ellipsis={{ tooltip: item.title }}
                            >
                                {item.title}
                            </Text>
                        </div>,
                        key: item['@id'],
                        color: item.color,
                        id: item.id
                    };

                    if( item['@id'] === StatusBarDetailStore.currentStatus['@id'] )
                        isFillBackground = false;

                    return itemTransformed;
                })}
                tabBarExtraContent={
                    StatusBarDetailStore.currentStatus.code !== "loss" ? <Button 
                        type='primary' 
                        danger={true} 
                        icon={<LuBadgeMinus  style={{ fontSize: 24 }} />}
                        style={{ display: 'flex', alignItems: 'center' }}
                        onClick={(e) => {
                            e.preventDefault();
                            StatusBarDetailStore.setIsModalOpen(true);
                        }}
                    >
                        Анулювати
                    </Button> : null
                }
                renderTabBar={(props, Component) => {
                    props.popupClassName += ' wl-status-bar-detail-dropdown';
                    return <Component {...props} />
                }}
            />
            <Modal 
                title={'Оберіть причину програшу'}
                open={StatusBarDetailStore.isModalOpen} 
                onCancel={() => StatusBarDetailStore.setIsModalOpen(false) }
                onOk={() => loseFormContext.submit()}
                okText={'Зберегти'}
                cancelButtonProps={{
                    style: { display: 'none' }
                }}
                destroyOnClose={true}
            >
                <Form formContext={loseFormContext}>
                    <FormField name='reasonLosing' />
                </Form>
            </Modal>
        </>;
    });

    return <StatusBarDetailObserver />;
}