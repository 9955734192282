import { action, computed, observable, makeObservable, toJS } from "mobx";
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import { FieldStore } from "./../Stores/FieldStore";
import { FieldMultipleStore } from './../Stores/FieldMultipleStore';
import { DeepCloneTask } from "@/Core/Tasks/Utils/DeepCloneTask";
import _ from "lodash";
import i18n from '@/Core/i18n';

export class FormStore extends UIBaseStore {

	name = null;
	errors = {};
	isEditable = true;
	isLoading = false;
	template = null;
	templateView = null;
	templateMultiple = null;
	templateObject = 'vertical';

	initialValues = {};
	values = {};

	fields = observable.map();

	fieldsSource = {};

	constructor({
		template,
		templateView,
		templateMultiple,
		templateObject,
		fields,
		initialValues,
		name,
		...props
	})
	{
		super({...props});

		makeObservable(this, {
			fields: observable,
			errors: observable,
			isEditable: observable,
			isLoading: observable,
			initialValues: observable,
			values: observable,
			isValid: computed,
			clear: action,
			resetErrors: action,
			reset: action,
			setInitialValues: action,
			setInitialValue: action,
			setValue: action,
			setError: action,
			setErrors: action,
			setIsEditable: action,
			setIsLoading: action
		});

		this.eventManager.run('onInit', { context: this });

		if( template ) this.template = template;
		if( templateView ) this.templateView = templateView;
		if( templateMultiple ) this.templateMultiple = templateMultiple;
		if( templateObject ) this.templateObject = templateObject;
		if( name ) this.setName( name );
		
		// TODO: fields is not reset when reopen card between create and edit mode.
		if( typeof fields === 'object' ) {
			this.fieldsSource = fields;
			this.makeFields( fields );
		}

		if( typeof initialValues === 'object' )
			this.mergeInitialValues( initialValues );
	}

	debounce = ( func, delay, field ) => {
		const context = this;
		return function (...args) {
			clearTimeout(field.debounceTimeoutId);

			const debounceTimeoutId = setTimeout(() => {
				func.apply(context, args);
			}, delay);
			
			field.setDebounceTimeoutId(debounceTimeoutId);
		};
	}

	get isValid() {
		return Object.keys(this.errors).length === 0;
	}

	setName( name ) {
		this.name = name;
	}

	makeFields( fields ) {
		if( typeof fields === 'object' && Object.keys( fields ).length > 0 )
			Object.keys( fields ).map( name => {
				this.makeField({ name, ...fields[name] }); 
			});
	}

	makeField( field, path = [] ) {

		if( typeof field?.name !== 'string' && !Array.isArray(field?.name) )
			return;

		if( typeof field?.name === 'string' && !path.includes(field.name) && !Array.isArray(field.name) ) {
			path.push( field.name );
		}
		/*
		if( Array.isArray(field?.name) ) {
			path = [ ...path, ...field.name ];
			field.name = field.name.pop();
		}
		*/
		if( field.type === 'object' && typeof field?.fields === 'object' ) {

			const fields = {};

			Object.keys(field.fields).map( name => {
				fields[name] = this.makeField(
					{ name, ...field.fields[ name ] },
					[ ...path ]
				);
			});

			field.fields = fields;			
		}

		const fieldObj = field?.isMultiple === true 
							? new FieldMultipleStore({ ...field, formContext: this, path }) 
							: new FieldStore({ ...field, formContext: this, path });

		this.setField( path, fieldObj);

		return fieldObj;
	}

	getField( path ) {
		path = Array.isArray(path) ? path.join('.') : path;
		return this.fields.get( path );
	}

	setField( path, field ) {
		path = Array.isArray(path) ? path.join('.') : path;
		this.fields.set( path, field );
	}

	deleteField( path ) {
		path = Array.isArray(path) ? path.join('.') : path;
		this.fields.delete( path );
	}

	getFields() {
		return toJS( Object.fromEntries(this.fields) );
	}

	setInitialValues( values ) {

		values = values ?? {};

		this.initialValues = DeepCloneTask(values);
		values = DeepCloneTask(values);
		
		let toValues = {};

		this.fields.forEach( field => {
			if( values[field.name] !== undefined )
				toValues[field.name] = values[field.name];

			if( toValues[field.name] === undefined && field.defaultValue !== undefined )
				toValues[field.name] = field.defaultValue;
		});

		if( toValues['id'] === undefined && values['id'] !== undefined )
			toValues['id'] = values['id'];

		if( toValues['@id'] === undefined && values['@id'] !== undefined )
			toValues['@id'] = values['@id'];

		this.values = toValues;
	}

	setInitialValue( path, value ) {
	
		if( Array.isArray( path ) )
			path = path.join('.');
		
		_.set( this.initialValues, path, value );

		const field = this.getField(path);
		
		if( value === undefined && field.defaultValue !== undefined )
			value = field.defaultValue;

		_.set( this.values, path, value );
	}

	mergeInitialValues( initialValues ) {
		const values = { ...this.initialValues, ...initialValues };
		this.setInitialValues( values );
	}

	setValue( path, value ) {

		const field = this.getField(path);

		if( !field )
			return;

		// if( 
		// 	( value === undefined || value === null || value === '' )
		// 	&& field.defaultValue !== undefined
		// )
		// 	value = field.defaultValue;

        if( !field.isMultiple ) {
			// this.values[path] = value;
			_.set( this.values, path, value )
        }
        
        if( field.isMultiple && Array.isArray(value) ) {
			_.set( this.values, path, value )
        }
		
		if( field.type === 'string' || field.type === 'textarea' || field.type === 'number' ) {
			
			const runOnChange = () => this.eventManager.run('onChange', { 
				formContext: this, 
				changedValues: { [path]: value } 
			});

			const onChange = this.debounce(
				runOnChange,
				700,
				field
			);

			onChange({ 
				formContext: this, 
				changedValues: {
					[path]: value
				} 
			});

		} else {
			this.eventManager.run( 
				'onChange', 
				{ 
					formContext: this, 
					changedValues: { [path]: value } 
				} 
			);
		}
		
		/*
        if( typeof this.eventHandlers?.onChange === 'function' ) {

			if( field.type === 'string' || field.type === 'textarea' || field.type === 'number' )
			{
				const onChange = this.debounce(
					this.eventHandlers.onChange,
					700,
					field
				);

				onChange({ 
					formContext: this, 
					changedValues: {
						[path]: value
					} 
				});

			} 
			else
				this.eventHandlers.onChange({ formContext: this, changedValues: { [path]: value } });
		}

        if( typeof field.eventHandlers?.onChange === 'function' ) {
			field.eventHandlers.onChange({ formContext: this, changedValues: {
                [path]: value
            } });
		}
		*/
	}

	getInitialValue( path ) {
		return _.get( this.initialValues, path );
	}

	getValue( path, defaultValue ) {
		return _.get( this.values, path ) ?? defaultValue;
	}

	setError( path, error ) {

		if( typeof path === 'string' )
			path = [ path ];

		this.errors[path.join('.')] = error;
	}

	getError( path ) {
		if( typeof path === 'string' )
			path = [ path ];
		return this.errors[path.join('.')];
	}

	setErrors( errors ) {
		this.errors = errors ?? {};
	}

	clear() {
		Object.keys(this.fields).map( name => this.fields[name].clear());
	}

	resetErrors() {
		this.setErrors();
	}

	reset() {
		this.makeFields( this.fieldsSource );
		this.resetErrors();
		this.setInitialValues();
	}

	validate() {

		this.resetErrors();

		this.fields.forEach( field => {
			if( field?.isRequired === true ) {
				const value = this.values[field.path];
				if( value === undefined || value === null || value === '' ) {
					const errorMessage = field.label ? i18n.t("The field {{fieldname}} is required.", { ns: 'Core', fieldname: field.label }) : i18n.t("The field is required.", { ns: 'Core' });
					this.setError( field.path, errorMessage );
				}
			}
		})
	}

	async submit( formComposer ) {

		this.validate();
		
		if( !this.isValid )
			return;

		await this.eventManager.run('onBeforeSubmit');
		
		const values = toJS(this.values);
		
		const initialValues = toJS(this.initialValues);
		const result = await this.eventManager.runAsync('onSubmit', {
			values,
			initialValues,
			formComposer,
			formContext: this
		});
		
		await this.eventManager.run('onAfterSubmit');

		return result;
	}

	scrollToFirstError() {

	}

	setIsEditable( value ) {
		this.isEditable = value === true ? true : false;
	}

	objectToMap( obj, prefix = '' ) {
		const map = new Map();
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const newKey = prefix ? `${prefix}.${key}` : key;
				if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
					const nestedMap = objectToMap(obj[key], newKey);
					nestedMap.forEach((value, nestedKey) => {
						map.set(nestedKey, value);
					});
				} else {
					map.set(newKey, obj[key]);
				}
			}
		}
		return map;
	}

	mapToObject( map ) {
		const obj = {};
		map.forEach((value, key) => {
			const keys = key.split('.');
			let currentObj = obj;
			keys.forEach((nestedKey, index) => {
				if (index === keys.length - 1) {
					currentObj[nestedKey] = value;
				} else {
					currentObj[nestedKey] = currentObj[nestedKey] || (Array.isArray(keys[index + 1]) ? [] : {});
					currentObj = currentObj[nestedKey];
				}
			});
		});
		return obj;
	}

	get( object, path, defaultValue ) {

		const keys = Array.isArray(path) ? path : path.split('.');
		let result = object;

		for (const key of keys) {
			if (result == null || typeof result !== 'object' || !(key in result)) {
				return typeof defaultValue !== 'undefined' ? defaultValue : undefined;
			}
			result = result[key];
		}

		return result;
	}

	set( object, path, value ) {

		const keys = Array.isArray(path) ? path : path.split('.');
		const length = keys.length;
		let currentObj = object;

		for (let i = 0; i < length - 1; i++) {
			const key = keys[i];
			if (currentObj[key] == null || typeof currentObj[key] !== 'object') {
				// Create nested objects if they don't exist
				const nextKey = keys[i + 1];
				currentObj[key] = /^\d+$/.test(nextKey) ? [] : {};
			}
			currentObj = currentObj[key];
		}

		currentObj[keys[length - 1]] = value;

		return object;
	}

	setIsLoading( isLoading ) {
		this.isLoading = isLoading;
	}
}