import React, { useMemo } from 'react';
import { Table } from 'antd';
import { ApplicationItemResource } from '../Resources/ApplicationItemResource';

export const ApplicationTransportTable = ({ record }) => {

    const columns = useMemo(() => {
            return Object.entries(ApplicationItemResource.fields)
            .filter(([key]) => key !== 'entity')
            .map(([key, field]) => ({
                title: field.label,
                dataIndex: key,
                key
            }));
    }, []);

    return <div style={{ backgroundColor: '#ffffff' }}>
        <Table
            columns={columns}
            dataSource={record?._original?.items ?? []}
            style={{ width: 800 }}
            rowKey={(item) => item.id || item['@id']}
            pagination={false}
        />
    </div>
};