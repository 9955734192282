import { Form, FormField, FormRow } from "@/Core/UI/Form";
import { PriceCurrencyField } from "@/Ship/Components/PriceCurrencyField";
import { Tabs } from "@/Core/UI/Tabs";
import { Dependency } from "@/Core/UI/Dependency";
import { StatusBarDetail } from "../Components/StatusBarDetail";
import { Empty } from "antd";
import { SaleItemCrud } from '../Components/SaleItemCrud';
import { ChangesList } from '../Components/ChangesList';

export const SaleFormTemplate = ({ formContext }) => {
    
    const tabItems = [
        {
            label: 'Загальне',
            key: 'general',
            children: <>
                <FormRow>
                    <FormRow.Col>
                        <Form formContext={formContext}>
                            <FormField name='client' />
                            <FormField name='contractNumber' />
                            <FormField name='organization' />
                            <FormField name='dateContract' />
                            <FormField name='product' />
                            <FormField name='quality' />
                            <FormField name='quantity' />
                            <PriceCurrencyField 
                                priceFieldName='price' 
                                currencyFieldName='priceCurrency' 
                            />
                            <FormField name='termDelivery' />
                            <FormField name='deliveryPlace' />
                            <FormField name='dateDelivery' />
                            <FormField name='responsible' />
                            <FormField name='comment' />
                            <FormField name='isDocumentsReceived' />
                            <FormField name='documentSigningType' />
                        </Form>
                    </FormRow.Col>
                    <FormRow.Col>
                        <Dependency depFn={({ crudContext }) => !!crudContext.formContext.values?.id}>
                            {() => <SaleItemCrud />}
                        </Dependency>
                        <Form formContext={formContext}>
                            <FormField name='totalQuantity' />
                            <FormField name='percentageCompletion' />
                            <FormField name='remainder' />
                            <FormField name='optionPercentage' />
                            <PriceCurrencyField 
                                priceFieldName='contractAmount' 
                                currencyFieldName='contractAmountCurrency' 
                            />
                            <FormField name='amountUAH' />
                            <FormField name='isConfirmedLogistician' />
                            <FormField name='commentLogistician' />
                            <FormField name='isConfirmedExecution' />
                            <FormField name='commentExecution' />
                            <FormField name='reasonLosing' />
                        </Form>
                    </FormRow.Col>
                </FormRow>
            </>
        },
        {
            label: 'Історія змін',
            key: 'events',
            depFn: ({ formContext }) => !!formContext.values?.id,
            children: <ChangesList />
        }
    ];

    return <>
        <StatusBarDetail />
        <Tabs items={tabItems} />
    </>
}