import React from "react";
import { Alert, App } from "antd";
import { useCrudResourceContext } from "@/Core/UI/CrudResource/CrudResourceContextProvider";
import { observer } from "mobx-react-lite";
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { useTranslation } from 'react-i18next';
import { Form, FormField, createFormContext } from "@/Core/UI/Form";

export const UserAccessSettings = observer(() => {

    const crudContext = useCrudResourceContext();
    const originalItem = crudContext.formContext.initialValues;
    const resourceClient = ServiceStore.get('resourceClient');
    const { notification } = App.useApp();
    const DefaultValueConfig = ConfigStore.get('DefaultValueConfig');
    const { t } = useTranslation();

    if( !originalItem?.id )
        return <Alert
            message={'Інформація'}
            description={<b>{'Для редагування прав доступу потрібно зберегти співробітника.'}</b>}
            type="info"
        />;
    
    let fields = {};
    let initialValues = {};

    if( Array.isArray(DefaultValueConfig.backendUserRolesList) )
        DefaultValueConfig.backendUserRolesList.map( role => fields[role.value] = {
            type: 'switch',
            label: role.label
        });

    if( Array.isArray(originalItem.roles) )
        originalItem.roles.map( role => initialValues[role] = true );

    const formContext = createFormContext({
        fields,
        initialValues,
        onChange: () => {
            let roles = [];
            Object.keys(formContext.values).map( key => {
                if( formContext.values[key] === true )
                    roles.push(key);
            });

            resourceClient.patch('Company.User', { json: { roles }}, {
                restUriReplaceParams: {
                    id: originalItem.id
                }
            });
        }
    });

    return <Form formContext={formContext}>
        {Array.isArray(DefaultValueConfig.backendUserRolesList) && DefaultValueConfig.backendUserRolesList.map( ( role ) => <FormField name={role.value} />)}
    </Form>
});