import { observer } from "mobx-react-lite";
import { FormField } from "@/Core/UI/Form";
import { Space } from "antd";

export const PriceCurrencyField = ({
    priceFieldName,
    currencyFieldName
}) => {

    const priceCurrencyTemplate = observer(({ field, children }) => {

        if( field === undefined )
            return;
        
        return children;
    });

    const priceTemplate = observer(({ field, children }) => {

        if( field === undefined )
            return;
        
        const error = field.formContext.getError( field.path );
        
        return <div className={`form-field form-field-default ${field.error ? 'is-error' : ''}`}>
            {field.label && <label htmlFor={field.id}>{field.label}{field.isRequired && <span className="is-required">*</span>}</label>}
            <Space.Compact style={{ width: '100%'}}>
                {children}
                <div style={{ width: 150 }}>
                    <FormField 
                        name={currencyFieldName} 
                        template={priceCurrencyTemplate} 
                        ignoreParentField={true} 
                    />
                </div>
            </Space.Compact>
            {<small>{error}</small>}
        </div>;
    });

    return <><FormField name={priceFieldName} template={priceTemplate} /></>;
}