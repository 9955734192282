import { observer } from "mobx-react-lite";
import { useDependency } from './useDependency'; 

export const Dependency = observer(({ depFn, depStore, children }) => {

    depFn = useDependency({ depFn, depStore });

    if( depFn() === false )
        return null;

    if( typeof children === 'function' )
        return children();

    return children;
});