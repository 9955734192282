export const ListBaseReasonLosingFields = {
    title: {
        label: 'Назва',
        isRequired: true
    },
    code: {
        label: 'Код',
        isRequired: true
    },
    sorting: {
        label: 'Сортування',
        type: 'number'
    }
};