import { ListBaseFields } from "./ListBaseFields";
import { toJS } from 'mobx';
import { UserStore } from "@/Core/Stores/UserStore";

export const ListOptionResource = {
    title: 'Опціон',
    fields: {
        ...ListBaseFields,
        code: {
            label: 'Код',
            isRequired: true
        },
        type: {
            label: 'Тип',
            type: 'select',
            isRequired: true,
            options: [
                { label: 'Закупівлі', value: 'purchases' },
                { label: 'Продажі', value: 'sales' }
            ]
        },
        rangeFrom: {
            label: 'Діапазон від',
            type: 'number'
        },
        rangeTo: {
            label: 'Діапазон до',
            type: 'number'
        },
        percent: {
            label: 'Відсоток',
            type: 'number'
        }
    },
    restUri: '/core_api/settings/list/options',
    accessFn: ( mode ) => {

        const roles = toJS(UserStore?.user?.roles);

        if( !Array.isArray(roles) )
            return false;

        let rules = {
            create: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            read: true,
            update: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false,
            delete: ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( role => roles.includes( role )) ? true : false
        };

        return rules[mode] !== undefined ? rules[mode] : false;
    }
};