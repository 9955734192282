import { observer } from "mobx-react-lite";

export const DefaultFieldTemplate = observer(({ field, children }) => {

    if( field === undefined )
        return;
    
    const error = field.formContext.getError( field.path );
    // TODO: field.isValid - ??
    return <div className={`form-field form-field-default ${field.error ? 'is-error' : ''}`}>
        {field.label && <label htmlFor={field.id}>{field.label}{field.isRequired && <span className="is-required">*</span>}</label>}
        {children}
        {<small>{error}</small>}
    </div>;
});