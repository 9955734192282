export const WarehouseCostResource = {
    title: 'Вартість послуг складу',
    fields: {
        warehouse: {
            label: 'Склад',
            isRequired: true,
            type: 'select',
            resource: 'Company.Warehouse',
            inputProps: {
                disabled: true
            }
        },
        service: {
            label: 'Послуга',
            isRequired: true,
            type: 'select',
            resource: 'Company.WarehouseService'
        },
        pricePerTon: {
            label: 'Вартість за тонну',
            type: 'number'
        }
    },
    restUri: '/core_api/company/warehouse/cost'
};