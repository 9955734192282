import { Tabs } from "@/Core/UI/Tabs";
import { Form, FormField, FormRow, FormBlock } from "@/Core/UI/Form";
import { UserChangePassword } from "../Components/UserChangePassword";
import { UserAccessSettings } from "../Components/UserAccessSettings";
import i18n from '@/Ship/i18n';
import { UserStore } from "@/Core/Stores/UserStore";

export const UserFormTemplate = ({ formContext }) => {

    const items = [
        {
            label: 'Про співробітника',
            key: 'userInfo',
            children: <Form formContext={formContext}>
                <FormField name='isActive' />
                <FormField name='login' />
                <FormField name='name' />
                <FormField name='lastName' />
                <FormField name='patronymic' />
                <FormField name='position' />
                <FormField name='email' />
                <FormField name='phones' />
                <FormField name='departments' />
            </Form>
        },
        {
            label: 'Змінити пароль',
            key: 'change-password',
            depFn: ({ formContext }) => 
                !!formContext.values?.id 
                && ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( 
                        role => UserStore.user.roles.includes(role)
                ),
            children: <UserChangePassword />
        },
        {
            label: 'Права доступу',
            key: 'access-rules',
            depFn: ({ formContext }) => 
                !!formContext.values?.id 
                && ['ROLE_ADMIN', 'ROLE_SUPER_USER'].some( 
                        role => UserStore.user.roles.includes(role)
                ),
            children: <UserAccessSettings />
        }
    ];

    return <Tabs items={items} />;
}