import { action, makeObservable, observable } from "mobx";
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';

export class ListStore extends UIBaseStore {

    sorter = {};
    filter = {};
    prefilter = {};
    pagination = {};

    issetNextPage = false;
    issetPrevPage = false;
    
    loader = undefined;

    fields = {};
    actions = {};

    constructor({
        sorter,
        filter,
        prefilter,
        pagination,
        ...props
    }) {

        super(props);
        
        this.eventManager.run('onInit', { context: this });

        if( typeof sorter === 'object' ) this.setSorter(sorter);
        if( typeof filter === 'object' ) this.setFilter(filter);
        if( typeof prefilter === 'object' ) this.setPrefilter(prefilter);
        if( typeof pagination === 'object' ) this.setPagination(pagination);

        makeObservable( this, {
            loader: observable,
            setLoader: action,
            setTotal: action
        });
    }

    get fullFilter() {
        return {...this.filter, ...this.prefilter};
    }

    setIssetNextPage( value ) {
        this.issetNextPage = value;
    }

    setIssetPrevPage( value ) {
        this.issetPrevPage = value;
    }

    setFilter( filter ) {
        if( typeof filter === 'object' )
            this.filter = filter;
    }

    setFilterOne( key, value ) {

        if( key === undefined )
            return;

        if( value === undefined )
            delete this.filter[key];
        else
            this.filter[key] = value;
    }

    resetFilter() {
        this.filter = {};
    }

    setPrefilter( prefilter ){
        if( typeof prefilter === 'object' )
            this.prefilter = prefilter;
    }

    setSorter( sorter ){
        if( typeof sorter === 'object' )
            this.sorter = sorter;
    }

    setPagination( pagination ){
        if( typeof pagination === 'object' )
            this.pagination = pagination;
    }

    setCurrentPage( number ) {
        this.pagination.current = number;
    }

    setLoader( loader ) {
        this.loader = loader;
    }

    setFields( fields ) {
        this.fields = fields;
    }

    setActions( actions ) {
        this.actions = actions;
    }

    setTotal( total ) {
        this.pagination = { ...this.pagination, total };
    }
}