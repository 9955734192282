import { useCrudResourceContext } from '@/Core/UI/CrudResource/CrudResourceContextProvider';
import { Button, Drawer, Dropdown } from 'antd';
import { observer } from 'mobx-react-lite';
import { ServiceStore } from '@/Core/Stores/ServiceStore';
import { makeAutoObservable } from 'mobx';
import { Form, FormField } from "@/Core/UI/Form";
import { ResourceStore } from '@/Core/Stores/ResourceStore';
import { createFormContext } from '@/Core/UI/Form/Providers/FormContextProvider';
import { useShowSuccessNotificationTask } from '@/Ship/Tasks/ShowSuccessNotificationTask';
import { useShowInfoNotificationTask } from '@/Ship/Tasks/ShowInfoNotificationTask';
import { useTranslation } from 'react-i18next';

export const AddButtonPurchaseDocumentCrud = () => {

    const crudContext = useCrudResourceContext();
    const resourceClient = ServiceStore.get('resourceClient');
    const PurchaseDocumentResource = ResourceStore.get('Purchase.PurchaseDocument');
    const restClient = ServiceStore.get('restClient');
    const ShowSuccessNotificationTask = useShowSuccessNotificationTask();
    const ShowInfoNotificationTask = useShowInfoNotificationTask();
    const { t } = useTranslation();

    const localStorage = makeAutoObservable({
        isLoading: true,
        setIsLoading( isLoading ) {
            this.isLoading = isLoading;
        },
        isDrawerOpen: false,
        setIsDrawerOpen(isOpen) { this.isDrawerOpen = isOpen },
        items: [],
        setItems( items ) {
            this.items = items;
        }
    });

    if( !crudContext )
        return;

    resourceClient
        .getCollection('Setting.ListDocumentType', {
            searchParams: {
                isUseInCarrier: true
            }
        })
        .getResponse()
        .then( response => {

            if( Array.isArray(response) ) {
                localStorage.setItems( response );
            }

            localStorage.setIsLoading( false );
            
        });

    const ButtonObserver = observer(() => {

        if( crudContext.accessManager.isCan('create') === false )
            return;

        if( localStorage.isLoading )
            return <Button loading={true} />;

        const menuItems = localStorage.items;

        const onClickHandler = code => {

            const typeDocument = localStorage.items.filter( item => item['@id'] === code);

            if( typeDocument.length === 0 )
                return;

            ShowInfoNotificationTask('Інформація', 'Відправлено на генерацію.');

            restClient
                .post(`/core_api/settings/printable/types_documents/${typeDocument[0].id}/generate`, {
                    json: {
                        entity: crudContext.parentCrudContext.formContext.values['@id']
                    }
                })
                .getResponse()
                .then( response => {
                    
                    crudContext.listContext.actions.reload();
                    ShowSuccessNotificationTask('Успіх', `Документ "${response.title}" успішно згенеровано.`);
        
                });
        }

        if( menuItems.length === 0 )
            return;

        return <>
            <Dropdown.Button 
                menu={{
                    items: [
                        {
                            key: 'generate',
                            type: 'group',
                            label: 'Генерація документа',
                            children: menuItems.map( item => ({
                                label: item.title,
                                key: item['@id']
                            }))
                        }
                    ],
                    onClick: (menuItem) => onClickHandler(menuItem.key)
                }} 
                onClick={() => {
                    crudContext.actions.create();
                }}
                type='primary'
            >
                Додати
            </Dropdown.Button>
        </>;
    });

    return <ButtonObserver />;
}