import { createContext, useContext } from "react";
import { CrudResourceStore } from './Stores/CrudResourceStore';

export const CrudResourceContext = createContext();

export const createCrudResourceContext = ({ access = null, accessFn = null, ...props }) => {

    if( access && typeof access === 'object' ) {
        accessFn = place => access[place] === false ? false : true
    }

    return new CrudResourceStore({ access, accessFn, ...props })
};

export const useCrudResourceContext = () => useContext( CrudResourceContext );

export const CrudResourceContextProvider = ({ value, children }) => <CrudResourceContext.Provider value={value}>
    {children}
</CrudResourceContext.Provider>