import { RestClientService } from './RestClientService';
import { GetResourceTask } from '@/Core/Tasks/Resources/GetResourceTask';
import { GetRestUriTask } from '@/Core/Tasks/Resources/GetRestUriTask';

export class ApiPlatformResourceClientService extends RestClientService {

    constructor( props ) {
        super( props );
    }

    getCollection(resource, options, settings) {
        
        options??= {};

        if( options?.hooks === undefined ) {
            options.hooks = {};
        }

        if( !Array.isArray(options.hooks?.afterResponse) ) {
            options.hooks.afterResponse = [];
        }

        options.hooks.afterResponse.push( async (request, options, response) => {

            if (response.ok) {
                return response.json().then(data => {
                    let modifiedData = data;

                    if( Array.isArray(data['hydra:member']))
                        modifiedData = data['hydra:member'];
                  
                    return new Response(JSON.stringify(modifiedData), {
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers
                    });
                });
            }
              
            return response;
        });
        
        return super.get( 
            this.getUrlByResource( resource, 'getCollection', settings ), 
            options, 
            settings 
        );
    }

    getCollectionNoStore(resource, options, settings) {
        
        options??= {};

        if( options?.hooks === undefined ) {
            options.hooks = {};
        }

        if( !Array.isArray(options.hooks?.afterResponse) ) {
            options.hooks.afterResponse = [];
        }

        options.hooks.afterResponse.push( async (request, options, response) => {

            if (response.ok) {
                return response.json().then(data => {
                    let modifiedData = data;

                    if( Array.isArray(data['hydra:member']))
                        modifiedData = data['hydra:member'];
                  
                    return new Response(JSON.stringify(modifiedData), {
                        status: response.status,
                        statusText: response.statusText,
                        headers: response.headers
                    });
                });
            }
              
            return response;
        });
        
        return super.getNoStore( 
            this.getUrlByResource( resource, 'getCollection', settings ), 
            options, 
            settings 
        );
    }

    get(resource, options, settings) {
        return super.get( 
            this.getUrlByResource( resource, 'get', settings ), 
            options, 
            settings 
        );
    }

    getBy(resource, uriCode, options, settings) {
        return super.get( 
            this.getUrlByResource( resource, uriCode, settings ), 
            options, 
            settings 
        );
    }

    post(resource, options, settings) {

        options??= {};

        if( options?.hooks === undefined ) {
            options.hooks = {};
        }

        if( !Array.isArray(options.hooks?.afterResponse) ) {
            options.hooks.afterResponse = [];
        }

        return super.post(
            this.getUrlByResource( resource, 'post', settings ),
            options, 
            settings
        );
    }

    postBy(resource, uriCode, options, settings) {
        return super.post( 
            this.getUrlByResource( resource, uriCode, settings ), 
            options, 
            settings 
        );
    }

    put(resource, options, settings) {
        return super.put(
            this.getUrlByResource( resource, 'put', settings ),
            options, 
            settings
        );
    }

    patch(resource, options, settings) {
        
        options ??= {};
        options.headers ??= {};
        
        if( options.headers['Content-Type'] === undefined )
            options.headers['Content-Type'] = 'application/merge-patch+json';

        return super.patch(
            this.getUrlByResource( resource, 'patch', settings ),
            options, 
            settings
        );
    }

    patchBy(resource, uriCode, options, settings) {
        return super.patch( 
            this.getUrlByResource( resource, uriCode, settings ), 
            options, 
            settings 
        );
    }

    delete(resource, options, settings) {
        
        settings ??= {};
        settings.isNotHeaderAddContentType = true;
        settings.isOriginalResponse = true;

        return super.delete(
            this.getUrlByResource( resource, 'delete', settings ),
            options, 
            settings
        );
    }

    deleteBy(resource, uriCode, options, settings) {
        return super.delete( 
            this.getUrlByResource( resource, uriCode, settings ), 
            options, 
            settings 
        );
    }

    getUrlByResource( resource, mode, settings ) {
        resource = GetResourceTask(resource);
        const restUriReplaceParams = settings?.restUriReplaceParams ?? {}
        return GetRestUriTask(resource?.restUri, mode, restUriReplaceParams);
    }
}