import { makeAutoObservable } from "mobx";
/**
 * items: {
        'con-1': [
            'task1',
            'task2',
            'task3',
            'task4'
        ],
        'con-2': [
            'task5'
        ],
        'con-3': []
    }
    containers: ['con-1', 'con-2', 'con-3']
    containersData: {
        'con-1': {
            title: 'Con 1'
        },
        'con-2': {
            title: 'Con 2'
        },
        'con-3': {
            title: 'Con 3'
        }
    }
 */
export const KanbanStore = makeAutoObservable({
    items: {},
    setItems( items ) {
        if( typeof items === 'function' ) {
            this.items = items(this.items);
            return;
        }
        this.items = items;
    },
    containers: [],
    setContainers( containers ) {
        if( typeof containers === 'function' ) {
            this.containers = containers(this.containers);
            return;
        }
        this.containers = containers;
    },
    containersData: {},
    setContainersData( containersData ) {

        if( typeof containersData === 'function' ) {
            this.containersData = containersData(this.containersData);
            return;
        }
        this.containersData = containersData;
    },
    deals: [], 
    setDeals( deals ) {
        if( typeof deals === 'function' ) {
            this.deals = deals(this.deals);
            return;
        }

        this.deals = deals;
    },
    activeId: null,
    setActiveId( activeId ) { this.activeId = activeId; },
    activeContainerId: null,
    setActiveContainerId( activeContainerId ) { this.activeContainerId = activeContainerId; },
    isLoading: false, 
    setIsLoading( isLoading ) { this.isLoading = isLoading; },
    clonedItems: null, 
    setClonedItems( clonedItems ) {
        if( typeof clonedItems === 'function' ) {
            this.clonedItems = clonedItems(this.clonedItems);
            return;
        }
        this.clonedItems = clonedItems;
    }
});