export const TransformPriceCurrencyViewFieldTask = ({
    value,
    values,
    costCurrencyFieldName
}) => {
    
    if( typeof value !== 'number')
        value = 0;

    let currencyCode = 'UAH';
    
    if( values[costCurrencyFieldName] !== undefined )
        currencyCode = values[costCurrencyFieldName].code;

    return `${value} ${currencyCode}`;
}