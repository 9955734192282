
import { makeAutoObservable } from 'mobx';

export const StatusBarDetailStore = makeAutoObservable({
    applicationId: null,
    setApplicationId( applicationId ) {
        this.applicationId = applicationId;
    },
    isModalOpen: false,
    setIsModalOpen( isModalOpen ) {
        this.isModalOpen = isModalOpen;
    },
    statusList: [],
    setStatusList( statusList ) {
        this.statusList = statusList
    },
    currentStatus: null,
    setCurrentStatus( currentStatus ){
        this.currentStatus = currentStatus;
    },
    loseStatus: null,
    setLoseStatus( loseStatus ) {
        this.loseStatus = loseStatus;
    }
});