import { Table as TableAntd } from "antd";
import { useListContext } from '@/Core/UI/List/ListContextProvider';
import { ConfigStore } from '@/Core/Stores/ConfigStore';
import { OverlayScrollbars } from '@/Core/UI/OverlayScrollbars';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { MergeConfigAndPropsTask } from '@/Core/Tasks/Utils/MergeConfigAndPropsTask';
import { UIBaseStore } from '@/Core/Stores/Base/UIBaseStore';
import { useCrudResourceContext } from '../CrudResource/CrudResourceContextProvider';
import { useEffect, useRef, useState } from 'react';
import { useDefaultLayoutContext } from '@/Core/Layouts/DefaultLayout/DefaultLayoutContextProvider';
import { DeepCloneTask } from '@/Core/Tasks/Utils/DeepCloneTask';

export const Table = observer( tableProps => {

    const tableRef = useRef(null);
    const [scrollDimensions, setScrollDimensions] = useState({ x: 0, y: 0 });
    const listContext = useListContext();
    const tableConfig = ConfigStore.get('TableConfig');
    const crudContext = useCrudResourceContext();
    const defaultLayoutContext = useDefaultLayoutContext();
    let props = MergeConfigAndPropsTask({ tableConfig, tableProps });

    const UI = new UIBaseStore(props);
    
    UI.eventManager.run('onInit', { context: UI, crudContext, listContext });
    UI.eventManager.run('onBuildProps', { props: UI.props, crudContext, listContext });

    if( UI.props.loading === undefined && listContext )
        UI.mergeProps({ loading: listContext.loader.isLoading });

    // TODO: Move to list features
    if( UI.props?.pagination === undefined && typeof listContext?.pagination === 'object') {
        UI.mergeProps({ pagination: {...listContext.pagination, showSizeChanger: false } });
    } else if( typeof UI.props?.pagination === 'object' && typeof listContext?.pagination === 'object' ) {
        UI.mergeProps({ pagination: {...UI.props.pagination, ...listContext.pagination, showSizeChanger: false} });
    }

    // TODO: Move to list features
    if( 
        UI.props.columns === undefined
        && listContext !== undefined
        && typeof listContext?.fields === 'object'
        && Object.keys(listContext.fields).length > 0
    ) {
        let columns = [];

        Object.keys(listContext.fields).map( name => {

            if( Array.isArray(UI.props.showColumns) && !UI.props.showColumns.includes(name) )
                return;

            const field = listContext.fields[name];
            let column = { 
                // TODO: !!!
                title: <div style={{ whiteSpace: 'nowrap' }}>{field?.label}</div> ?? '',
                dataIndex: name,
                key: name
            };
            columns.push( column );
        });
        
        UI.mergeProps({ columns: toJS(columns) });
    }

    // TODO: Move to table features
    if(Array.isArray( UI.props.columns )) {

        UI.mergeProps({ columns: toJS(UI.props.columns) });

        
        let columns = UI.props.columns;
        UI.eventManager.run( 'onBuildColumns', { columns, tableContext: UI, crudContext } );

        if( UI.eventManager.hasHandlers('onBuildColumn'))
            UI.props.columns.forEach( column => UI.eventManager.run( 'onBuildColumn', { column, tableContext: UI, crudContext } ));

        if( Array.isArray(UI.props.showColumns) ) {

            if( UI.props.settings?.isActionsColumnHide !== true)
                UI.props.showColumns.unshift('_actions');

            UI.props.showColumns.push('_original');

            let columns = UI.props.columns.filter( column => UI.props.showColumns.includes( column.key ) );
            if( typeof UI.props.tableColumnProps === 'object' )
                columns = columns.map( column => {
                    if( typeof UI.props.tableColumnProps[column.key] === 'object' )
                        column = { ...column, ...UI.props.tableColumnProps[column.key] }
                    return column;
                });

            const orderMap = UI.props.showColumns.reduce((acc, key, index) => {
                acc[key] = index;
                return acc;
            }, {});
                
            columns.sort((a, b) => orderMap[a.key] - orderMap[b.key]);
            UI.mergeProps({ columns });
        }
    }

    let dataSource = [];

    if( Array.isArray(listContext?.loader?.items) )
        dataSource = toJS(listContext?.loader.items);
    if( Array.isArray(UI.props.dataSource) )
        dataSource = UI.props.dataSource;

    UI.mergeProps({ dataSource });

    if( UI.props.dataSource.length > 0 )
        UI.eventManager.run( 'onBuildDataSourceItems', { dataSource: UI.props.dataSource, tableContext: UI, crudContext } );

    if( UI.props.dataSource.length > 0 && UI.eventManager.hasHandlers('onBuildDataSourceItem') ) {
        UI.props.dataSource.forEach( item => {
            item._original = DeepCloneTask(item);
            UI.eventManager.run( 'onBuildDataSourceItem', { item, tableContext: UI, crudContext, defaultLayoutContext } )
        });
    }

    // TODO: table
    const components = {
        table: (props) => (
            <OverlayScrollbars>
              <table {...props} />
            </OverlayScrollbars>
        ),
        ...(typeof UI.props?.components === 'object' ? UI.props.components : {})
    };

    UI.mergeProps({ components });

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
          for (let entry of entries) {
            const { width, height } = entry.contentRect;
            setScrollDimensions({ x: width, y: height });
          }
        });
    
        if (tableRef.current) {
          resizeObserver.observe(tableRef.current);
        }

        return () => {
          resizeObserver.disconnect();
        };
    }, []);

    return <div ref={tableRef} style={{ width: '100%', height: '100%' }}>
        <TableAntd {...UI.props} />
    </div>;
});